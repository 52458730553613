import { Box, createStyles, Group, Stack, useMantineTheme } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';
import P1Medium from 'components/typography/P1Medium';
import { RevisionChangeGroup } from 'components/forms/revision/fill-out/types';
import ChangeBox from 'components/forms/revision/fill-out/modals/revision-changes-modal/ChangeBox';

const useStyles = createStyles((theme) => ({
  changeGroup: {
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
}));

/**
 * Group of changes in a revision.
 */
export default function ChangeGroup({ title, changes }: RevisionChangeGroup) {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Stack spacing={12} py={24} className={classes.changeGroup}>
      <Group spacing={8} noWrap>
        <Box w={24} h={24}>
          <IconExclamationCircle stroke={2} size={24} color={theme.colors.gray[9]} />
        </Box>
        <P1Medium color="gray.9">{title}</P1Medium>
      </Group>
      {changes.map((change, index) => (
        <ChangeBox key={index} {...change} />
      ))}
    </Stack>
  );
}
