import Excerpt from 'components/Excerpt';
import { DeviceRow } from 'components/tables/data/device/types';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for the building.
 */
export default function BuildingColumn({ data: { building } }: { data: DeviceRow }) {
  return (
    <P2Regular color="gray.8">
      <Excerpt length={60} text={building ?? '-'} />
    </P2Regular>
  );
}
