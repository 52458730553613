import { Box, Flex } from '@mantine/core';
import { RevisionPlanRow } from 'components/tables/data/revision-plan/types';
import { IconBuildingSkyscraper } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for the organization name.
 */
export default function OrganizationNameColumn({ data: { organization } }: { data: RevisionPlanRow }) {
  return (
    <Flex gap={8} align="center">
      <Box w={24} h={24}>
        <IconBuildingSkyscraper size={24} stroke="1.5" />
      </Box>
      <P2Regular color="gray.8">{organization.organizationName}</P2Regular>
    </Flex>
  );
}
