import { AgGridReact } from 'ag-grid-react';
import TableWrapper from 'components/tables/TableWrapper';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import panic from 'errors/panic';
import { useDisclosure } from '@mantine/hooks';
import { ColDef, CsvExportParams, GetRowIdFunc, GridApi } from 'ag-grid-community';
import { isEmpty, isEqual, isNumber, isString, noop, omit } from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Group,
  Menu,
  NumberInput,
  Stack,
  Switch,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import {
  IconCopy,
  IconFile,
  IconFileExport,
  IconPencilCog,
  IconPlus,
  IconRefresh,
  IconSearch,
  IconTableColumn,
  IconTableImport,
  IconTableOptions,
  IconTrash,
} from '@tabler/icons-react';
import P3Regular from 'components/typography/P3Regular';
import ExportModal, { DataTableExport } from 'components/tables/ExportModal';
import ImportModal, { ImportCallback } from 'components/tables/ImportModal';
import { read, writeFile } from 'xlsx';
import { nanoid } from 'nanoid';
import { DASHBOARD_LAYOUT_TITLE_HEIGHT } from 'layouts/dashboard/DashboardLayout';
import H2Medium from 'components/typography/H2Medium';
import P2Regular from 'components/typography/P2Regular';
import P2Medium from 'components/typography/P2Medium';
import BulkActionsBar from 'components/tables/BulkActionsBar';
import objectHash from 'object-hash';
import { useConfirm } from 'components/modals/message/MessageProvider';
import { usePrompt } from 'hooks/modals/use-prompt';
import { Modal } from '@mantine/core';
import P4Regular from 'components/typography/P4Regular';
import P3Medium from 'components/typography/P3Medium';
import { showNotification } from '@mantine/notifications';

const DATA_TABLE_ROW_HEIGHT = 72;
const PROFILE_NAME_MAX_LENGTH = 50;

/**
 * The parameters of data import.
 */
export interface DataTableImport {
  modalTitle: string;
  action: ImportCallback;
  onHint?: () => void;
}

/**
 * Parameters of the DataTable component.
 */
export interface DataTableProps<TData> {
  action: () => Promise<TData[]>;
  tableId?: string;
  title?: string;
  afterTitle?: ReactNode;
  columns: ColDef[];
  hideToggleDiscarded?: boolean;
  toggleDiscardedLabel?: string;
  addButtonText?: string;
  addButtonTarget?: string;
  hideAddButton?: boolean;
  dataExport?: DataTableExport;
  dataImport?: DataTableImport;
  selectedRows?: TData[];
  bulkActions?: ReactNode;
  initialFilters?: Record<string, any>;
  defaultProfileFilters?: Record<string, any>;
  onReady?: (api: GridApi<TData>) => void;
  onSelectedRowsChange?: (rows: TData[]) => void;
  getRowId?: GetRowIdFunc<TData>;
}

type ColId = string;

interface ColSort {
  sort: 'asc' | 'desc';
  sortIndex: number;
}

type DataTableProfileConfigSort = Record<ColId, ColSort>;

interface DataTableProfileConfig {
  search: string;
  filters: Record<string, any>;
  hiddenColumns: string[];
  columnOrder: string[];
  columnSizes: Record<ColId, number>;
  sort: DataTableProfileConfigSort;
  hash: string;
}

interface DataTableProfile {
  id: string;
  name: string;
  initial: DataTableProfileConfig;
  current: DataTableProfileConfig;
  selected: boolean;
  readOnly: boolean;
}

/**
 * Renders data in a table using the specified action to fetch the data.
 */
export default function DataTable<TData>({
  action,
  tableId = '',
  title = '',
  afterTitle,
  columns,
  hideToggleDiscarded = false,
  toggleDiscardedLabel = '',
  addButtonText,
  addButtonTarget,
  hideAddButton = false,
  dataExport,
  dataImport,
  selectedRows = [],
  bulkActions = [],
  initialFilters = {},
  defaultProfileFilters = {},
  onReady = noop,
  onSelectedRowsChange = noop,
  getRowId,
}: DataTableProps<TData>) {
  const theme = useMantineTheme();
  const ref = useRef<AgGridReact>(null);
  const { confirm } = useConfirm();
  const prompt = usePrompt();

  const [ready, setReady] = useState<boolean>(false);
  const [loading, { open: startLoading, close: stopLoading }] = useDisclosure(true);
  const [data, setData] = useState<TData[]>([]);
  const [exporting, { open: startExporting, close: stopExporting }] = useDisclosure(false);
  const [importing, { open: startImporting, close: stopImporting }] = useDisclosure(false);
  const [columnsManagerOpened, { open: openColumnsManager, close: closeColumnsManager }] = useDisclosure(false);
  const [columnsManagerHidden, setColumnsManagerHidden] = useState<string[]>([]);
  const [columnsManagerFilters, setColumnsManagerFilters] = useState<Record<string, any>>({});
  const [columnsManagerWidths, setColumnsManagerWidths] = useState<Record<string, number>>({});

  const columnsWithBulk = useMemo(() => {
    return [
      {
        field: '_bulk',
        headerName: '',
        suppressMovable: true,
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        colSpan: ({ node }) => (node?.isRowPinned() ? 999 : 0),
      } satisfies ColDef,
      {
        field: '_checkbox',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressMovable: true,
        width: 44,
        minWidth: 44,
        maxWidth: 44,
        lockPosition: 'left',
      } satisfies ColDef,
      ...columns.map((column) =>
        column.field === '_actions'
          ? {
              ...column,
              headerComponent: () => (
                <Group w="100%" position="right" pr={4}>
                  <Button variant="tertiary" size="sm" leftIcon={<IconTableColumn />} onClick={openColumnsManager}>
                    Konfigurovať stĺpce
                  </Button>
                </Group>
              ),
            }
          : column
      ),
    ].map((column) => ({ ...column, lockPinned: column.field !== '_actions' }));
  }, [columns]);

  const configurableColumns = useMemo(
    () => columns.filter(({ cellRenderer, field }) => cellRenderer !== undefined && field !== '_actions'),
    [columns]
  );

  const isColumnConfigurable = useCallback(
    (field: string) => configurableColumns.some((column) => column.field === field),
    [configurableColumns]
  );

  const [visibleRowsCount, setVisibleRowsCount] = useState<number>(0);

  const defaultConfig = useMemo(() => {
    const filters: Record<string, any> = { ...defaultProfileFilters };

    if (!hideToggleDiscarded) {
      filters.status = {
        filterType: 'text',
        type: 'equals',
        filter: '1',
      };
    }

    const config: DataTableProfileConfig = {
      search: '',
      filters,
      hiddenColumns: [],
      columnOrder: configurableColumns.map(({ field }) => field!),
      columnSizes: {},
      sort: {},
      hash: '',
    };

    config.hash = objectHash(config);

    return config;
  }, [defaultProfileFilters, configurableColumns, hideToggleDiscarded]);

  const [profilesRaw, setProfiles] = useState<DataTableProfile[]>(() => [
    {
      id: nanoid(),
      name: 'Predvolený',
      initial: defaultConfig,
      current: defaultConfig,
      selected: true,
      readOnly: true,
    },
  ]);

  const profiles = useMemo(
    () => profilesRaw.map((profile) => ({ ...profile, modified: profile.current.hash !== profile.initial.hash })),
    [profilesRaw]
  );

  const getProfile = useCallback((id: string) => profiles.find((profile) => profile.id === id), [profiles]);

  const currentProfile = useMemo(() => profiles.find(({ selected }) => selected) ?? profiles[0], [profiles]);

  const showDiscarded = useMemo(() => !currentProfile.current.filters.status, [currentProfile.current.filters.status]);

  const applyProfile = useCallback(
    ({ filters, search, hiddenColumns, columnOrder, columnSizes, sort }: DataTableProfileConfig) => {
      ref.current?.api?.setFilterModel(filters);
      ref.current?.api?.setQuickFilter(search);

      configurableColumns.forEach((column) => {
        const hidden = hiddenColumns.includes(column.field!);
        ref.current?.columnApi.setColumnVisible(column.field!, !hidden);
      });

      const columnState = ref.current?.columnApi.getColumnState().map((column) => ({
        ...column,
        sort: sort[column.colId]?.sort ?? null,
        sortIndex: sort[column.colId]?.sortIndex ?? null,
        width: columnSizes[column.colId] ?? column.width,
      }));

      columnState?.sort((a, b) => columnOrder.indexOf(a.colId) - columnOrder.indexOf(b.colId));

      ref.current?.columnApi.applyColumnState({ state: columnState, applyOrder: true });

      ref.current?.api.sizeColumnsToFit({
        columnLimits: Object.entries(columnSizes).map(([colId, width]) => ({
          key: colId,
          minWidth: width,
          maxWidth: width,
        })),
      });
    },
    [configurableColumns]
  );

  const switchProfile = useCallback(
    (id: string) => setProfiles((profiles) => profiles.map((profile) => ({ ...profile, selected: profile.id === id }))),
    [setProfiles]
  );

  const updateProfile = useCallback(
    (update: (profile: DataTableProfileConfig) => DataTableProfileConfig) =>
      setProfiles((profiles) =>
        profiles.map((profile) => {
          if (!profile.selected) {
            return profile;
          }

          const current = update(profile.current);
          current.hash = objectHash({ ...current, hash: '' });

          return { ...profile, current };
        })
      ),
    []
  );

  const setSearch = useCallback(
    (search: string) => updateProfile((profile) => ({ ...profile, search })),
    [updateProfile]
  );

  const setFilters = useCallback(
    (filters: Record<string, any>) => updateProfile((profile) => ({ ...profile, filters })),
    [updateProfile]
  );

  const setColumnWidth = useCallback(
    (colId: ColId, width: number) =>
      updateProfile((profile) => ({
        ...profile,
        columnSizes: { ...profile.columnSizes, [colId]: width },
      })),
    [updateProfile]
  );

  const autoColumnWidth = useCallback(
    (colId: ColId) => updateProfile((profile) => ({ ...profile, columnSizes: omit(profile.columnSizes, [colId]) })),
    [updateProfile]
  );

  const setHiddenColumns = useCallback(
    (hiddenColumns: string[]) => updateProfile((profile) => ({ ...profile, hiddenColumns })),
    [updateProfile]
  );

  const setColumnOrder = useCallback(
    (columnOrder: string[]) => updateProfile((profile) => ({ ...profile, columnOrder })),
    [updateProfile]
  );

  const setSort = useCallback(
    (sort: DataTableProfileConfigSort) => updateProfile((profile) => ({ ...profile, sort })),
    [updateProfile]
  );

  const addFilter = useCallback(
    (key: string, value: Record<string, any>) =>
      updateProfile((profile) => ({
        ...profile,
        filters: { ...profile.filters, [key]: value },
      })),
    [updateProfile]
  );

  const removeFilter = useCallback(
    (key: string) =>
      updateProfile((profile) => {
        const filters = { ...profile.filters };
        delete filters[key];
        return { ...profile, filters };
      }),
    [updateProfile]
  );

  const setShowDiscarded = useCallback(
    (showDiscarded: boolean) => {
      if (showDiscarded) {
        removeFilter('status');
      } else {
        addFilter('status', { filterType: 'text', type: 'equals', filter: '1' });
      }
    },
    [addFilter, removeFilter]
  );

  const saveProfile = useCallback(
    () =>
      setProfiles((profiles) =>
        profiles.map((profile) => (profile.selected ? { ...profile, initial: profile.current } : profile))
      ),
    []
  );

  const resetProfile = useCallback(
    () =>
      setProfiles((profiles) =>
        profiles.map((profile) => (profile.selected ? { ...profile, current: profile.initial } : profile))
      ),
    []
  );

  const createEmptyProfile = useCallback((profileName: string) => {
    const config: DataTableProfileConfig = {
      search: '',
      filters: {
        status: {
          filterType: 'text',
          type: 'equals',
          filter: '1',
        },
      },
      hiddenColumns: [],
      columnOrder: configurableColumns.map(({ field }) => field!),
      columnSizes: {},
      sort: {},
      hash: '',
    };

    config.hash = objectHash(config);

    const profile: DataTableProfile = {
      id: nanoid(),
      name: profileName,
      initial: config,
      current: config,
      selected: true,
      readOnly: false,
    };

    setProfiles((profiles) => [...profiles.map((profile) => ({ ...profile, selected: false })), profile]);
  }, []);

  const duplicateProfile = useCallback(
    (profileName: string) => {
      const profile: DataTableProfile = {
        ...currentProfile,
        id: nanoid(),
        name: profileName,
        selected: true,
        initial: currentProfile.current,
        readOnly: false,
      };

      setProfiles((profiles) => [...profiles.map((profile) => ({ ...profile, selected: false })), profile]);
    },
    [currentProfile]
  );

  const deleteProfile = useCallback(
    (id: string) =>
      setProfiles((profiles) =>
        profiles.filter((profile) => profile.id !== id).map((profile, index) => ({ ...profile, selected: index === 0 }))
      ),
    []
  );

  const deleteProfileUi = useCallback(
    (id: string) => {
      const profile = getProfile(id);

      if (profile) {
        confirm({
          centered: false,
          title: 'Vymazať profil',
          content: (
            <p>
              Naozaj chcete vymazať profil <b>{profile.name}</b>?
            </p>
          ),
          onConfirm: () => deleteProfile(id),
        });
      }
    },
    [getProfile, confirm, deleteProfile]
  );

  const renameProfile = useCallback(
    (id: string, name: string) =>
      setProfiles((profiles) => profiles.map((profile) => (profile.id === id ? { ...profile, name } : profile))),
    []
  );

  const renameProfileUi = useCallback(
    (id: string) => {
      const profile = getProfile(id);

      if (profile) {
        prompt({
          title: 'Premenovať profil',
          message: 'Zadajte nový názov profilu',
          initialValue: profile.name,
          maxLength: PROFILE_NAME_MAX_LENGTH,
          onConfirm: (name) => renameProfile(id, name),
        });
      }
    },
    [getProfile, prompt, renameProfile]
  );

  const createEmptyProfileUi = useCallback(
    () =>
      prompt({
        title: 'Nový profil',
        message: 'Zadajte názov profilu',
        placeholder: 'Názov profilu',
        maxLength: PROFILE_NAME_MAX_LENGTH,
        onConfirm: (name) => createEmptyProfile(name),
      }),
    [prompt, createEmptyProfile]
  );

  const duplicateProfileUi = useCallback(
    () =>
      prompt({
        title: 'Duplikovať profil',
        message: 'Zadajte názov profilu',
        placeholder: 'Názov profilu',
        initialValue: `${currentProfile.name} (kópia)`,
        maxLength: PROFILE_NAME_MAX_LENGTH,
        onConfirm: (name) => duplicateProfile(name),
      }),
    [prompt, duplicateProfile, currentProfile]
  );

  /**
   * Exports the file as CSV.
   */
  async function exportFile(params: CsvExportParams) {
    const csv = ref.current?.api.getDataAsCsv(params)?.replace(/","(=+)/g, '"," $1');

    if (!csv) {
      return;
    }

    const wb = read(csv, {
      type: 'string',
      cellDates: true,
      cellFormula: false,
    });

    const fileName = params.fileName ?? 'export.xlsx';

    writeFile(wb, fileName, {
      type: 'file',
      bookType: 'xlsx',
    });
  }

  // Fetch the data.
  useEffect(() => {
    startLoading();

    setProfiles((profiles) => {
      const key = `fmpoint.dataTable.${tableId}.profiles`;
      const raw = window.localStorage.getItem(key) ?? 'null';
      const parsed = (JSON.parse(raw) ?? profiles) as DataTableProfile[];

      // Apply initial filters to the default profile.
      if (!isEmpty(initialFilters)) {
        parsed.forEach((profile) => {
          const wasSelected = profile.selected;
          const isDefault = profile.readOnly;

          if (isDefault) {
            const newFilters = { ...defaultConfig.filters, ...initialFilters };
            const hasChanged = !isEqual(newFilters, profile.current.filters);

            profile.selected = true;
            profile.current.filters = newFilters;
            profile.current.hash = objectHash({ ...profile.current, hash: '' });

            if (hasChanged || !wasSelected) {
              showNotification({
                title: 'Aplikované filtre',
                message: (
                  <p className="my-0">
                    Profil bol zmenený na <b>Predvolený</b> a boli aplikované filtre podľa prekliku.
                  </p>
                ),
                color: 'orange',
              });
            }
          } else {
            profile.selected = false;
          }
        });
      }

      // Set the initial data to the default profile.
      parsed.forEach((profile) => {
        if (profile.readOnly) {
          profile.initial = defaultConfig;
        }
      });

      return parsed;
    });

    action().then(setData).catch(panic).finally(stopLoading);
  }, []);

  // Apply the profile on data change.
  useEffect(() => {
    if (ready && !loading) {
      applyProfile(currentProfile.current);
    }
  }, [ready, loading, currentProfile.id]);

  // Save profiles on change.
  useEffect(() => {
    if (ready && !loading) {
      const key = `fmpoint.dataTable.${tableId}.profiles`;
      window.localStorage.setItem(key, JSON.stringify(profiles));
    }
  }, [profiles]);

  // Apply quick search.
  useEffect(() => {
    ref.current?.api?.setQuickFilter(currentProfile.current.search);
  }, [currentProfile.current.search]);

  // Apply filters.
  useEffect(() => {
    ref.current?.api?.setFilterModel(currentProfile.current.filters);
  }, [currentProfile.current.filters]);

  useEffect(() => {
    setColumnsManagerHidden(currentProfile.current.hiddenColumns);
  }, [currentProfile.current.hiddenColumns]);

  useEffect(() => {
    setColumnsManagerFilters(currentProfile.current.filters);
  }, [currentProfile.current.filters]);

  useEffect(() => {
    setColumnsManagerWidths(currentProfile.current.columnSizes);
  }, [currentProfile.current.columnSizes]);

  const hasBulkActions = !!bulkActions && selectedRows.length > 0 && data.length > 0;

  return (
    <Stack spacing={0}>
      <Group mih={DASHBOARD_LAYOUT_TITLE_HEIGHT} align="center" position="apart" pb={8}>
        <Group maw={750} spacing={16}>
          <H2Medium>{title}</H2Medium>
          {!hideToggleDiscarded && (
            <Switch
              mt={6}
              checked={showDiscarded}
              onChange={(e) => setShowDiscarded(e.currentTarget.checked)}
              label={toggleDiscardedLabel}
            />
          )}
          {afterTitle}
        </Group>
        <Group spacing={16}>
          <Group spacing={4}>
            <Menu>
              <Menu.Target>
                <Button size="sm" variant="tertiary" leftIcon={<IconTableOptions stroke={1.5} size={24} />}>
                  <Group spacing={4}>
                    <P2Regular>Profil:</P2Regular>
                    <P2Medium>{currentProfile.name}</P2Medium>
                    {currentProfile.modified && <Box ml={8} w={8} h={8} bg="red.6" style={{ borderRadius: '50%' }} />}
                  </Group>
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                {profiles.map(({ id, name, modified }) => (
                  <Menu.Item
                    key={id}
                    onClick={() => switchProfile(id)}
                    rightSection={modified && <Box ml={8} w={8} h={8} bg="red.6" style={{ borderRadius: '50%' }} />}
                    fw={id === currentProfile.id ? 'bold' : 'normal'}
                  >
                    {name}
                  </Menu.Item>
                ))}
                <Menu.Divider />
                <Menu.Item color="blue" icon={<IconPlus size={16} />} onClick={createEmptyProfileUi}>
                  Nový profil (prázdny)
                </Menu.Item>
                <Menu.Item color="blue" icon={<IconCopy size={16} />} onClick={duplicateProfileUi}>
                  Duplikovať profil
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  icon={<IconPencilCog size={16} />}
                  color="gray"
                  disabled={currentProfile.readOnly}
                  onClick={() => renameProfileUi(currentProfile.id)}
                >
                  Premenovať profil
                </Menu.Item>
                <Menu.Item icon={<IconTableColumn size={16} />} color="gray" onClick={openColumnsManager}>
                  Konfigurovať stĺpce
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  icon={<IconTrash size={16} />}
                  color="red"
                  disabled={currentProfile.readOnly}
                  onClick={() => deleteProfileUi(currentProfile.id)}
                >
                  Vymazať profil
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <Button
              size="sm"
              variant="tertiary"
              leftIcon={<IconFile stroke={1.5} />}
              disabled={currentProfile.readOnly}
              onClick={saveProfile}
            >
              Uložiť
            </Button>
            <Button
              size="sm"
              variant="danger-secondary"
              leftIcon={<IconRefresh stroke={1.5} />}
              onClick={() => {
                applyProfile(currentProfile.initial);
                resetProfile();
              }}
              disabled={!currentProfile.modified}
            >
              Obnoviť
            </Button>
          </Group>
          <TextInput
            size="md"
            miw={336}
            rightSection={<IconSearch stroke={1.5} color={theme.colors.gray[6]} />}
            placeholder="Hľadať v tabuľke"
            value={currentProfile.current.search}
            onChange={(event) => setSearch(event.currentTarget.value)}
          />
        </Group>
      </Group>
      <TableWrapper>
        <AgGridReact
          ref={ref}
          rowData={data}
          getRowId={getRowId}
          columnDefs={columnsWithBulk}
          onGridReady={({ api }) => {
            api.sizeColumnsToFit();
            setReady(true);
            onReady(api);
          }}
          onFilterChanged={({ api }) => {
            setVisibleRowsCount(api.getDisplayedRowCount());

            if (ready && !loading) {
              setFilters(api.getFilterModel());
            }
          }}
          onSortChanged={({ columnApi }) => {
            const sortState: DataTableProfileConfigSort = {};

            columnApi.getColumnState().forEach(({ colId, sort, sortIndex }) => {
              if (isString(sort) && isNumber(sortIndex)) {
                sortState[colId] = { sort, sortIndex };
              }
            });

            setSort(sortState);
          }}
          onColumnMoved={({ columnApi }) => {
            const colOrder = columnApi
              .getColumnState()
              .filter(({ colId }) => isColumnConfigurable(colId))
              .map(({ colId }) => colId);

            setColumnOrder(colOrder);
          }}
          onColumnResized={({ column, source }) => {
            if (column) {
              const colId = column.getColId();
              const width = column.getActualWidth();

              if (source === 'autosizeColumns') {
                autoColumnWidth(colId);
              } else if (source === 'uiColumnResized') {
                setColumnWidth(colId, width);
              } else {
                // We don't care about other sources.
              }
            }
          }}
          onModelUpdated={({ api }) => setVisibleRowsCount(api.getDisplayedRowCount())}
          onSelectionChanged={({ api }) => onSelectedRowsChange(api.getSelectedRows())}
          rowHeight={DATA_TABLE_ROW_HEIGHT}
          className="ag-theme-alpine"
          rowSelection="multiple"
          enableCellTextSelection
          suppressCellFocus
          suppressRowClickSelection
          suppressDragLeaveHidesColumns
          suppressColumnVirtualisation
          noRowsOverlayComponent={() => (loading ? 'Načítavanie ...' : 'Nenájdené žiadne záznamy')}
          pinnedTopRowData={hasBulkActions ? [data[0]] : []}
        />
        <Flex gap={12} p={16} align="center" h="100%" bg="gray.0" justify="space-between">
          <Group spacing={16}>
            {!hideAddButton && addButtonTarget && addButtonText && (
              <Button
                variant="primary"
                component={Link}
                to={addButtonTarget}
                size="md"
                leftIcon={<IconPlus stroke="1.5" size={24} />}
              >
                {addButtonText}
              </Button>
            )}
            {dataExport && (
              <>
                <Button
                  variant="tertiary"
                  size="md"
                  leftIcon={<IconFileExport stroke="1.5" size={24} />}
                  onClick={startExporting}
                >
                  Exportovať
                </Button>
                <ExportModal
                  columns={columns}
                  dataExport={dataExport}
                  opened={exporting}
                  onClose={stopExporting}
                  onExport={exportFile}
                />
              </>
            )}
            {dataImport && (
              <>
                <Button
                  variant="tertiary"
                  size="md"
                  leftIcon={<IconTableImport stroke="1.5" size={24} />}
                  onClick={startImporting}
                >
                  Importovať
                </Button>
                <ImportModal
                  title={dataImport.modalTitle}
                  opened={importing}
                  onClose={stopImporting}
                  onImport={dataImport.action}
                  onHint={dataImport.onHint}
                />
              </>
            )}
          </Group>
          <P3Regular color="gray.7">Záznamy: {visibleRowsCount}</P3Regular>
        </Flex>
        {hasBulkActions && (
          <BulkActionsBar
            bulkActions={bulkActions}
            selectedRowsCount={selectedRows.length}
            onDeselectAll={() => ref.current?.api.deselectAll()}
          />
        )}
      </TableWrapper>
      {columnsManagerOpened && (
        <Modal
          opened={columnsManagerOpened}
          onClose={closeColumnsManager}
          closeOnClickOutside={false}
          centered
          title="Konfigurácia stĺpcov"
          size="auto"
        >
          <Box py={4} className="grid grid-cols-[auto_250px_80px] items-center gap-x-4 gap-y-2">
            <P3Medium c="gray.6" pl={36}>
              Stĺpec
            </P3Medium>
            <P3Medium c="gray.6">Filter</P3Medium>
            <P3Medium c="gray.6">Šírka</P3Medium>
            {configurableColumns
              .sort(
                (a, b) =>
                  currentProfile.current.columnOrder.indexOf(a.field!) -
                  currentProfile.current.columnOrder.indexOf(b.field!)
              )
              .map((column) => (
                <Fragment key={column.field}>
                  <Checkbox
                    size="md"
                    label={<P2Medium pl={8}>{column.headerName}</P2Medium>}
                    checked={!columnsManagerHidden.includes(column.field!)}
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        setColumnsManagerHidden(columnsManagerHidden.filter((field) => field !== column.field));
                      } else {
                        setColumnsManagerHidden([...columnsManagerHidden, column.field!]);
                      }
                    }}
                  />
                  <Box
                    component={column.floatingFilterComponent}
                    api={ref.current?.api}
                    onChange={(filter: any) => setColumnsManagerFilters((filters) => ({ ...filters, ...filter }))}
                  />
                  <NumberInput
                    rightSection={<P4Regular>px</P4Regular>}
                    size="sm"
                    min={0}
                    max={999}
                    placeholder="auto"
                    value={columnsManagerWidths[column.field!] ?? ''}
                    onChange={(value) =>
                      setColumnsManagerWidths((widths) =>
                        value === '' ? omit(widths, [column.field!]) : { ...widths, [column.field!]: value }
                      )
                    }
                  />
                </Fragment>
              ))}
          </Box>
          <Group position="right" pt={32}>
            <Button variant="link" onClick={closeColumnsManager}>
              Zrušiť
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() => {
                setHiddenColumns(columnsManagerHidden);

                applyProfile({
                  ...currentProfile.current,
                  hiddenColumns: columnsManagerHidden,
                  filters: columnsManagerFilters,
                  columnSizes: columnsManagerWidths,
                });

                closeColumnsManager();
              }}
            >
              Potvrdiť
            </Button>
          </Group>
        </Modal>
      )}
    </Stack>
  );
}
