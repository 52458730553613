import { Button, Group } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import P1Medium from 'components/typography/P1Medium';
import { noop } from 'lodash';
import { ReactNode, useMemo } from 'react';

interface BulkActionsBarProps {
  bulkActions?: ReactNode;
  selectedRowsCount?: number;
  onDeselectAll?: () => void;
}

/**
 * Displays a bar with bulk actions for the selected rows in the table.
 */
export default function BulkActionsBar({ bulkActions, selectedRowsCount, onDeselectAll = noop }: BulkActionsBarProps) {
  const style = useMemo(
    () => ({
      zIndex: 200,
      borderTop: '2px solid white',
      borderLeft: '2px solid white',
      borderRight: '2px solid white',
    }),
    []
  );

  return (
    <Group position="apart" pos="absolute" top={98} left={0} w="100%" h={73} bg="blue.6" px={24} py={16} style={style}>
      {bulkActions}
      <Group spacing={8}>
        <P1Medium color="white">vybrané: {selectedRowsCount}</P1Medium>
        <Button variant="primary" size="sm" leftIcon={<IconX strokeWidth={1.5} size={16} />} onClick={onDeselectAll}>
          Odznačiť všetko
        </Button>
      </Group>
    </Group>
  );
}
