import { noop } from 'lodash';
import { RevisionActionProps } from 'components/tables/data/revision/types';
import { useApi } from 'api/api-context';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import panic from 'errors/panic';

/**
 * The parameters of the reassign action.
 */
export interface ReassignActionParams extends RevisionActionProps {
  reason?: string;
}

/**
 * Implements the reassign action for revision.
 */
export default function useReassignAction() {
  const { getAction } = useApi();

  /**
   * Makes a call to the API to reassign the revision.
   */
  async function reassignRevision({
    revision: { revisionId, revisionName },
    reason,
    onStatusChange = noop,
  }: ReassignActionParams) {
    const revisionReassignAction = getAction('RevisionReassign');

    try {
      const { status } = await revisionReassignAction({
        parameters: { revisionId: String(revisionId) },
        payload: { reason: reason },
      });

      onStatusChange({ revisionId, status, reason });

      showNotification({
        title: 'Revízna správa bola opätovne zadaná',
        message: `Revíznu správu ${revisionName} ste úspešne opätovne zadali.`,
        color: SUCCESS_NOTIFICATION_COLOR,
      });
    } catch (error: any) {
      panic(error);
    }
  }

  return { reassignRevision };
}
