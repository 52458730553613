import P2Regular from 'components/typography/P2Regular';
import { FaultRow } from 'components/tables/data/fault/types';
import Excerpt from 'components/Excerpt';

/**
 * Column for the fixed note.
 */
export default function FixedNoteColumn({ data: { fixed } }: { data: FaultRow }) {
  return (
    <P2Regular color="gray.8">
      <Excerpt text={fixed?.fixedNote ?? '-'} length={30} />
    </P2Regular>
  );
}
