import { ADD_MEASURING_DEVICE_PAGE_PATH } from 'routes/paths';
import { ColDef } from 'ag-grid-community';
import { useApi } from 'api/api-context';
import DataTable from 'components/tables/DataTable';
import MeasuringDeviceNameColumn from 'components/tables/data/measuring-device/columns/MeasuringDeviceNameColumn';
import ManufacturerColumn from 'components/tables/data/measuring-device/columns/ManufacturerColumn';
import TypeColumn from 'components/tables/data/measuring-device/columns/TypeColumn';
import SerialNumberColumn from 'components/tables/data/measuring-device/columns/SerialNumberColumn';
import DescriptionColumn from 'components/tables/data/measuring-device/columns/DescriptionColumn';
import ActionsColumn from 'components/tables/data/measuring-device/columns/ActionsColumn';

/**
 * The columns of the table.
 */
const columns: ColDef[] = [
  {
    field: 'measuringDeviceId',
    headerName: 'ID meracieho prístroja',
    hide: true,
  },
  {
    field: 'measuringDeviceName',
    headerName: 'Názov',
    minWidth: 200,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: MeasuringDeviceNameColumn,
  },
  {
    field: 'manufacturer',
    headerName: 'Výrobca',
    minWidth: 200,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: ManufacturerColumn,
  },
  {
    field: 'type',
    headerName: 'Typ',
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    minWidth: 200,
    cellRenderer: TypeColumn,
  },
  {
    field: 'serialNumber',
    headerName: 'Výrobné číslo',
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    minWidth: 200,
    cellRenderer: SerialNumberColumn,
  },
  {
    field: 'description',
    headerName: 'Info',
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    minWidth: 200,
    cellRenderer: DescriptionColumn,
  },
  {
    field: '_actions',
    headerName: '',
    pinned: 'right',
    width: 194,
    minWidth: 194,
    maxWidth: 194,
    cellRenderer: ActionsColumn,
  },
];

/**
 * Table which displays measuring devices.
 */
export default function MeasuringDeviceTable() {
  const { getAction } = useApi();

  return (
    <DataTable
      tableId="measuring-devices"
      title="Meracie prístroje"
      hideToggleDiscarded
      addButtonText="Pridať nový"
      columns={columns}
      action={getAction('MeasuringDeviceList')}
      addButtonTarget={ADD_MEASURING_DEVICE_PAGE_PATH.original}
      dataExport={{
        modalTitle: 'Exportovať meracie prístroje',
        fileName: 'meracie-pristroje.xlsx',
        columnKeys: ['measuringDeviceId', 'measuringDeviceName', 'manufacturer', 'type', 'serialNumber', 'description'],
      }}
    />
  );
}
