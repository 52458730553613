import { Button, Menu } from '@mantine/core';
import { IconCheck, IconChevronRight } from '@tabler/icons-react';
import { RevisionSingleActionProps } from 'components/tables/data/revision/types';
import { Link } from 'react-router-dom';
import { FILL_OUT_REVISION_PAGE_PATH, SELECT_TEMPLATE_AND_VISUAL_PAGE_PATH } from 'routes/paths';

/**
 * Action performed by the revision technician to fill out the revision.
 */
export default function FillOutAction({ revision, kind }: RevisionSingleActionProps) {
  const revisionStatusSlug = revision.revisionStatus.slug;
  const revisionId = revision.revisionId;

  const path =
    revisionStatusSlug === 'accepted-by-technician'
      ? SELECT_TEMPLATE_AND_VISUAL_PAGE_PATH.insert({ revisionId })
      : FILL_OUT_REVISION_PAGE_PATH.insert({ revisionId });

  if (kind === 'button') {
    return (
      <Button size="md" component={Link} to={path} leftIcon={<IconCheck />} variant="secondary" w={180}>
        Vypracovať
      </Button>
    );
  }

  return (
    <Menu.Item component={Link} to={path} icon={<IconChevronRight stroke="1.5" size={24} />} color="gray.8">
      Vypracovať
    </Menu.Item>
  );
}
