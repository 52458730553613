import RestrictionSelectBaseFilter, {
  RestrictionSelectFilterBaseProps,
} from 'components/tables/filters/restriction/RestrictionSelectBaseFilter';
import { useMemo } from 'react';

/**
 * Filter component for the restriction column with two options - global and organization.
 */
export default function TwoRestrictionsSelectFilter(props: RestrictionSelectFilterBaseProps) {
  const options = useMemo(
    () => [
      { value: 'global', label: 'Globálne' },
      { value: 'organization', label: 'Organizácia' },
    ],
    []
  );

  return <RestrictionSelectBaseFilter {...props} data={options} />;
}
