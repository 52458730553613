import { Button, Group, Modal } from '@mantine/core';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { useEffect, useMemo, useState } from 'react';
import MultiCheckbox, { MultiCheckboxItem } from 'components/inputs/multi-checkbox/MultiCheckbox';
import { isEmpty, noop } from 'lodash';
import BadgeDeleted from 'components/Badge';
import { OrganizationListResponse } from 'api/actions/organization-list/organization-list-response';

/**
 * A single organization.
 */
export type PickOrganizationItem = OrganizationListResponse[number];

/**
 * Parameters of the PickOrganizations component.
 */
export interface PickOrganizationsParams {
  opened?: boolean;
  title?: string;
  initialPicked?: number[];
  excludeIds?: number[];
  showDiscarded?: boolean;
  permissionSlug?: string;
  onClose?: () => void;
  onPick?: (organizations: PickOrganizationItem[]) => void;
}

/**
 * Modal for selecting organizations.
 */
export default function PickOrganizations({
  opened = false,
  title = 'Pridať organizáciu',
  initialPicked = [],
  excludeIds = [],
  showDiscarded = false,
  permissionSlug = undefined,
  onClose = noop,
  onPick = noop,
}: PickOrganizationsParams = {}) {
  const { getAction } = useApi();
  const [organizations, setOrganizations] = useState<PickOrganizationItem[]>([]);
  const [picked, setPicked] = useState<number[]>(initialPicked);
  const [loading, setLoading] = useState(true);

  const options: MultiCheckboxItem[] = useMemo(
    () =>
      organizations.map(({ organizationId, organizationName, status }) => ({
        value: organizationId,
        label: organizationName,
        iconLeft: status ? null : <BadgeDeleted size="sm" text="Vyradené" />,
      })),
    [organizations]
  );

  // Fetch organizations from the API.
  useEffect(() => {
    setLoading(true);

    const organizationGetListAction = getAction('OrganizationList');

    organizationGetListAction({
      query: { filters: { 'showDiscarded.eq': showDiscarded ? 1 : 0, 'permissionSlug.eq': permissionSlug } },
    })
      .then(setOrganizations)
      .catch(panic)
      .finally(() => setLoading(false));
  }, []);

  /**
   * Is called when the user clicks on the "Pridať" button.
   */
  const onPickImpl = () => {
    onPick(organizations.filter(({ organizationId }) => picked.includes(organizationId)));
    setPicked([]);
    onClose();
  };

  return (
    <Modal title={title} opened={opened} onClose={onClose}>
      <MultiCheckbox
        withSearch
        data={options}
        value={picked}
        loading={loading}
        onChange={setPicked}
        excludeIds={excludeIds}
        searchPlaceholder="Hľadať organizácie"
        noResultPlaceholder="Neboli nájdené žiadne organizácie."
      />
      <Group position="apart" pt={24}>
        <Button size="md" variant="subtle-gray" onClick={onClose}>
          Zrušiť
        </Button>
        <Button size="md" variant="primary" onClick={onPickImpl} disabled={isEmpty(picked)}>
          Pridať
        </Button>
      </Group>
    </Modal>
  );
}
