import { GridApi } from 'ag-grid-community';
import { QualificationsSelect } from 'components/selects/QualificationsSelect';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'qualificationSlugs' as const;

/**
 * Parameters of the QualificationsSelectFilter component.
 */
export interface QualificationsSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the qualificationSlugs column.
 *
 * This filter assumes:
 *   - column field is `qualificationSlugs`
 */
export default function QualificationsSelectFilter({ api, onChange }: QualificationsSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange, type: 'contains' });

  return (
    <QualificationsSelect
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky typy oprávnení"
      value={String(value)}
      onChange={setValue}
    />
  );
}
