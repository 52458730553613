import { Menu } from '@mantine/core';
import { IconTrashX } from '@tabler/icons-react';
import { RevisionSingleActionProps } from 'components/tables/data/revision/types';
import { noop } from 'lodash';
import useDeleteAction from 'components/tables/data/revision/actions/hooks/use-delete-action';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Action performed by the user who has the permission to delete the revision.
 */
export default function DeleteAction({ revision, onStatusChange = noop }: RevisionSingleActionProps) {
  const { deleteRevision } = useDeleteAction();
  const { confirm } = useConfirm();

  /**
   * Confirms the deletion of the revision.
   */
  const confirmDeleteRevision = () =>
    confirm({
      title: 'Vymazať revíziu',
      content: 'Naozaj si prajete vymazať túto revíznu správu?',
      onConfirm: () => deleteRevision({ revision, onStatusChange }),
    });

  return (
    <Menu.Item color="red.8" onClick={confirmDeleteRevision} icon={<IconTrashX stroke={1.5} size={24} />}>
      Zmazať
    </Menu.Item>
  );
}
