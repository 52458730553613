import MessageModal from 'components/modals/message/MessageModal';
import { noop } from 'lodash';
import { ReactNode, createContext, useCallback, useContext, useState } from 'react';

/**
 * Parameters of the Message component.
 */
interface MessageComponentParams {
  onClose: () => void;
}

/**
 * The message component.
 */
type MessageComponent = (params: MessageComponentParams) => ReactNode;

/**
 * The content of the message.
 */
export type IContent = ReactNode | MessageComponent;

/**
 * The callback.
 */
export type ICallback = () => void | Promise<void>;

/**
 * Parameters of the message function.
 */
export interface IMessageParams {
  content: IContent;
  title?: string;
  onMessage: () => void;
  onClose?: () => void;
  size?: string | number;
  zIndex?: number;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  hidePrimaryButton?: boolean;
  hideSecondaryButton?: boolean;
  footerLeftSection?: React.ReactNode;
  returnFocus?: boolean;
  centered?: boolean;
}

/**
 * Message function.
 */
export type IMessage = (params: IMessageParams) => void;

/**
 * Typedef for the MessageProvider context.
 */
export interface IMessageContext {
  message: IMessage;
}

/**
 * The message context.
 */
const MessageContext = createContext<IMessageContext>(undefined!);

/**
 * Provides the MessageContext.
 */
export default function MessageProvider({ children }: { children: React.ReactNode }) {
  const [opened, setOpened] = useState(false);
  const [params, setParams] = useState<IMessageParams>({ content: '', onMessage: noop });

  /**
   * Message function.
   */
  const message: IMessage = useCallback(
    (params) => {
      setParams(params);
      setOpened(true);
    },
    [setParams, setOpened]
  );

  const onCloseImpl = useCallback(() => {
    if (params.onClose) {
      params.onClose();
    }

    setOpened(false);
  }, [params]);

  return (
    <MessageContext.Provider value={{ message }}>
      {children}
      <MessageModal {...params} opened={opened} onClose={onCloseImpl} />
    </MessageContext.Provider>
  );
}

/**
 * Uses the MessageContext.
 */
export function useMessage() {
  return useContext(MessageContext);
}

/**
 * Hook to use the confirm modal.
 */
export function useConfirm() {
  const { message } = useMessage();

  const confirm = useCallback(
    ({
      title,
      content,
      zIndex,
      returnFocus,
      centered,
      onConfirm,
      onClose = noop,
    }: {
      title?: string;
      content: IContent;
      zIndex?: number;
      returnFocus?: boolean;
      centered?: boolean;
      onConfirm: ICallback;
      onClose?: ICallback;
    }) =>
      message({
        title,
        content,
        zIndex,
        returnFocus,
        centered,
        primaryButtonText: 'Áno',
        secondaryButtonText: 'Nie',
        onMessage: onConfirm,
        onClose,
      }),
    [message]
  );

  return { confirm };
}

/**
 * Hook to use the acknowledge modal.
 */
export function useAcknowledge() {
  const { message } = useMessage();

  const acknowledge = useCallback(
    ({
      title,
      content,
      size,
      zIndex,
      primaryButtonText = 'V poriadku',
      onAcknowledge = noop,
    }: {
      title?: string;
      content: IContent;
      size?: string | number;
      zIndex?: number;
      primaryButtonText?: string;
      onAcknowledge?: ICallback;
    }) =>
      message({
        title,
        content,
        size,
        zIndex,
        primaryButtonText,
        hideSecondaryButton: true,
        onMessage: onAcknowledge,
      }),
    [message]
  );

  return { acknowledge };
}

/**
 * Hook to use the warn modal.
 */
export function useWarn() {
  const { message } = useMessage();

  const warn = useCallback(
    ({
      title,
      content,
      zIndex,
      onWarned,
      primaryButtonText = 'Pokračovať',
      footerLeftSection,
    }: {
      title?: string;
      content: IContent;
      zIndex?: number;
      onWarned: ICallback;
      primaryButtonText?: string;
      footerLeftSection?: ReactNode;
    }) =>
      message({
        title,
        content,
        primaryButtonText,
        zIndex,
        secondaryButtonText: 'Zrušiť',
        footerLeftSection,
        onMessage: onWarned,
      }),
    [message]
  );

  return { warn };
}
