import { Box, Group } from '@mantine/core';
import { RevisionRow } from 'components/tables/data/revision/types';
import { IconUser } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for the technician's name.
 */
export default function TechnicianNameColumn({ data: { technician } }: { data: RevisionRow }) {
  return (
    <Group spacing={8} noWrap align="center">
      <Box w={24} h={24}>
        <IconUser stroke={1.5} height={24} width={24} />
      </Box>
      <P2Regular color="gray.8">{technician.fullName}</P2Regular>
    </Group>
  );
}
