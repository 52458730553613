import { Select } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'isFixedText' as const;

/**
 * The options of the filter.
 */
export const OPTIONS = [
  { value: 'fixed', label: 'Vyriešená' },
  { value: 'not-fixed', label: 'Nevyriešená' },
];

/**
 * Parameters of the FaultFixedSelectFilter component.
 */
export interface FaultFixedSelectFilterProps {
  api: GridApi;
  onChange: FilterChangeCallback;
}

/**
 * Filter component for the isFixedText column.
 *
 * This filter assumes:
 * - column field is `isFixedText`
 */
export default function FaultFixedSelectFilter({ api, onChange }: FaultFixedSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });

  return (
    <Select
      searchable
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky stavy riešenia"
      data={OPTIONS}
      value={value}
      onChange={setValue}
    />
  );
}
