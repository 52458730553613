import UserTable from 'components/tables/data/user/UserTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';

/**
 * Page used to display a list of users.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=233%3A8532 Figma Design}
 * - {@link https://www.notion.so/Administrative-List-Users-5bf19a6ae3124e39a2e827dc477efa3a?pvs=4 Notion Page}
 */
export default function UsersPage() {
  return (
    <DashboardLayout breadcrumbs={[{ title: 'Používatelia' }]} height="screen">
      <UserTable />
    </DashboardLayout>
  );
}
