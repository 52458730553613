import { GridApi } from 'ag-grid-community';
import { OrganizationSelect } from 'components/selects/OrganizationSelect';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'organization.organizationId' as const;

/**
 * Parameters of the OrganizationSelectFilter component.
 */
export interface OrganizationSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

interface ConfigureOrganizationSelectFilterProps {
  permissionSlug?: string;
}

type FullOrganizationSelectFilterProps = OrganizationSelectFilterProps & ConfigureOrganizationSelectFilterProps;

/**
 * Filter component for the organization column.
 *
 * This filter assumes:
 *   - column field is `organization.organizationId`
 */
export default function OrganizationSelectFilter({ api, onChange, permissionSlug }: FullOrganizationSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });

  return (
    <OrganizationSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky organizácie"
      value={String(value)}
      onChange={setValue}
      permissionSlug={permissionSlug}
    />
  );
}

OrganizationSelectFilter.configure =
  (config: ConfigureOrganizationSelectFilterProps) => (props: OrganizationSelectFilterProps) =>
    <OrganizationSelectFilter {...config} {...props} />;
