import { GridApi } from 'ag-grid-community';
import { OrganizationSelect } from 'components/selects/OrganizationSelect';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'assignedBy.organizationId' as const;

/**
 * Parameters of the AssignedBySelectFilter component.
 */
export interface AssignedBySelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the assignedBy column.
 *
 * This filter assumes:
 *   - column field is `assignedBy.organizationId`
 */
export default function AssignedBySelectFilter({ api, onChange }: AssignedBySelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });

  return (
    <OrganizationSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky organizácie"
      value={String(value)}
      onChange={setValue}
    />
  );
}
