import { rem } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { DeviceTypeSelect } from 'components/selects/DeviceTypeSelect';
import { useEffect, useState } from 'react';
import { FILTER_KEY as DEVICE_TYPE_SELECT_FILTER_KEY } from 'components/tables/filters/DeviceTypeSelectFilter';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

export interface DeviceSubtypeSelectFilterBaseProps {
  api: GridApi;
  onChange: FilterChangeCallback;
}

/**
 * Parameters of the DeviceSubtypeSelectFilter component.
 */
interface DeviceSubtypeSelectFilterProps extends DeviceSubtypeSelectFilterBaseProps {
  filterKey: string;
  type: 'contains' | 'equals';
}

/**
 * Filter component for the deviceSubtype column.
 *
 * This filter assumes:
 *   - column field is `filterKey`
 */
export default function DeviceSubtypeSelectFilter({ filterKey, type, api, onChange }: DeviceSubtypeSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey, type, onChange });
  const [parentDeviceTypeId, setParentDeviceTypeId] = useState<number | undefined>(0);

  useEffect(() => {
    /**
     * Handles the change of the parent device type id.
     */
    const onFilterChanged = () => {
      const parentDeviceTypeId = api.getFilterModel()[DEVICE_TYPE_SELECT_FILTER_KEY]?.filter ?? 0;
      setParentDeviceTypeId(parentDeviceTypeId);
    };

    api.addEventListener('filterChanged', onFilterChanged);
    return () => api.removeEventListener('filterChanged', onFilterChanged);
  }, []);

  return (
    <DeviceTypeSelect
      lazyLoad
      parentDeviceTypeId={parentDeviceTypeId}
      listSubtypes={true}
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky typy zariadení"
      value={String(value)}
      onChange={setValue}
      styles={{ dropdown: { minWidth: rem(250) } }}
    />
  );
}
