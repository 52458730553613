// cspell:disable

/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/generate-environment.ts. If you wish to
 * change the environment variables, modify the file config/environment.yaml and then run:
 *
 *     npm run generate:environment
 *
 * -------------------------------------------------------------------------------------------------
 */

import getenv from 'env/getenv';

/**
 * Whether the feature is on.
 */
function isFeatureOn(feature: string): boolean {
  return feature === 'on';
}

/**
 * The base url of the REST API.
 */
export const REST_API_URL =
  process.env.REACT_APP_REST_API_URL ||
  getenv('REST_API_URL', {
    development: '/',
    test: '/',
    production: '/',
  });

/**
 * The timeout for the REST API.
 */
export const REST_API_TIMEOUT = Number(
  process.env.REACT_APP_REST_API_TIMEOUT ||
    getenv('REST_API_TIMEOUT', {
      development: '60000',
      test: '60000',
      production: '60000',
    })
);

/**
 * The timeout for the file manager.
 */
export const FILE_MANAGER_TIMEOUT = Number(
  process.env.REACT_APP_FILE_MANAGER_TIMEOUT ||
    getenv('FILE_MANAGER_TIMEOUT', {
      development: '60000',
      test: '60000',
      production: '60000',
    })
);

/**
 * The base url of the file manager.
 */
export const FILE_MANAGER_URL =
  process.env.REACT_APP_FILE_MANAGER_URL ||
  getenv('FILE_MANAGER_URL', {
    development: '/api/files',
    test: '/api/files',
    production: '/api/files',
  });

/**
 * The host of the web app.
 */
export const WEB_APP_HOST =
  process.env.REACT_APP_WEB_APP_HOST ||
  getenv('WEB_APP_HOST', {
    development: 'http://localhost:3000',
    test: 'http://localhost:3000',
    production: '',
  });

/**
 * Whether to show debug bar.
 */
export const FEATURE_TOGGLE_DEBUG_BAR = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_DEBUG_BAR ||
    getenv('FEATURE_TOGGLE_DEBUG_BAR', {
      development: 'on',
      test: 'off',
      production: '',
    })
);

/**
 * The maximum size of a file upload in bytes.
 */
export const FILE_UPLOAD_MAX_SIZE = Number(
  process.env.REACT_APP_FILE_UPLOAD_MAX_SIZE ||
    getenv('FILE_UPLOAD_MAX_SIZE', {
      development: 5242880,
      test: 5242880,
      production: '104857600',
    })
);

/**
 * Whether to use Firebase.
 */
export const FEATURE_TOGGLE_FIREBASE = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_FIREBASE ||
    getenv('FEATURE_TOGGLE_FIREBASE', {
      development: 'off',
      test: 'off',
      production: 'on',
    })
);

/**
 * The API key for Firebase.
 */
export const FIREBASE_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY ||
  getenv('FIREBASE_API_KEY', {
    development: '',
    test: '',
    production: 'AIzaSyAHOrdoNQNwAZ-f2NyAiyxEJpQaWAl3cVc',
  });

/**
 * The auth domain for Firebase.
 */
export const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
  getenv('FIREBASE_AUTH_DOMAIN', {
    development: '',
    test: '',
    production: 'fmpoint-v2.firebaseapp.com',
  });

/**
 * The project id for Firebase.
 */
export const FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID ||
  getenv('FIREBASE_PROJECT_ID', {
    development: '',
    test: '',
    production: 'fmpoint-v2',
  });

/**
 * The storage bucket for Firebase.
 */
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
  getenv('FIREBASE_STORAGE_BUCKET', {
    development: '',
    test: '',
    production: 'fmpoint-v2.appspot.com',
  });

/**
 * The messaging sender id for Firebase.
 */
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ||
  getenv('FIREBASE_MESSAGING_SENDER_ID', {
    development: '',
    test: '',
    production: '791585174427',
  });

/**
 * The app id for Firebase.
 */
export const FIREBASE_APP_ID =
  process.env.REACT_APP_FIREBASE_APP_ID ||
  getenv('FIREBASE_APP_ID', {
    development: '',
    test: '',
    production: '1:791585174427:web:78434763fe79774023e73e',
  });

/**
 * Whether to allow KEP.
 */
export const FEATURE_TOGGLE_KEP = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_KEP ||
    getenv('FEATURE_TOGGLE_KEP', {
      development: 'on',
      test: 'off',
      production: 'on',
    })
);

/**
 * Whether to allow Test Page.
 */
export const FEATURE_TOGGLE_TEST_PAGE = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_TEST_PAGE ||
    getenv('FEATURE_TOGGLE_TEST_PAGE', {
      development: 'on',
      test: 'off',
      production: '',
    })
);

/**
 * Whether to allow Create Device Subtypes.
 */
export const FEATURE_TOGGLE_CREATE_DEVICE_SUBTYPES = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_CREATE_DEVICE_SUBTYPES ||
    getenv('FEATURE_TOGGLE_CREATE_DEVICE_SUBTYPES', {
      development: 'on',
      test: 'off',
      production:
        'on',
    })
);

/**
 * The target for window.open when using Brain IT.
 */
export const BRAIN_IT_WINDOW_OPEN_TARGET =
  process.env.REACT_APP_BRAIN_IT_WINDOW_OPEN_TARGET ||
  getenv('BRAIN_IT_WINDOW_OPEN_TARGET', {
    development: '_blank',
    test: '_blank',
    production: '_self',
  });

/**
 * Whether to allow the admins to see Revision Templates pages.
 */
export const FEATURE_TOGGLE_BROWSE_REVISION_TEMPLATES = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_BROWSE_REVISION_TEMPLATES ||
    getenv('FEATURE_TOGGLE_BROWSE_REVISION_TEMPLATES', {
      development: 'on',
      test: 'off',
      production:
        'on',
    })
);

/**
 * Whether to allow the revision technicians to see the Plan Next Revision checkbox.
 */
export const FEATURE_TOGGLE_PLAN_NEXT_REVISION_CHECKBOX = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_PLAN_NEXT_REVISION_CHECKBOX ||
    getenv('FEATURE_TOGGLE_PLAN_NEXT_REVISION_CHECKBOX', {
      development: 'on',
      test: 'on',
      production:
        'on',
    })
);

/**
 * Whether to allow the technicians to see ag-grid table with device measurements in revision form
 */
export const FEATURE_TOGGLE_DEVICE_MEASUREMENTS_AG_GRID = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_DEVICE_MEASUREMENTS_AG_GRID ||
    getenv('FEATURE_TOGGLE_DEVICE_MEASUREMENTS_AG_GRID', {
      development: 'on',
      test: 'off',
      production:
        'on',
    })
);

/**
 * Whether to allow users to define evidence fields in device subtype form
 */
export const FEATURE_TOGGLE_DEVICE_SUBTYPE_EVIDENCE_FIELDS = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_DEVICE_SUBTYPE_EVIDENCE_FIELDS ||
    getenv('FEATURE_TOGGLE_DEVICE_SUBTYPE_EVIDENCE_FIELDS', {
      development: 'on',
      test: 'off',
      production:
        'on',
    })
);

/**
 * Whether to allow the technicians to see the modal with changes made in revision form
 */
export const FEATURE_TOGGLE_REVISION_CHANGES_MODAL = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_REVISION_CHANGES_MODAL ||
    getenv('FEATURE_TOGGLE_REVISION_CHANGES_MODAL', {
      development: 'on',
      test: 'off',
      production:
        '',
    })
);

/**
 * Whether to allow the users to see the slider with device images
 */
export const FEATURE_TOGGLE_DEVICE_IMAGES_SLIDER = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_DEVICE_IMAGES_SLIDER ||
    getenv('FEATURE_TOGGLE_DEVICE_IMAGES_SLIDER', {
      development: 'on',
      test: 'off',
      production: 'on',
    })
);

/**
 * The destination the user is redirected to after updating company settings
 */
export const COMPANY_SETTINGS_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_COMPANY_SETTINGS_REDIRECT_AFTER_SAVE ||
  getenv('COMPANY_SETTINGS_REDIRECT_AFTER_SAVE', {
    development: 'list',
    test: 'list',
    production: 'list',
  });

/**
 * The destination the user is redirected to after updating a department
 */
export const DEPARTMENT_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_DEPARTMENT_REDIRECT_AFTER_SAVE ||
  getenv('DEPARTMENT_REDIRECT_AFTER_SAVE', {
    development: 'same',
    test: 'same',
    production: 'list',
  });

/**
 * The destination the user is redirected to after updating a device
 */
export const DEVICE_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_DEVICE_REDIRECT_AFTER_SAVE ||
  getenv('DEVICE_REDIRECT_AFTER_SAVE', {
    development: 'same',
    test: 'same',
    production: 'same',
  });

/**
 * The destination the user is redirected to after updating a device subtype
 */
export const DEVICE_SUBTYPE_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_DEVICE_SUBTYPE_REDIRECT_AFTER_SAVE ||
  getenv('DEVICE_SUBTYPE_REDIRECT_AFTER_SAVE', {
    development: 'list',
    test: 'list',
    production: 'list',
  });

/**
 * The destination the user is redirected to after updating a measuring device
 */
export const MEASURING_DEVICE_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_MEASURING_DEVICE_REDIRECT_AFTER_SAVE ||
  getenv('MEASURING_DEVICE_REDIRECT_AFTER_SAVE', {
    development: 'list',
    test: 'list',
    production: 'list',
  });

/**
 * The destination the user is redirected to after updating a certificate
 */
export const MY_CERTIFICATES_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_MY_CERTIFICATES_REDIRECT_AFTER_SAVE ||
  getenv('MY_CERTIFICATES_REDIRECT_AFTER_SAVE', {
    development: 'list',
    test: 'list',
    production: 'list',
  });

/**
 * The destination the user is redirected to after updating their profile
 */
export const MY_PROFILE_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_MY_PROFILE_REDIRECT_AFTER_SAVE ||
  getenv('MY_PROFILE_REDIRECT_AFTER_SAVE', {
    development: 'list',
    test: 'list',
    production: 'list',
  });

/**
 * The destination the user is redirected to after updating notification settings
 */
export const NOTIFICATION_SETTINGS_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_NOTIFICATION_SETTINGS_REDIRECT_AFTER_SAVE ||
  getenv('NOTIFICATION_SETTINGS_REDIRECT_AFTER_SAVE', {
    development: 'list',
    test: 'list',
    production:
      'list',
  });

/**
 * The destination the user is redirected to after updating an organization
 */
export const ORGANIZATION_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_ORGANIZATION_REDIRECT_AFTER_SAVE ||
  getenv('ORGANIZATION_REDIRECT_AFTER_SAVE', {
    development: 'same',
    test: 'same',
    production: 'list',
  });

/**
 * The destination the user is redirected to after updating predefined data
 */
export const PREDEFINED_DATA_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_PREDEFINED_DATA_REDIRECT_AFTER_SAVE ||
  getenv('PREDEFINED_DATA_REDIRECT_AFTER_SAVE', {
    development: 'list',
    test: 'list',
    production: 'list',
  });

/**
 * The destination the user is redirected to after updating a revision
 */
export const REVISION_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_REVISION_REDIRECT_AFTER_SAVE ||
  getenv('REVISION_REDIRECT_AFTER_SAVE', {
    development: 'list',
    test: 'list',
    production: 'list',
  });

/**
 * The destination the user is redirected to after updating a revision template
 */
export const REVISION_TEMPLATE_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_REVISION_TEMPLATE_REDIRECT_AFTER_SAVE ||
  getenv('REVISION_TEMPLATE_REDIRECT_AFTER_SAVE', {
    development: 'list',
    test: 'list',
    production: 'list',
  });

/**
 * The destination the user is redirected to after updating a user
 */
export const USER_REDIRECT_AFTER_SAVE =
  process.env.REACT_APP_USER_REDIRECT_AFTER_SAVE ||
  getenv('USER_REDIRECT_AFTER_SAVE', {
    development: 'same',
    test: 'same',
    production: 'list',
  });

/**
 * Whether to allow the admins to see the Revision Template Editor
 */
export const FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR ||
    getenv('FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR', {
      development: 'on',
      test: 'off',
      production:
        'on',
    })
);

/**
 * Whether to allow the admins to see the Revision Template Editor Automations
 */
export const FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR_AUTOMATIONS = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR_AUTOMATIONS ||
    getenv('FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR_AUTOMATIONS', {
      development: 'on',
      test: 'off',
      production:
        'on',
    })
);

/**
 * Whether to allow the admins to see the Revision Plan Change Log
 */
export const FEATURE_TOGGLE_REVISION_PLAN_CHANGE_LOG = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_REVISION_PLAN_CHANGE_LOG ||
    getenv('FEATURE_TOGGLE_REVISION_PLAN_CHANGE_LOG', {
      development: 'on',
      test: 'off',
      production:
        '',
    })
);

/**
 * Whether to show the device id in the revision fill out form
 */
export const FEATURE_TOGGLE_SHOW_DEVICE_ID_IN_REVISION_FILL_OUT_FORM = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_SHOW_DEVICE_ID_IN_REVISION_FILL_OUT_FORM ||
    getenv('FEATURE_TOGGLE_SHOW_DEVICE_ID_IN_REVISION_FILL_OUT_FORM', {
      development: 'on',
      test: 'off',
      production:
        '',
    })
);

/**
 * Whether to allow the users to see the data table profiles
 */
export const FEATURE_TOGGLE_DATA_TABLE_PROFILES = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_DATA_TABLE_PROFILES ||
    getenv('FEATURE_TOGGLE_DATA_TABLE_PROFILES', {
      development: 'on',
      test: 'off',
      production: 'off',
    })
);
