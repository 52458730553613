import { ColDef, GridApi } from 'ag-grid-community';
import DataTable from 'components/tables/DataTable';
import { SweetPath } from 'sweet-path';
import {
  GlobalPredefinedDataTypeSelectFilter,
  PersonalPredefinedDataTypeSelectFilter,
} from 'components/tables/filters/PredefinedDataTypeSelectFilter';
import DeviceTypeSelectFilter from 'components/tables/filters/DeviceTypeSelectFilter';
import { useCallback, useMemo } from 'react';
import DeviceTypeColumn from 'components/tables/data/predefined/columns/DeviceTypeColumn';
import PredefinedDataTypeColumn from 'components/tables/data/predefined/columns/PredefinedDataTypeColumn';
import KeyColumn from 'components/tables/data/predefined/columns/KeyColumn';
import ValueColumn from 'components/tables/data/predefined/columns/ValueColumn';
import { PredefinedDataRow } from 'components/tables/data/predefined/types';
import PredefinedDataActions from 'components/tables/data/predefined/PredefinedDataActions';

/**
 * Parameters of the PredefinedDataTable component.
 */
export interface PreDefinedDataTableProps<TData> {
  action: () => Promise<TData[]>;
  addPath: string;
  editPath: SweetPath<string>;
  tableType: 'user' | 'global';
}

/**
 * Table which displays users.
 */
export default function PredefinedDataTable<
  TData extends { deviceType: { deviceTypeName: string; slug: string }; predefinedDataType: { name: string } }
>({ action, addPath, editPath, tableType }: PreDefinedDataTableProps<TData>) {
  /**
   * Column for the actions.
   */
  const ActionsColumn = useMemo(
    () =>
      function ActionsColumn({ api, data }: { api: GridApi; data: PredefinedDataRow }) {
        const predefinedDataId = tableType === 'user' ? data.usersPredefinedDataId : data.globalPredefinedDataId;

        return (
          <PredefinedDataActions
            predefinedDataId={predefinedDataId as number}
            predefinedDataName={data.key}
            editPath={editPath}
            tableType={tableType}
            onDelete={() => api.applyTransaction({ remove: [data] })}
          />
        );
      },
    [editPath, tableType]
  );

  /**
   * The columns of the table.
   */
  const columns: ColDef[] = useMemo(
    () => [
      {
        field: 'globalPredefinedDataId',
        headerName: 'ID predvoleného údaja',
        hide: true,
      },
      {
        field: 'usersPredefinedDataId',
        headerName: 'ID predvoleného údaja',
        hide: true,
      },
      {
        field: 'deviceTypeName',
        headerName: 'Názov typu zariadenia',
        filter: true,
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        cellStyle: { opacity: 0 },
      },
      {
        field: 'deviceTypeSlug',
        headerName: '',
        filter: true,
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        cellStyle: { opacity: 0 },
      },
      {
        field: '_deviceType.deviceTypeId',
        headerName: 'ID typu zariadenia',
        hide: true,
      },
      {
        field: 'deviceType.deviceTypeId',
        headerName: 'Typ zariadenia',
        minWidth: 220,
        sortable: true,
        resizable: true,
        unSortIcon: true,
        wrapText: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: DeviceTypeSelectFilter,
        cellRenderer: DeviceTypeColumn,
      },
      {
        field: 'predefinedDataTypeName',
        headerName: 'Typ údaju',
        filter: true,
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        cellStyle: { opacity: 0 },
      },
      {
        field: 'predefinedDataType.predefinedDataTypeId',
        headerName: 'Typ údaju',
        minWidth: 275,
        sortable: true,
        resizable: true,
        unSortIcon: true,
        wrapText: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent:
          tableType === 'user' ? GlobalPredefinedDataTypeSelectFilter : PersonalPredefinedDataTypeSelectFilter,
        cellRenderer: PredefinedDataTypeColumn,
      },
      {
        field: 'key',
        headerName: 'Názov',
        minWidth: 450,
        sortable: true,
        resizable: true,
        unSortIcon: true,
        wrapText: true,
        cellRenderer: KeyColumn,
      },
      {
        field: 'value',
        headerName: 'Popis',
        resizable: true,
        unSortIcon: true,
        wrapText: true,
        minWidth: 300,
        cellRenderer: ValueColumn,
      },
      {
        field: '_actions',
        headerName: '',
        pinned: 'right',
        width: 194,
        maxWidth: 194,
        minWidth: 194,
        cellRenderer: ActionsColumn,
      },
    ],
    [ActionsColumn, tableType]
  );

  const tableAction = useCallback(async () => {
    const data = await action();

    return data.map((item) => ({
      ...item,
      _deviceType: item.deviceType,
      deviceTypeName: item.deviceType.deviceTypeName,
      deviceTypeSlug: item.deviceType.slug,
      predefinedDataTypeName: item.predefinedDataType.name,
    }));
  }, [action]);

  const columnKeys = [];

  if (tableType === 'user') {
    columnKeys.push('usersPredefinedDataId');
  } else if (tableType === 'global') {
    columnKeys.push('globalPredefinedDataId');
  }

  columnKeys.push('predefinedDataTypeName', 'key', 'value', 'deviceTypeName', '_deviceType.deviceTypeId');

  return (
    <DataTable
      tableId={`predefined-data-${tableType}`}
      title={tableType === 'user' ? 'Predvolené údaje' : 'Globálne predvolené údaje'}
      hideToggleDiscarded
      columns={columns}
      action={tableAction}
      addButtonText="Pridať nový"
      addButtonTarget={addPath}
      dataExport={{
        modalTitle: tableType === 'user' ? 'Exportovať predvolené údaje' : 'Exportovať globálne predvolené údaje',
        fileName: tableType === 'user' ? 'predvolene-udaje.xlsx' : 'globalne-predvolene-udaje.xlsx',
        columnKeys,
      }}
    />
  );
}
