import RevisionPeriodSelect from 'components/inputs/revision-period-select/RevisionPeriodSelect';
import { IFormInputSpecPeriod } from 'pages/revisions-module/template-editor/editors/form/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';

/**
 * Period select input for the revision form.
 */
export default function RevisionFieldPeriod({ spec, name }: RevisionFieldProps<IFormInputSpecPeriod>) {
  const { form } = useFillOutRevisionDataProvider();
  const { value, onChange } = form.getInputProps(name);

  return (
    <RevisionPeriodSelect
      size="lg"
      label={spec.label}
      required={spec.required}
      readOnly={spec.readOnly}
      value={String(value)}
      onChange={(value) => onChange(Number(value))}
      clearable={!spec.required}
    />
  );
}
