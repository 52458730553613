import { Select } from '@mantine/core';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { IFormInputSpecSafetyPoint } from 'pages/revisions-module/template-editor/editors/form/types';
import { useCallback, useMemo } from 'react';

/**
 * Select input for the revision form.
 */
export default function RevisionFieldSelect({ spec, name }: RevisionFieldProps<IFormInputSpecSafetyPoint>) {
  const { form } = useFillOutRevisionDataProvider();

  const options = useMemo(
    () => [
      { value: 'suitable', label: spec.suitableText },
      { value: 'not-suitable', label: spec.notSuitableText },
      { value: 'with-reservations', label: spec.withReservationsText },
    ],
    [spec.suitableText, spec.withReservationsText, spec.notSuitableText]
  );

  const getLabel = useCallback((value: string) => options.find((option) => option.value === value)?.label, [options]);

  const { value, onChange } = useMemo(() => form.getInputProps(name), [form, name]);

  return (
    <Select
      required={spec.required}
      readOnly={spec.readOnly}
      size="lg"
      label={spec.label}
      searchable
      data={options}
      name={name}
      value={value?.value ?? ''}
      onChange={(value) => {
        if (value) {
          onChange({ value, label: getLabel(value) });
        }
      }}
      clearable={!spec.required}
    />
  );
}
