import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import MeasuringDeviceTable from 'components/tables/data/measuring-device/MeasuringDeviceTable';
import { SETTINGS_PAGE_PATH } from 'routes/paths';
/**
 * Page used to display measuring devices.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=251%3A8262 Figma Design}
 * - {@link https://www.notion.so/Pre-defined-Data-List-Measuring-Devices-031b03fe746b423f8e58fc7cdf97fc15?pvs=4 Notion Page}
 */
export default function MeasuringDevicesPage() {
  return (
    <DashboardLayout
      height="screen"
      breadcrumbs={[{ title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original }, { title: 'Meracie prístroje' }]}
    >
      <MeasuringDeviceTable />
    </DashboardLayout>
  );
}
