import { GridApi } from 'ag-grid-community';
import { PredefinedDataTypeSelect } from 'components/selects/PredefinedDataTypeSelect';
import { useCallback, useEffect, useState } from 'react';
import { FILTER_KEY as DEVICE_TYPE_SELECT_FILTER_KEY } from 'components/tables/filters/DeviceTypeSelectFilter';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'predefinedDataType.predefinedDataTypeId' as const;

/**
 * Parameters of the PredefinedDataDataTypeSelectFilter component.
 */
export interface PredefinedDataTypeSelectFilterProps {
  api: GridApi;
  context: any;
}

interface ConfiguredPredefinedDataTypeSelectFilterProps extends PredefinedDataTypeSelectFilterProps {
  isGlobal?: boolean;
  isPersonal?: boolean;
}

/**
 * Filter component for the predefinedDataType.predefinedDataTypeId column.
 *
 * This filter assumes:
 *   - column field is `predefinedDataType.predefinedDataTypeId`
 */
function PredefinedDataTypeSelectFilterImpl({
  api,
  context,
  isGlobal = false,
  isPersonal = false,
}: ConfiguredPredefinedDataTypeSelectFilterProps) {
  const [value, setValue] = useState<string | null>(context?.initialFilters?.[FILTER_KEY]?.filter);
  const [deviceTypeId, setDeviceTypeId] = useState<number | null>(0);

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [FILTER_KEY]: { filterType: 'text', type: 'equals', filter: value },
      });
    },
    [api, setValue]
  );

  useEffect(() => {
    /**
     * Handles the change of the device type id.
     */
    const onFilterChanged = () => {
      const deviceTypeId = api.getFilterModel()[DEVICE_TYPE_SELECT_FILTER_KEY]?.filter ?? 0;
      setDeviceTypeId(deviceTypeId);
    };

    api.addEventListener('filterChanged', onFilterChanged);
    return () => api.removeEventListener('filterChanged', onFilterChanged);
  }, []);

  return (
    <PredefinedDataTypeSelect
      lazyLoad
      deviceTypeId={deviceTypeId ?? undefined}
      isGlobal={isGlobal}
      isPersonal={isPersonal}
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky typy údajov"
      value={value}
      onChange={onChange}
    />
  );
}

/**
 * Filter component for the predefinedDataType.predefinedDataTypeId column (global version).
 */
export function GlobalPredefinedDataTypeSelectFilter(props: PredefinedDataTypeSelectFilterProps) {
  return <PredefinedDataTypeSelectFilterImpl {...props} isGlobal />;
}

/**
 * Filter component for the predefinedDataType.predefinedDataTypeId column (personal version).
 */
export function PersonalPredefinedDataTypeSelectFilter(props: PredefinedDataTypeSelectFilterProps) {
  return <PredefinedDataTypeSelectFilterImpl {...props} isPersonal />;
}
