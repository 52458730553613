import FaultTable from 'components/tables/data/fault/FaultTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';

/**
 * Displays a list of all faults for all devices.
 */
export default function FaultsPage() {
  return (
    <DashboardLayout height="screen" breadcrumbs={[{ title: 'Závady zariadení' }]}>
      <FaultTable />
    </DashboardLayout>
  );
}
