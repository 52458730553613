import { ScrollArea, Stack, Table } from '@mantine/core';
import P1Medium from 'components/typography/P1Medium';
import P2Regular from 'components/typography/P2Regular';
import { DEVICE_TYPES } from 'model/DeviceType';
import { DEVICE_TYPES_PAGE_PATH } from 'routes/paths';

/**
 * Displays an import hint for the device table.
 */
export default function ImportHint() {
  return (
    <ScrollArea h="60vh">
      <Stack spacing={16}>
        <Stack spacing={8}>
          <P1Medium>1. XML Schéma</P1Medium>
          <P2Regular>
            Pre import zariadení je potrebné mať XML súbor, ktorý bude obsahovať zoznam zariadení. XML súbor musí byť
            validný voči schéme, ktorá je dostupná na nasledujúcom odkaze:{' '}
            <a href="/resources/import-device-schema.xsd" target="_blank">
              XML schéma
            </a>
            .
          </P2Regular>
        </Stack>
        <Stack spacing={8}>
          <P1Medium>2. Zariadenia</P1Medium>
          <P2Regular>Nasledujúca tabuľka obsahuje zoznam zariadení, ktoré je možné importovať do systému.</P2Regular>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Zariadenie</th>
              </tr>
            </thead>
            <tbody>
              {DEVICE_TYPES.map(({ id, name }) => (
                <tr key={id}>
                  <td>{id}</td>
                  <td>{name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Stack>
        <Stack spacing={8}>
          <P1Medium>3. Typy zariadení</P1Medium>
          <P2Regular>
            Typy zariadení nájdete na stránke{' '}
            <a href={DEVICE_TYPES_PAGE_PATH.original} target="_blank" rel="noreferrer">
              Typy zariadení
            </a>
            .
          </P2Regular>
        </Stack>
      </Stack>
    </ScrollArea>
  );
}
