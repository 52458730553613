import RevisionTable from 'components/tables/data/revision/RevisionTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';

/**
 * Page used to display revisions.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=233%3A10497 Figma Design}
 * - {@link https://www.notion.so/Revisions-List-Revisions-7f2582bc435a42f3b77b9c52debd5602?pvs=4 Notion Page}
 */
export default function RevisionsPage() {
  return (
    <DashboardLayout height="screen" breadcrumbs={[{ title: 'Revízne správy' }]}>
      <RevisionTable />
    </DashboardLayout>
  );
}
