import { GridApi } from 'ag-grid-community';
import { DepartmentSelect } from 'components/selects/DepartmentSelect';
import { useEffect, useState } from 'react';
import { FILTER_KEY as ORGANIZATION_SELECT_FILTER_KEY } from 'components/tables/filters/OrganizationSelectFilter';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'department.departmentId' as const;

/**
 * Parameters of the DepartmentSelectFilter component.
 */
export interface DepartmentSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

interface ConfigureDepartmentSelectFilterProps {
  permissionSlug?: string;
}

type FullDepartmentSelectFilterProps = DepartmentSelectFilterProps & ConfigureDepartmentSelectFilterProps;

/**
 * Filter component for the department column.
 *
 * This filter assumes:
 *   - column field is `department.departmentId`
 */
export default function DepartmentSelectFilter({ api, onChange, permissionSlug }: FullDepartmentSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });
  const [organizationId, setOrganizationId] = useState<number | null>(-1);

  useEffect(() => {
    /**
     * Handles the change of the organization id.
     */
    const onFilterChanged = () => {
      const organizationId = api.getFilterModel()[ORGANIZATION_SELECT_FILTER_KEY]?.filter ?? -1;
      setOrganizationId(organizationId);
    };

    api.addEventListener('filterChanged', onFilterChanged);
    return () => api.removeEventListener('filterChanged', onFilterChanged);
  }, []);

  return (
    <DepartmentSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky strediská"
      value={String(value)}
      onChange={setValue}
      groupByOrganization
      organizationId={organizationId ?? -1}
      permissionSlug={permissionSlug}
    />
  );
}

DepartmentSelectFilter.configure =
  (config: ConfigureDepartmentSelectFilterProps) => (props: DepartmentSelectFilterProps) =>
    <DepartmentSelectFilter {...config} {...props} />;
