import { RevisionBulkActionProps, RevisionRow } from 'components/tables/data/revision/types';
import { useEffect, useState } from 'react';
import { Button, Skeleton } from '@mantine/core';
import { IconRubberStamp } from '@tabler/icons-react';
import useConfirmAction from 'components/tables/data/revision/actions/hooks/use-confirm-action';
import { noop } from 'lodash';
import BulkConfirmMessage from 'components/tables/data/revision/actions/bulk/BulkConfirmMessage';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Performs a bulk confirm on all selected rows.
 */
export default function BulkConfirmAction({
  revisions,
  onStatusChange = noop,
  onBulkActionComplete = noop,
}: RevisionBulkActionProps) {
  const { getAction, roleId } = useApi();
  const { confirm } = useConfirm();
  const { confirmRevision } = useConfirmAction();
  const [loading, setLoading] = useState(true);
  const [availableRevisions, setAvailableRevisions] = useState<RevisionRow[]>([]);

  useEffect(() => {
    setLoading(true);

    const availableRevisions = revisions.filter(
      ({ revisionStatus: { slug } }) => slug === 'approved-waiting-for-confirmation'
    );

    if (availableRevisions.length === 0) {
      setAvailableRevisions([]);
      setLoading(false);
    } else if (roleId === ROLE_ADMIN_ID) {
      setAvailableRevisions(availableRevisions);
      setLoading(false);
    } else {
      const action = getAction('AuthGetDepartmentsWithPermission');

      action({ parameters: { permissionSlug: 'accept-revisions' } })
        .then((departments) => {
          const departmentIds = new Set(departments.map(({ departmentId }) => departmentId));

          const actionableRevisions = availableRevisions.filter(({ department: { departmentId } }) =>
            departmentIds.has(departmentId)
          );

          setAvailableRevisions(actionableRevisions);
        })
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }, [revisions]);

  /**
   * Confirms all selected revisions.
   */
  const confirmAll = () =>
    confirm({
      title: `Prebrať revízne správy (${availableRevisions.length})`,
      content: (
        <BulkConfirmMessage
          message="Naozaj si prajete prebrať vybrané revízne správy?"
          revisions={availableRevisions}
        />
      ),
      onConfirm: async () => {
        for (const revision of availableRevisions) {
          await confirmRevision({ revision, onStatusChange });
        }
        onBulkActionComplete();
      },
    });

  if (loading) {
    return <Skeleton w={120} h={32} radius={4} />;
  }

  if (availableRevisions.length === 0) {
    return <></>;
  }

  return (
    <Button variant="primary" size="sm" leftIcon={<IconRubberStamp />} onClick={confirmAll}>
      Prebrať ({availableRevisions.length})
    </Button>
  );
}
