import P2Regular from 'components/typography/P2Regular';
import { RevisionPlanRow } from 'components/tables/data/revision-plan/types';
import Excerpt from 'components/Excerpt';

/**
 * Column for the description.
 */
export default function DescriptionColumn({ data: { description } }: { data: RevisionPlanRow }) {
  return (
    <P2Regular color="gray.8">
      <Excerpt text={description ?? '-'} length={30} />
    </P2Regular>
  );
}
