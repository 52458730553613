import { Box, Grid, Menu, Portal } from '@mantine/core';
import { Stack, useMantineTheme } from '@mantine/core';
import { DeviceListRevisionPlanResponse } from 'api/actions/device-list-revision-plan/device-list-revision-plan-response';
import { RevisionPlanDate } from 'components/tables/data/revision-plan/RevisionPlanCalendarUtils';
import P2Medium from 'components/typography/P2Medium';
import { useCallback, useState } from 'react';
import PlanItem from 'components/tables/data/revision-plan/PlanItem';
import P4Medium from 'components/typography/P4Medium';
import { useDisclosure } from '@mantine/hooks';
import { useSearchParams } from 'react-router-dom';

type RevisionPlanRow = DeviceListRevisionPlanResponse[number];

interface RevisionPlanCalendarCellProps {
  dateItem: RevisionPlanDate;
  cellHeight: string;
  getPlanForDate: (date: Date) => RevisionPlanRow[] | undefined;
}

/**
 * Single cell in the Revision Plan calendar.
 */
export default function RevisionPlanCalendarCell({
  dateItem,
  cellHeight,
  getPlanForDate,
}: RevisionPlanCalendarCellProps) {
  const theme = useMantineTheme();
  const [searchParams] = useSearchParams();

  const [menuOpened, { toggle: toggleMenu }] = useDisclosure(false);
  const [currentDate] = useState(new Date());

  const year = searchParams.get('year') ?? '';
  const month = searchParams.get('month') ?? '';
  const day = searchParams.get('day') ?? '';

  const plansForDate = getPlanForDate(dateItem.date);

  const visiblePlans = plansForDate?.slice(0, 2);
  const rest = plansForDate?.slice(2);

  const isToday = useCallback(
    (dateItem: RevisionPlanDate) => {
      return (
        dateItem.date.getDate() === currentDate.getDate() &&
        dateItem.date.getMonth() === currentDate.getMonth() &&
        dateItem.date.getFullYear() === currentDate.getFullYear()
      );
    },
    [currentDate]
  );

  const isChosenDay = useCallback(
    (date: Date) => {
      if (year !== '' && month !== '' && day !== '') {
        return (
          date.getDate() === Number(day) && date.getMonth() === Number(month) - 1 && date.getFullYear() === Number(year)
        );
      }

      return false;
    },
    [year, month, day]
  );

  const isChosenDayItem = isChosenDay(dateItem.date);

  return (
    <Grid.Col
      span={1}
      style={{
        border: `1px solid ${isChosenDayItem ? theme.colors.blue[5] : '#DCDCE5'}`,
        textAlign: 'center',
        color: dateItem.isCurrentMonth ? theme.colors.gray[7] : theme.colors.gray[5],
        height: cellHeight,
        padding: '7px',
        backgroundColor: isChosenDayItem ? theme.colors.blue[1] : undefined,
      }}
    >
      <P2Medium color={isToday(dateItem) ? 'red.8' : dateItem.isCurrentMonth ? 'gray.7' : 'gray.5'}>
        {dateItem.date.getDate()}
        {isToday(dateItem) ? ' | dnes' : ''}
      </P2Medium>
      <Stack spacing={0} style={{ textAlign: 'left', wordWrap: 'normal' }}>
        {visiblePlans!.map(
          ({
            revisionPlanId,
            revisionPeriod,
            type,
            device,
            organization,
            department,
            deviceType,
            deviceSubtype,
            nextRevision,
          }) => (
            <PlanItem
              key={`${type}-${device.deviceName}`}
              revisionPlanId={revisionPlanId}
              revisionPeriod={revisionPeriod}
              type={type}
              deviceId={device.deviceId}
              deviceName={device.deviceName}
              manufactured={device.manufactured}
              manufacturer={device.manufacturer}
              serialNumber={device.serialNumber}
              organization={organization}
              department={department}
              nextRevision={nextRevision ? new Date(nextRevision) : undefined}
              deviceType={deviceType}
              deviceSubtype={deviceSubtype}
              dateItem={dateItem}
              initialOpened={
                searchParams.get('revisionPlanId') === null
                  ? false
                  : revisionPlanId === Number(searchParams.get('revisionPlanId'))
              }
            />
          )
        )}
        {rest!.length > 0 && (
          <Menu position="bottom" opened={menuOpened}>
            <Menu.Target>
              <Box style={{ cursor: 'pointer' }} p={4} onClick={toggleMenu}>
                <P4Medium color="blue.8">ďalšie termíny ({rest?.length})</P4Medium>
              </Box>
            </Menu.Target>
            <Portal>
              <Menu.Dropdown>
                <Stack spacing={4} p={4}>
                  {rest?.map(
                    ({
                      revisionPlanId,
                      revisionPeriod,
                      type,
                      device,
                      organization,
                      department,
                      deviceType,
                      deviceSubtype,
                      nextRevision,
                    }) => (
                      <PlanItem
                        key={`${type}-${device.deviceName}`}
                        revisionPlanId={revisionPlanId}
                        revisionPeriod={revisionPeriod}
                        type={type}
                        deviceId={device.deviceId}
                        deviceName={device.deviceName}
                        manufactured={device.manufactured}
                        manufacturer={device.manufacturer}
                        serialNumber={device.serialNumber}
                        organization={organization}
                        department={department}
                        nextRevision={nextRevision ? new Date(nextRevision) : undefined}
                        deviceType={deviceType}
                        deviceSubtype={deviceSubtype}
                        dateItem={dateItem}
                      />
                    )
                  )}
                </Stack>
              </Menu.Dropdown>
            </Portal>
          </Menu>
        )}
      </Stack>
    </Grid.Col>
  );
}
