import FaultSeverityLabel from 'components/device/FaultSeverityLabel';
import { FaultRow } from 'components/tables/data/fault/types';

/**
 * Column for the fault severity name.
 */
export default function FaultSeverityNameColumn({ data: { faultSeverity, fixed } }: { data: FaultRow }) {
  return (
    <FaultSeverityLabel
      faultSeverityId={faultSeverity.faultSeverityId}
      faultSeverityName={faultSeverity.faultSeverityName}
      isFaultFixed={fixed?.fixedAt ? true : false}
    />
  );
}
