import { Box, Flex } from '@mantine/core';
import { RevisionPlanRow } from 'components/tables/data/revision-plan/types';
import { IconMapPinPlus } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for the department name.
 */
export default function DepartmentNameColumn({ data: { department } }: { data: RevisionPlanRow }) {
  return (
    <Flex gap={8} align="center">
      <Box w={24} h={24}>
        <IconMapPinPlus size={24} stroke="1.5" />
      </Box>
      <P2Regular color="gray.8">{department.departmentName}</P2Regular>
    </Flex>
  );
}
