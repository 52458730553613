import { Select } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'organizationSelfAdministration' as const;

/**
 * Options for the select.
 */
export const OPTIONS = [
  { value: 'Áno', label: 'Spravuje sa sama' },
  { value: 'Nie', label: 'Nespravuje sa sama' },
] as const;

/**
 * Parameters of the SelfAdministrationSelectFilter component.
 */
export interface SelfAdministrationSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the role column.
 *
 * This filter assumes:
 *   - column field is `organizationSelfAdministration`
 */
export default function SelfAdministrationSelectFilter({ api, onChange }: SelfAdministrationSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });

  return (
    <Select
      data={OPTIONS}
      clearable
      searchable
      w="100%"
      size="sm"
      placeholder="Všetky typy správy"
      value={value}
      onChange={setValue}
    />
  );
}
