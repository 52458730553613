import { rem } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { DeviceTypeSelect } from 'components/selects/DeviceTypeSelect';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'deviceType.deviceTypeId' as const;

/**
 * Parameters of the DeviceTypeSelectFilter component.
 */
export interface DeviceTypeSelectFilterProps {
  api: GridApi;
  onChange: FilterChangeCallback;
}

/**
 * Filter component for the deviceType column.
 *
 * This filter assumes:
 *   - column field is `deviceType.deviceTypeId`
 */
export default function DeviceTypeSelectFilter({ api, onChange }: DeviceTypeSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });

  return (
    <DeviceTypeSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky zariadenia"
      value={String(value)}
      onChange={setValue}
      styles={{ dropdown: { minWidth: rem(220) } }}
    />
  );
}
