import { DeviceSelect } from 'components/selects/DeviceSelect';
import { GridApi } from 'ag-grid-community';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = '_search.deviceId' as const;

/**
 * Parameters of the RevisionDeviceSelectFilter component.
 */
export interface RevisionDeviceSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the `deviceId` column.
 *
 * This filter assumes:
 *  - column field is `deviceId`
 */
export default function RevisionDeviceSelectFilter({ api, onChange }: RevisionDeviceSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange, type: 'contains' });

  return (
    <DeviceSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky názvy zariadení"
      value={String(value)}
      onChange={setValue}
      showDiscarded={false}
    />
  );
}
