import { Button, Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeModal, openModal } from '@mantine/modals';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import P3Medium from 'components/typography/P3Medium';
import panic from 'errors/panic';
import { noop } from 'lodash';
import { useCallback, useState } from 'react';

/**
 * Parameters of the prompt function.
 */
export interface IPromptParams {
  message?: string;
  title?: string;
  initialValue?: string;
  placeholder?: string;
  maxLength?: number;
  onConfirm?: (value: string) => void | Promise<void>;
}

const MODAL_ID = 'prompt';

/**
 * The prompt modal.
 */
function PromptModal({
  initialValue = '',
  message = '',
  placeholder = '',
  maxLength,
  onConfirm = noop,
}: IPromptParams) {
  const form = useForm({
    initialValues: {
      value: initialValue,
    },
    validate: {
      value: createValidator([required]),
    },
  });

  const [loading, setLoading] = useState(false);

  /** Closes the modal. */
  const close = () => closeModal(MODAL_ID);

  /** Submits the form. */
  async function submit({ value }: { value: string }) {
    try {
      setLoading(true);
      await onConfirm(value);
      setLoading(false);
      close();
    } catch (err: any) {
      panic(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={24}>
        <Stack spacing={2}>
          <TextInput
            label={message}
            size="md"
            disabled={loading}
            placeholder={placeholder}
            data-autofocus
            maxLength={maxLength}
            {...form.getInputProps('value')}
          />
          {maxLength && (
            <P3Medium c="gray.5">
              <Group spacing={4} position="right">
                <span>Použité znaky:</span>
                <Group spacing={2}>
                  <span>{form.values.value.length}</span>
                  <span>/</span>
                  <span>{maxLength}</span>
                </Group>
              </Group>
            </P3Medium>
          )}
        </Stack>
        <Group position="apart">
          <Button variant="link" onClick={close}>
            Zrušiť
          </Button>
          <Button variant="primary" type="submit">
            Potvrdiť
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

/**
 * Uses the PromptContext.
 */
export function usePrompt() {
  return useCallback(
    ({ title, ...props }: IPromptParams) =>
      openModal({
        modalId: MODAL_ID,
        title,
        children: <PromptModal {...props} />,
        withinPortal: true,
      }),
    []
  );
}
