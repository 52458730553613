import { IFilterParams } from 'ag-grid-community';
import { isEqual } from 'lodash';
import { forwardRef, useImperativeHandle, useRef } from 'react';

export default forwardRef(({ filterChangedCallback }: IFilterParams, ref) => {
  const value = useRef<number[]>([]);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass({ data }: { data: { revisionStatus: { revisionStatusId: number } } }) {
        return value.current.includes(data.revisionStatus.revisionStatusId);
      },

      getModel() {
        if (value.current.length === 0) {
          return null;
        }

        return { filter: value.current };
      },

      setModel(model: { filter: number[] }) {
        let filter = model?.filter ?? [];

        if (!Array.isArray(filter)) {
          filter = [];
        }

        value.current = filter;
      },

      isFilterActive() {
        return value.current.length > 0;
      },

      onFloatingFilterChanged(newValue: number[]) {
        if (!isEqual(value.current, newValue)) {
          value.current = newValue;
          filterChangedCallback();
        }
      },
    };
  });

  return <></>;
});
