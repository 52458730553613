import { P1Medium, P2Medium, P3Medium } from 'theme/typography';
import { ButtonProps, MantineThemeOverride, rem } from '@mantine/core';

export type ButtonTheme = NonNullable<MantineThemeOverride['components']>['Button'];

export const ButtonDefaultProps: Partial<ButtonProps> = {
  variant: 'primary',
  size: 'md',
};

export const Button: ButtonTheme = {
  defaultProps: ButtonDefaultProps,
  variants: {
    primary: (theme) => ({
      root: {
        color: theme.colors.gray[0],
        backgroundColor: theme.colors.blue[8],
        '&:hover': {
          backgroundColor: theme.colors.blue[9],
        },
        '&:focus': {
          backgroundColor: theme.colors.blue[6],
          boxShadow: '0px 0px 0px 4px #DBE4FF',
        },
        '&:disabled': {
          backgroundColor: theme.colors.blue[2],
          color: theme.colors.gray[0],
        },
      },
    }),
    secondary: (theme) => ({
      root: {
        color: theme.colors.blue[9],
        backgroundColor: theme.colors.blue[1],
        '&:hover': {
          color: theme.colors.blue[9],
          backgroundColor: theme.colors.blue[2],
        },
        '&:focus': {
          color: theme.colors.blue[9],
          backgroundColor: theme.colors.blue[1],
          boxShadow: '0px 0px 0px 4px #DBE4FF',
        },
        '&:disabled': {
          backgroundColor: theme.colors.blue[0],
          color: theme.colors.blue[3],
        },
      },
    }),
    tertiary: (theme) => ({
      root: {
        color: theme.colors.gray[7],
        backgroundColor: theme.white,
        border: '1px solid #DCDCE5',
        '&:hover': {
          color: theme.colors.gray[8],
          backgroundColor: theme.colors.gray[0],
          borderColor: theme.colors.gray[2],
        },
        '&:focus': {
          color: theme.colors.gray[7],
          backgroundColor: theme.white,
          borderColor: theme.colors.gray[0],
          boxShadow: '0px 0px 0px 4px #DBE4FF',
        },
        '&:disabled': {
          color: theme.colors.gray[3],
          backgroundColor: theme.white,
          borderColor: theme.colors.gray[1],
        },
      },
    }),
    subtle: (theme) => ({
      root: {
        color: theme.colors.blue[8],
        backgroundColor: 'transparent',
        '&:hover': {
          color: theme.colors.blue[9],
          backgroundColor: theme.colors.blue[0],
        },
        '&:focus': {
          color: theme.colors.blue[9],
          backgroundColor: theme.white,
          boxShadow: '0px 0px 0px 4px #DBE4FF',
        },
        '&:disabled': {
          backgroundColor: theme.white,
          color: theme.colors.blue[2],
          border: 'none',
        },
      },
    }),
    'subtle-gray': (theme) => ({
      root: {
        color: theme.colors.gray[7],
        backgroundColor: 'transparent',
        '&:hover': {
          color: theme.colors.gray[8],
          backgroundColor: theme.colors.gray[1],
        },
        '&:focus': {
          color: theme.colors.gray[8],
          backgroundColor: theme.white,
          boxShadow: '0px 0px 0px 4px #DBE4FF',
        },
        '&:disabled': {
          backgroundColor: theme.white,
          color: theme.colors.gray[3],
        },
      },
    }),
    'subtle-compact': (theme) => ({
      root: {
        padding: `${rem(2)} !important`,
        color: theme.colors.blue[8],
        backgroundColor: theme.white,
        '&:hover': {
          color: theme.colors.blue[9],
          backgroundColor: theme.colors.blue[0],
        },
        '&:focus': {
          color: theme.colors.blue[9],
          backgroundColor: theme.white,
          boxShadow: '0px 0px 0px 4px #DBE4FF',
        },
        '&:disabled': {
          backgroundColor: theme.white,
          color: theme.colors.blue[2],
          border: 'none',
        },
      },
    }),
    danger: (theme) => ({
      root: {
        color: theme.colors.gray[0],
        backgroundColor: theme.colors.red[8],
        '&:hover': {
          color: theme.colors.gray[0],
          backgroundColor: theme.colors.red[9],
        },
        '&:focus': {
          color: theme.colors.gray[0],
          backgroundColor: theme.colors.red[8],
          boxShadow: '0px 0px 0px 4px #DBE4FF',
        },
        '&:disabled': {
          color: theme.colors.gray[0],
          backgroundColor: theme.colors.red[2],
        },
      },
    }),
    'danger-secondary': (theme) => ({
      root: {
        color: theme.colors.red[8],
        backgroundColor: 'transparent',
        '&:hover': {
          color: theme.colors.red[9],
          backgroundColor: theme.colors.red[0],
        },
        '&:focus': {
          color: theme.colors.red[8],
          backgroundColor: theme.white,
          boxShadow: '0px 0px 0px 4px #DBE4FF',
        },
        '&:disabled': {
          color: theme.colors.red[2],
          backgroundColor: 'transparent',
        },
      },
    }),
  },
  sizes: {
    lg: (theme) => ({
      root: {
        paddingTop: rem(12),
        paddingBottom: rem(12),
        paddingLeft: rem(24),
        paddingRight: rem(24),
        ...P1Medium,
        gap: rem(8),
      },
      icon: {
        width: rem(24),
        height: rem(24),
      },
    }),
    md: (theme) => ({
      root: {
        paddingTop: rem(8),
        paddingBottom: rem(8),
        paddingLeft: rem(16),
        paddingRight: rem(16),
        ...P2Medium,
        gap: rem(8),
      },
      icon: {
        width: rem(24),
        height: rem(24),
      },
    }),
    sm: (theme) => ({
      root: {
        paddingTop: rem(4),
        paddingBottom: rem(4),
        paddingLeft: rem(8),
        paddingRight: rem(8),
        ...P2Medium,
        gap: rem(4),
      },
      icon: {
        width: rem(16),
        height: rem(16),
        marginRight: `${rem(4)} !important`,
      },
    }),
    xs: (theme) => ({
      root: {
        paddingTop: rem(2),
        paddingBottom: rem(2),
        paddingLeft: rem(8),
        paddingRight: rem(8),
        ...P2Medium,
        gap: rem(4),
      },
      leftIcon: {
        width: rem(16),
        height: rem(16),
        marginRight: `${rem(4)} !important`,
      },
      rightIcon: {
        width: rem(16),
        height: rem(16),
        marginLeft: `${rem(4)} !important`,
      },
    }),
    xxs: (theme) => ({
      root: {
        paddingTop: rem(0),
        paddingBottom: rem(0),
        paddingLeft: rem(4),
        paddingRight: rem(4),
        ...P3Medium,
        gap: rem(4),
      },
      leftIcon: {
        width: rem(12),
        height: rem(12),
        marginRight: `${rem(4)} !important`,
      },
      rightIcon: {
        width: rem(12),
        height: rem(12),
        marginLeft: `${rem(4)} !important`,
      },
    }),
  },
  styles: {
    root: {
      height: 'auto',
    },
  },
};
