import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import OrganizationsTable from 'components/tables/data/organization/OrganizationsTable';
import { useMemo } from 'react';

/**
 * Page used to list organizations.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=241%3A6072 Figma Design}
 * - {@link https://www.notion.so/Administrative-List-Organizations-26b6dd7f0a314a989f38b787c8b56094?pvs=4 Notion Page}
 */
export default function OrganizationsPage() {
  const breadcrumbs = useMemo(() => [{ title: 'Organizácie' }], []);

  return (
    <DashboardLayout height="screen" breadcrumbs={breadcrumbs} toggleLabel="Zobraziť vyradené">
      <OrganizationsTable />
    </DashboardLayout>
  );
}
