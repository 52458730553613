import { useApi } from 'api/api-context';
import { RevisionBulkActionProps, RevisionRow } from 'components/tables/data/revision/types';
import { useEffect, useState } from 'react';
import { Button, Skeleton } from '@mantine/core';
import { IconTrashX } from '@tabler/icons-react';
import useDeleteAction from 'components/tables/data/revision/actions/hooks/use-delete-action';
import { noop } from 'lodash';
import BulkConfirmMessage from 'components/tables/data/revision/actions/bulk/BulkConfirmMessage';
import { ROLE_ADMIN_ID } from 'model/Role';
import panic from 'errors/panic';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Performs a bulk delete on all selected rows.
 */
export default function BulkDeleteAction({
  revisions,
  onStatusChange = noop,
  onBulkActionComplete = noop,
}: RevisionBulkActionProps) {
  const { getAction, roleId } = useApi();
  const { confirm } = useConfirm();
  const { deleteRevision } = useDeleteAction();
  const [loading, setLoading] = useState(true);
  const [availableRevisions, setAvailableRevisions] = useState<RevisionRow[]>([]);

  /**
   * Deletes all selected revisions.
   */
  const deleteAll = () =>
    confirm({
      title: `Zmazať revízne správy (${availableRevisions.length})`,
      content: (
        <BulkConfirmMessage message="Naozaj si prajete zmazať vybrané revízne správy?" revisions={availableRevisions} />
      ),
      onConfirm: async () => {
        for (const revision of availableRevisions) {
          await deleteRevision({ revision, onStatusChange });
        }
        onBulkActionComplete();
      },
    });

  useEffect(() => {
    setLoading(true);

    const availableRevisions = revisions.filter(
      ({ revisionStatus: { slug } }) =>
        slug === 'created' ||
        slug === 'declined-by-technician' ||
        slug === 'accepted-by-technician' ||
        slug === 'in-progress'
    );

    if (availableRevisions.length === 0) {
      setAvailableRevisions([]);
      setLoading(false);
    } else if (roleId === ROLE_ADMIN_ID) {
      setAvailableRevisions(availableRevisions);
      setLoading(false);
    } else {
      const action = getAction('AuthGetDepartmentsWithPermission');

      action({ parameters: { permissionSlug: 'assign-revisions' } })
        .then((departments) => {
          const departmentIds = new Set(departments.map(({ departmentId }) => departmentId));

          const actionableRevisions = availableRevisions.filter(({ department: { departmentId } }) =>
            departmentIds.has(departmentId)
          );

          setAvailableRevisions(actionableRevisions);
        })
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }, [revisions]);

  if (loading) {
    return <Skeleton w={120} h={32} radius={4} />;
  }

  if (availableRevisions.length === 0) {
    return <></>;
  }

  return (
    <Button variant="primary" size="sm" leftIcon={<IconTrashX />} onClick={deleteAll}>
      Zmazať ({availableRevisions.length})
    </Button>
  );
}
