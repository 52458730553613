import { Checkbox, Group, Stack } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import P3Regular from 'components/typography/P3Regular';
import { IFormInputSpec } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Sub-form for checkbox input properties
 */
export default function CheckboxInputPropertiesSubForm({ form }: { form: UseFormReturnType<IFormInputSpec> }) {
  return (
    <Stack spacing={12}>
      <P3Regular mb={-6} c="gray.6">
        Prepínače
      </P3Regular>
      <Checkbox
        size="md"
        label={
          <Group spacing={8}>
            Predvolený stav
            <QuestionMarkTooltip color="blue.5" size={14} label="Či je pole predvolene zaškrtnuté alebo nie." />
          </Group>
        }
        {...form.getInputProps('defaultChecked', { type: 'checkbox' })}
      />
    </Stack>
  );
}
