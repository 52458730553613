import { IRowNode } from 'ag-grid-community';
import { UserRow } from 'components/tables/data/user/types';
import UserActions from 'components/tables/data/user/UserActions';

/**
 * Column for the actions.
 */
export default function ActionsColumn({ node, data }: { node: IRowNode<UserRow>; data: UserRow }) {
  return <UserActions {...data} onStatusChange={(status) => node.setData({ ...data, status })} />;
}
