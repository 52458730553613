import { GridApi } from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface Filter {
  filterType?: string;
  type?: string;
  filter: any;
}

export type FilterChangeCallback = (value: Record<string, Filter>) => void;

interface UseFilterValueProps {
  api: GridApi;
  filterKey: string;
  onChange?: FilterChangeCallback;
  filterType?: 'text';
  type?: 'equals' | 'contains';
}

/**
 * Uses a value stored in the filter model and provides a way to update it.
 */
export default function useFilterValue({
  api,
  filterKey,
  onChange,
  filterType = 'text',
  type = 'equals',
}: UseFilterValueProps) {
  const [value, setValue] = useState<string | null>(() => api?.getFilterModel()?.[filterKey]?.filter ?? null);

  const setFilterModel = useCallback(
    (value: string | null) =>
      api.setFilterModel({
        ...api.getFilterModel(),
        [filterKey]: { filterType, type, filter: value },
      }),
    [api, filterKey, filterType, type]
  );

  const onChangeImpl = useCallback(
    (value: string | null) => {
      setValue(value);

      if (onChange) {
        onChange({ [filterKey]: { filterType, type, filter: value } });
      } else {
        setFilterModel(value);
      }
    },
    [setValue, onChange, setFilterModel]
  );

  useEffect(() => {
    /** Handles the filter changed event. */
    const handleFilterChanged = ({ api }: { api: GridApi }) =>
      setValue(api.getFilterModel()[filterKey]?.filter ?? null);

    api.addEventListener('filterChanged', handleFilterChanged);
    return () => api.removeEventListener('filterChanged', handleFilterChanged);
  }, [filterKey]);

  return useMemo(() => [value, onChangeImpl] as const, [value, onChangeImpl]);
}
