import { rem, Select } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { useEffect, useMemo, useState } from 'react';
import { RevisionRow } from 'components/tables/data/revision/types';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

type RevisionTemplate = NonNullable<RevisionRow['revisionTemplate']>;

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'revisionTemplate.slug' as const;

/**
 * Parameters of the RevisionTemplateSelectFilter component.
 */
export interface RevisionTemplateSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the revisionTemplate column.
 *
 * This filter assumes:
 *   - column field is `revisionTemplate.slug`
 */
export default function RevisionTemplateSelectFilter({ api, onChange }: RevisionTemplateSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });
  const [templates, setTemplates] = useState<RevisionTemplate[]>([]);

  const options = useMemo(
    () =>
      templates.map(({ slug, name }) => ({ value: slug, label: name })).sort((a, b) => a.label.localeCompare(b.label)),
    [templates]
  );

  useEffect(() => {
    /** Finds unique templates and sets them as options. */
    const findUniqueTemplates = () => {
      const uniqueTemplates = new Map<string, RevisionTemplate>();

      api.forEachNode(({ displayed, data: { revisionTemplate } }) => {
        if (displayed && revisionTemplate && !uniqueTemplates.has(revisionTemplate.slug)) {
          uniqueTemplates.set(revisionTemplate.slug, revisionTemplate);
        }
      });

      setTemplates(Array.from(uniqueTemplates.values()));
    };

    api.addEventListener('modelUpdated', findUniqueTemplates);
    return () => api.removeEventListener('modelUpdated', findUniqueTemplates);
  }, []);

  return (
    <Select
      data={options}
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky šablóny"
      value={value}
      onChange={setValue}
      styles={{ dropdown: { minWidth: rem(300) } }}
    />
  );
}
