import { ColDef } from 'ag-grid-community';
import { useApi } from 'api/api-context';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DataTable from 'components/tables/DataTable';
import DeviceSubtypeEqualsSelectFilter from 'components/tables/filters/device-subtype/DeviceSubtypeEqualsSelectFilter';
import DeviceTypeSelectFilter from 'components/tables/filters/DeviceTypeSelectFilter';
import DepartmentSelectFilter from 'components/tables/filters/DepartmentSelectFilter';
import OrganizationSelectFilter from 'components/tables/filters/OrganizationSelectFilter';
import DeviceSelectFilter from 'components/tables/filters/DeviceSelectFilter';
import FaultSeverityFilter from 'components/tables/filters/FaultSeveritySelectFilter';
import FaultFixedSelectFilter from 'components/tables/filters/FaultFixedSelectFilter';
import FaultNameColumn from 'components/tables/data/fault/columns/FaultNameColumn';
import DescriptionColumn from 'components/tables/data/fault/columns/DescriptionColumn';
import DeviceNameColumn from 'components/tables/data/fault/columns/DeviceNameColumn';
import FixedAtColumn from 'components/tables/data/fault/columns/FixedAtColumn';
import FaultSeverityNameColumn from 'components/tables/data/fault/columns/FaultSeverityNameColumn';
import FixedNoteColumn from 'components/tables/data/fault/columns/FixedNoteColumn';
import FixedByNameColumn from 'components/tables/data/fault/columns/FixedByNameColumn';
import OrganizationNameColumn from 'components/tables/data/fault/columns/OrganizationNameColumn';
import DepartmentNameColumn from 'components/tables/data/fault/columns/DepartmentNameColumn';
import DeviceTypeNameColumn from 'components/tables/data/fault/columns/DeviceTypeNameColumn';
import DeviceSubtypeNameColumn from 'components/tables/data/fault/columns/DeviceSubtypeNameColumn';
import ActionsColumn from 'components/tables/data/fault/columns/ActionsColumn';
import MoreInfoColumn from 'components/tables/data/fault/columns/MoreInfoColumn';

/**
 * The columns of the table.
 */
const columns: ColDef[] = [
  {
    field: 'faultId',
    headerName: 'ID závady',
    hide: true,
  },
  {
    field: 'faultName',
    headerName: 'Názov závady',
    minWidth: 400,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: FaultNameColumn,
  },
  {
    field: 'description',
    headerName: 'Popis závady',
    minWidth: 250,
    resizable: true,
    wrapText: true,
    cellRenderer: DescriptionColumn,
  },
  {
    field: 'moreInfo',
    headerName: 'Dodatok',
    minWidth: 250,
    resizable: true,
    wrapText: true,
    cellRenderer: MoreInfoColumn,
  },
  {
    field: 'deviceId',
    headerName: 'ID zariadenia',
    filter: true,
    hide: true,
  },
  {
    field: 'deviceName',
    headerName: 'Názov zariadenia',
    minWidth: 350,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DeviceSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceNameColumn,
  },
  {
    field: 'faultSeverity.faultSeverityId',
    headerName: 'ID závažnosti',
    hide: true,
    filter: true,
  },
  {
    field: 'faultSeverity.faultSeverityName',
    headerName: 'Závažnosť',
    minWidth: 240,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: FaultSeverityFilter,
    resizable: true,
    wrapText: true,
    cellRenderer: FaultSeverityNameColumn,
  },
  {
    field: 'isFixedText',
    hide: true,
    filter: true,
  },
  {
    field: 'fixed.fixedAt',
    headerName: 'Dátum riešenia',
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: FaultFixedSelectFilter,
    minWidth: 200,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: FixedAtColumn,
  },
  {
    field: 'fixed.fixedNote',
    headerName: 'Popis riešenia',
    minWidth: 200,
    resizable: true,
    wrapText: true,
    cellRenderer: FixedNoteColumn,
  },
  {
    field: 'fixed.fixedBy.userId',
    headerName: 'ID riešiteľa závady',
    hide: true,
    filter: true,
  },
  {
    field: 'fixed.fixedBy.fullName',
    headerName: 'Vyriešené používateľom',
    minWidth: 200,
    resizable: true,
    wrapText: true,
    sortable: true,
    unSortIcon: true,
    filter: true,
    cellRenderer: FixedByNameColumn,
  },
  {
    field: 'organization.organizationId',
    headerName: 'ID organizácie',
    hide: true,
    filter: true,
  },
  {
    field: 'organization.organizationName',
    headerName: 'Organizácia',
    minWidth: 275,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: OrganizationSelectFilter.configure({ permissionSlug: 'browse-devices' }),
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: OrganizationNameColumn,
  },
  {
    field: 'department.departmentId',
    headerName: 'ID strediska',
    hide: true,
    filter: true,
  },
  {
    field: 'department.departmentName',
    headerName: 'Stredisko',
    minWidth: 275,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DepartmentSelectFilter.configure({ permissionSlug: 'browse-devices' }),
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DepartmentNameColumn,
  },
  {
    field: 'deviceType.deviceTypeId',
    hide: true,
    filter: true,
  },
  {
    field: 'deviceType.deviceTypeName',
    headerName: 'Zariadenie',
    minWidth: 240,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DeviceTypeSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceTypeNameColumn,
  },
  {
    field: 'deviceSubtype.deviceTypeId',
    hide: true,
    filter: true,
  },
  {
    field: 'deviceSubtype.deviceTypeName',
    headerName: 'Typ zariadenia',
    minWidth: 250,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DeviceSubtypeEqualsSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceSubtypeNameColumn,
  },
  {
    field: '_actions',
    headerName: '',
    pinned: 'right',
    width: 194,
    minWidth: 194,
    maxWidth: 194,
    cellRenderer: ActionsColumn,
  },
];

/**
 * Table which displays device faults.
 */
export default function FaultTable() {
  const { getAction } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialFilters] = useState(() => {
    const filters: Record<string, any> = {};

    if (searchParams.has('isFixedText')) {
      filters.isFixedText = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('isFixedText'),
      };
    }

    if (searchParams.has('organizationId')) {
      filters['organization.organizationId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('organizationId'),
      };
    }

    if (searchParams.has('departmentId')) {
      filters['department.departmentId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('departmentId'),
      };
    }

    return filters;
  });

  const action = useCallback(async () => {
    const action = getAction('DeviceListAllFaults');

    const faults = await action();

    return faults.map((fault) => ({
      ...fault,
      isFixedText: fault.fixed && fault.fixed.fixedAt !== undefined ? 'fixed' : 'not-fixed',
      isFixed: fault.fixed && fault.fixed.fixedAt !== undefined,
      department: fault.device!.department,
      organization: fault.device!.organization,
      deviceType: fault.device!.deviceType,
      deviceSubtype: fault.device!.deviceSubtype,
      deviceId: fault.device!.deviceId,
      deviceName: fault.device!.deviceName,
      fixed: {
        fixedAt: fault.fixed?.fixedAt,
        fixedNote: fault.fixed?.fixedNote,
        fixedBy: {
          userId: fault.fixed?.fixedBy.userId,
          firstName: fault.fixed?.fixedBy.firstName,
          lastName: fault.fixed?.fixedBy.lastName,
          fullName:
            fault.fixed && fault.fixed.fixedAt !== undefined
              ? `${fault.fixed?.fixedBy.firstName} ${fault.fixed?.fixedBy.lastName}`
              : undefined,
        },
      },
    }));
  }, [getAction]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete('isFixedText');
        params.delete('organizationId');
        params.delete('departmentId');
        return params;
      },
      { replace: true }
    );
  });

  return (
    <DataTable
      tableId="faults"
      title="Závady zariadení"
      hideToggleDiscarded
      columns={columns}
      action={action}
      initialFilters={initialFilters}
      dataExport={{
        modalTitle: 'Exportovať závady zariadení',
        fileName: 'zavady-zariadeni.xlsx',
        columnKeys: [
          'faultId',
          'faultName',
          'description',
          'moreInfo',
          'deviceId',
          'deviceName',
          'faultSeverity.faultSeverityName',
          'fixed.fixedAt',
          'fixed.fixedNote',
          'fixed.fixedBy.fullName',
          'organization.organizationId',
          'organization.organizationName',
          'department.departmentId',
          'department.departmentName',
          'deviceType.deviceTypeName',
          'deviceSubtype.deviceTypeName',
        ],
      }}
    />
  );
}
