import DeviceSubtypeSelectFilter, {
  DeviceSubtypeSelectFilterBaseProps,
} from 'components/tables/filters/device-subtype/DeviceSubtypeSelectFilter';

/**
 * Select filter for the device subtype. The filter is of type 'contains'.
 */
export default function DeviceSubtypeContainsSelectFilter(props: DeviceSubtypeSelectFilterBaseProps) {
  return <DeviceSubtypeSelectFilter {...props} filterKey="deviceSubtypes.deviceTypeId" type="contains" />;
}
