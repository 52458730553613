import { noop } from 'lodash';
import { RevisionActionProps } from 'components/tables/data/revision/types';
import { useApi } from 'api/api-context';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import panic from 'errors/panic';

/**
 * Implements the delete action for revision.
 */
export default function useDeleteAction() {
  const { getAction } = useApi();

  /**
   * Makes a call to the API to delete the revision.
   */
  async function deleteRevision({
    revision: { revisionId, revisionName },
    onStatusChange = noop,
  }: RevisionActionProps) {
    const revisionDeleteAction = getAction('RevisionDelete');

    try {
      const { status } = await revisionDeleteAction({ parameters: { revisionId: String(revisionId) } });

      onStatusChange({ revisionId, status });

      showNotification({
        title: 'Revízna správa bola úspešne vymazaná',
        message: `Revízna správa ${revisionName} bola úspešne vymazaná.`,
        color: SUCCESS_NOTIFICATION_COLOR,
      });
    } catch (error: any) {
      panic(error);
    }
  }

  return { deleteRevision };
}
