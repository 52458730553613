import { Button, Group, Select, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { uniq } from 'lodash';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { useCallback, useEffect, useMemo } from 'react';
import PlaceholderSelect from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/PlaceholderSelect';

interface FormValues {
  field: string;
  operator: string;
  value: string;
}

/**
 * The if-block configurator form.
 */
export default function IfBlockConfigurator({ onClose }: { onClose: () => void }) {
  const { allInputs: inputs } = useFormInputs();
  const { editor } = useRichTextEditorContext();

  const initialValues = useMemo(() => {
    const attrs = editor.getAttributes('ifBlock');

    return {
      field: String(attrs?.['data-field'] ?? ''),
      operator: String(attrs?.['data-operator'] ?? 'not-empty'),
      value: String(attrs?.['data-value'] ?? ''),
    };
  }, []);

  const form = useForm<FormValues>({ initialValues });

  const selectedField = useMemo(
    () => inputs.find(({ spec }) => spec.name === form.values.field),
    [inputs, form.values.field]
  );

  const withValue = useMemo(
    () => form.values.operator === 'equals' || form.values.operator === 'not-equals',
    [form.values.operator]
  );

  const valueOptions = useMemo(
    () => (selectedField?.spec.type === 'select' ? uniq(selectedField.spec.options) : []),
    [selectedField]
  );

  const operators = useMemo(
    () => [
      { value: 'not-empty', label: 'Nie je prázdne' },
      { value: 'empty', label: 'Je prázdne' },
      { value: 'equals', label: 'Rovná sa' },
      { value: 'not-equals', label: 'Nerovná sa' },
    ],
    []
  );

  const submit = useCallback(
    ({ field, operator, value }: FormValues) =>
      editor
        .chain()
        .focus()
        .setIfConditionField(field)
        .setIfConditionOperator(operator)
        .setIfConditionValue(value)
        .run(),
    [editor]
  );

  const submitAndClose = useCallback(
    (values: FormValues) => {
      submit(values);
      onClose();
    },
    [submit, onClose]
  );

  useEffect(() => {
    if (!withValue) {
      form.setFieldValue('value', '');
    }
  }, [withValue]);

  return (
    <form onSubmit={form.onSubmit(submitAndClose)}>
      <Stack spacing={16} pt={8}>
        <Group spacing={8} noWrap className="grid grid-cols-[300px_125px_200px] gap-2">
          <PlaceholderSelect
            required
            size="sm"
            label="Pole"
            value={form.values.field}
            onChange={(field) => {
              form.setFieldValue('field', field || '');
              form.setFieldValue('value', '');
            }}
            excludeSpecialDocumentProperties
            excludeSpecialTechnicianProperties
          />
          <Select required size="sm" label="Operátor" data={operators} {...form.getInputProps('operator')} />
          {selectedField?.spec.type === 'select' ? (
            <Select
              size="sm"
              label="Hodnota"
              data={valueOptions}
              required={withValue}
              disabled={!withValue}
              placeholder={withValue ? 'Vyberte možnosť' : ''}
              {...form.getInputProps('value')}
            />
          ) : (
            <TextInput
              size="sm"
              label="Hodnota"
              required={withValue}
              disabled={!withValue}
              placeholder={withValue ? 'Zadajte hodnotu' : ''}
              {...form.getInputProps('value')}
            />
          )}
        </Group>
        <Group pt={8} spacing={16} position="right">
          <Button type="button" onClick={onClose} variant="subtle-gray">
            Zrušiť
          </Button>
          <Button type="submit" variant="primary">
            Nastaviť
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
