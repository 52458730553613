import { Checkbox } from '@mantine/core';
import { IFormInputSpecCheckbox } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Preview for a checkbox input.
 */
export default function CheckboxInputPreview({ spec }: { spec: IFormInputSpecCheckbox }) {
  return (
    <Checkbox
      required={spec.required}
      readOnly={spec.readOnly}
      disabled={spec.readOnly}
      defaultChecked={spec.defaultChecked}
      size="sm"
    />
  );
}
