import { noop } from 'lodash';
import { RevisionSingleActionProps } from 'components/tables/data/revision/types';
import { Button, Menu } from '@mantine/core';
import { IconRubberStamp } from '@tabler/icons-react';
import useConfirmAction from 'components/tables/data/revision/actions/hooks/use-confirm-action';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Action performed by the user who has the permission to confirm the revision
 */
export default function ConfirmAction({ revision, onStatusChange = noop, kind }: RevisionSingleActionProps) {
  const { confirm } = useConfirm();
  const { confirmRevision } = useConfirmAction();

  /**
   * Confirms the confirmation of the revision.
   */
  const confirmConfirmRevision = () =>
    confirm({
      title: 'Prebratie revíznej správy',
      content: 'Naozaj si prajete prebrať túto revíznu správu?',
      onConfirm: () => confirmRevision({ revision, onStatusChange }),
    });

  if (kind === 'button') {
    return (
      <Button
        size="md"
        onClick={confirmConfirmRevision}
        leftIcon={<IconRubberStamp stroke={1.5} size={24} />}
        variant="primary"
        w={180}
      >
        Prebrať
      </Button>
    );
  }

  return (
    <Menu.Item color="gray.8" onClick={confirmConfirmRevision} icon={<IconRubberStamp stroke={1.5} size={24} />}>
      Prebrať
    </Menu.Item>
  );
}
