import { Flex } from '@mantine/core';
import { RevisionRow } from 'components/tables/data/revision/types';
import RevisionStatusIcon from 'components/RevisionStatusIcon';

/**
 * Column for the revision status.
 */
export default function RevisionStatusColumn({ data: { revisionStatus } }: { data: RevisionRow }) {
  return (
    <Flex gap={8}>
      <RevisionStatusIcon statusId={revisionStatus.revisionStatusId} />
    </Flex>
  );
}
