import { Group } from '@mantine/core';
import { DeviceRow } from 'components/tables/data/device/types';
import UserContact from 'components/UserContact';

/**
 * Column for device name.
 */
export default function ResponsiblePersonColumn({
  data: { responsiblePerson, responsiblePersonNote },
}: {
  data: DeviceRow;
}) {
  return responsiblePerson ? (
    <Group spacing={8}>
      <UserContact
        firstName={responsiblePerson.firstName}
        lastName={responsiblePerson.lastName}
        email={responsiblePerson.email ?? ''}
        note={responsiblePersonNote}
      />
    </Group>
  ) : (
    '-'
  );
}
