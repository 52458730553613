import { useCallback, useState } from 'react';
import { ActionIcon, Grid, Stack, Group, LoadingOverlay } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { getCalendarDates } from 'components/tables/data/revision-plan/RevisionPlanCalendarUtils';
import { useMantineTheme } from '@mantine/core';
import { capitalize } from 'lodash';
import H2Medium from 'components/typography/H2Medium';
import P2Medium from 'components/typography/P2Medium';
import useImmediateAction from 'api/use-immediate-action';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { useSearchParams } from 'react-router-dom';
import RevisionPlanCalendarCell from 'components/tables/data/revision-plan/RevisionPlanCalendarCell';

/**
 * The component for displaying the revision plan in a form of a calendar.
 */
export default function RevisionPlanCalendar() {
  const [searchParams] = useSearchParams();
  const year = searchParams.get('year') ?? '';
  const month = searchParams.get('month') ?? '';

  const theme = useMantineTheme();
  const { getAction } = useApi();

  const currentDate = new Date();

  // Initialize state for the current year and month
  const [currentYear, setCurrentYear] = useState(year === '' ? currentDate.getFullYear() : Number(year));
  const [currentMonth, setCurrentMonth] = useState(month === '' ? currentDate.getMonth() + 1 : Number(month));

  // Get the calendar dates based on the current year and month
  const calendarDates = getCalendarDates(currentYear, currentMonth);

  const { data, loading, error } = useImmediateAction(() => {
    const action = getAction('DeviceListRevisionPlan');
    return action({ query: { 'month.eq': currentMonth, 'year.eq': currentYear } });
  });

  /**
   * Retrieves revision plans for a given date
   */
  const getPlanForDate = useCallback(
    (date: Date) => {
      if (!data) {
        return undefined;
      }

      return data.filter((plan) => {
        if (plan.nextRevision) {
          const nextRevision = new Date(plan.nextRevision);

          return (
            nextRevision.getDate() === date.getDate() &&
            nextRevision.getMonth() === date.getMonth() &&
            nextRevision.getFullYear() === date.getFullYear()
          );
        }

        return false;
      });
    },
    [data]
  );

  if (error) {
    panic(error);
    return <></>;
  }

  /**
   * Function to navigate to the previous month
   */
  const goToPreviousMonth = () => {
    if (currentMonth === 1) {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(12);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  /**
   * Function to navigate to the next month
   */
  const goToNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  return (
    <Stack bg={theme.white} style={{ borderRadius: '4px' }} spacing={12}>
      <LoadingOverlay visible={loading} />
      <Stack pt={24} pl={24} pr={24} spacing={12}>
        <Group spacing={16}>
          <Group spacing={4}>
            <ActionIcon variant="subtle-gray" size="md" onClick={goToPreviousMonth}>
              <IconChevronLeft stroke="1.5" size={24} />
            </ActionIcon>
            <ActionIcon variant="subtle-gray" size="md" onClick={goToNextMonth}>
              <IconChevronRight stroke="1.5" size={24} />
            </ActionIcon>
          </Group>
          <H2Medium>
            {capitalize(
              new Date(currentYear, currentMonth - 1).toLocaleDateString('sk-EU', {
                year: 'numeric',
                month: 'long',
              })
            )}
          </H2Medium>
        </Group>
      </Stack>
      <Stack>
        <Grid columns={7} style={{ textAlign: 'center' }} m={0} mr={24} ml={24}>
          <Grid.Col span={1} m={0} p={0}>
            <P2Medium>Pondelok</P2Medium>
          </Grid.Col>
          <Grid.Col span={1} m={0} p={0}>
            <P2Medium>Utorok</P2Medium>
          </Grid.Col>
          <Grid.Col span={1} m={0} p={0}>
            <P2Medium>Streda</P2Medium>
          </Grid.Col>
          <Grid.Col span={1} m={0} p={0}>
            <P2Medium>Štvrtok</P2Medium>
          </Grid.Col>
          <Grid.Col span={1} m={0} p={0}>
            <P2Medium>Piatok</P2Medium>
          </Grid.Col>
          <Grid.Col span={1} m={0} p={0}>
            <P2Medium>Sobota</P2Medium>
          </Grid.Col>
          <Grid.Col span={1} m={0} p={0}>
            <P2Medium>Nedeľa</P2Medium>
          </Grid.Col>
        </Grid>
        <Grid columns={7} style={{ border: '1px solid #DCDCE5' }} mr={24} ml={24} mb={24}>
          {!loading &&
            calendarDates.map((dateItem) => (
              <RevisionPlanCalendarCell
                key={dateItem.date.toString()}
                dateItem={dateItem}
                cellHeight={calendarDates.length > 35 ? '11.8vh' : '13.6vh'}
                getPlanForDate={getPlanForDate}
              />
            ))}
        </Grid>
      </Stack>
    </Stack>
  );
}
