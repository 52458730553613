import { RevisionSingleActionProps } from 'components/tables/data/revision/types';
import { noop } from 'lodash';
import { useConfirmWithMessage } from 'components/modals/confirm-with-message/ConfirmWithMessageProvider';
import { Menu } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import useRejectAction from 'components/tables/data/revision/actions/hooks/use-reject-action';

/**
 * Action performed by the user who has the permission to reject the revision.
 */
export default function RejectAction({ revision, onStatusChange = noop }: RevisionSingleActionProps) {
  const { rejectRevision } = useRejectAction();
  const { confirmWithMessage } = useConfirmWithMessage();

  /**
   * Confirms the rejection of the revision.
   */
  const confirmRejectRevision = () =>
    confirmWithMessage({
      title: 'Zamietnutie revíznej správy',
      message: 'Naozaj si prajete zamietnuť túto revíznu správu?',
      onConfirmWithMessage: (reason?: string) => rejectRevision({ revision, reason, onStatusChange }),
    });

  return (
    <Menu.Item color="gray.8" onClick={confirmRejectRevision} icon={<IconX stroke={1.5} size={24} />}>
      Zamietnuť
    </Menu.Item>
  );
}
