import { RevisionSingleActionProps } from 'components/tables/data/revision/types';
import { noop } from 'lodash';
import { Menu } from '@mantine/core';
import { useConfirmWithMessage } from 'components/modals/confirm-with-message/ConfirmWithMessageProvider';
import { IconRotateRectangle } from '@tabler/icons-react';
import useReassignAction from 'components/tables/data/revision/actions/hooks/use-reassign-revision';

/**
 * Action performed by the user who has the permission to reassign the revision.
 */
export default function ReassignAction({ revision, onStatusChange = noop }: RevisionSingleActionProps) {
  const { reassignRevision } = useReassignAction();
  const { confirmWithMessage } = useConfirmWithMessage();

  /**
   * Confirms the reassignment of the revision.
   */
  const confirmReassignRevision = () =>
    confirmWithMessage({
      title: 'Opätovne zadať revíznu správu',
      message: 'Naozaj si prajete opätovne zadať túto revíznu správu?',
      onConfirmWithMessage: (reason?: string) => reassignRevision({ revision, reason, onStatusChange }),
    });

  return (
    <Menu.Item color="gray.8" onClick={confirmReassignRevision} icon={<IconRotateRectangle stroke={1.5} size={24} />}>
      Zadať znova
    </Menu.Item>
  );
}
