import { rem } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { UserSelect } from 'components/selects/UserSelect';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'technician.userId' as const;

/**
 * Params passed to the TechnicianSelectFilter component
 */
export interface TechnicianSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the technician column.
 *
 * This filter assumes:
 *   - column field is `technician.userId`
 */
export default function TechnicianSelectFilter({ api, onChange }: TechnicianSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });

  // TODO list here only technicians
  return (
    <UserSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetci technici"
      value={String(value)}
      onChange={setValue}
      styles={{ dropdown: { minWidth: rem(220) } }}
    />
  );
}
