import { GridApi } from 'ag-grid-community';
import { QualificationsSelect } from 'components/selects/QualificationsSelect';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'certificateSlugs' as const;

/**
 * Parameters of the CertificatesSelectFilter component.
 */
export interface CertificatesSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the certificateSlugs column.
 *
 * This filter assumes:
 *   - column field is `certificateSlugs`
 */
export default function CertificatesSelectFilter({ api, onChange }: CertificatesSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange, type: 'contains' });

  return (
    <QualificationsSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky typy osvedčení"
      value={String(value)}
      onChange={setValue}
    />
  );
}
