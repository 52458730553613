import { useForm, UseFormReturnType } from '@mantine/form';
import { RevisionGetResponse as Revision } from 'api/actions/revision-get/revision-get-response';
import {
  IForm,
  IFormInput,
  IFormSection,
  IFormTabSlug,
} from 'pages/revisions-module/template-editor/editors/form/types';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { RevisionChange, RevisionChangeGroup, RevisionFormData } from 'components/forms/revision/fill-out/types';
import { fromPairs, has, times, values, zip } from 'lodash';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import { useFileManager } from 'api/file-manager/file-manager-context';
import toBase64 from 'utils/to-base64';
import panic from 'errors/panic';
import useTotalFaultsIntervalCheck from 'components/forms/revision/fill-out/data/hooks/use-total-faults-interval-check';
import { FEATURE_TOGGLE_REVISION_CHANGES_MODAL } from 'env';

export interface IExtendedFormInput extends IFormInput {
  tabTitle: string;
}

type ISectionsByTab = Record<IFormTabSlug, IFormSection[]>;
type IInputLookup = Map<string, IExtendedFormInput>;

interface IFillOutRevisionDataProviderContext {
  revision: Revision;
  sectionsByTab: ISectionsByTab;
  form: UseFormReturnType<RevisionFormData>;
  changes: RevisionChangeGroup[];
  findInputByName: (name: string) => IExtendedFormInput | undefined;
  tabHasVisibleInputs: (slug: IFormTabSlug) => boolean;
}

export const TAB_TITLE_MAP: Record<IFormTabSlug, string> = {
  info: 'Základné informácie',
  description: 'Popis',
  specification: 'Špecifikácia',
  conclusion: 'Záver',
  device: 'Zariadenia',
  measurements: 'Merania',
};

const FillOutRevisionDataProviderContext = createContext<IFillOutRevisionDataProviderContext>(undefined!);

/**
 * Provides the revision and form spec to the children.
 */
export function FillOutRevisionDataProvider({ revision, children }: { revision: Revision; children: ReactNode }) {
  const { readFile } = useFileManager();

  const formSpec = useMemo(() => JSON.parse(revision.revisionTemplate!.formScheme!) as IForm, [revision]);
  const allSections = useMemo(() => formSpec.sections, [formSpec]);
  const allRows = useMemo(() => allSections.flatMap(({ rows }) => rows), [allSections]);
  const allInputs = useMemo(() => allRows.flatMap(({ inputs }) => inputs), [allRows]);

  const sectionsByTab: ISectionsByTab = useMemo(
    () => ({
      info: allSections.filter(({ tab }) => tab === 'info'),
      description: allSections.filter(({ tab }) => tab === 'description'),
      specification: allSections.filter(({ tab }) => tab === 'specification'),
      conclusion: allSections.filter(({ tab }) => tab === 'conclusion'),
      device: allSections.filter(({ tab }) => tab === 'device'),
      measurements: allSections.filter(({ tab }) => tab === 'measurements'),
    }),
    [allSections]
  );

  const inputLookup = useMemo(() => {
    const lookup: IInputLookup = new Map();

    allSections.forEach(({ rows, tab }) => {
      rows.forEach(({ inputs }) => {
        inputs.forEach((input) => {
          lookup.set(input.spec.name, { ...input, tabTitle: TAB_TITLE_MAP[tab] });
        });
      });
    });

    return lookup;
  }, [allSections]);

  const validate = useMemo(() => {
    const requiredInputs = allInputs.filter(({ spec: { required } }) => required);

    const revisionFields = requiredInputs
      .filter(({ context }) => context === 'revision')
      .map(({ spec: { name } }) => `fields.${name}`);

    const deviceFields = requiredInputs
      .filter(({ context }) => context === 'device')
      .flatMap(({ spec: { name } }) => revision.devices.map((device) => `devices.${device.deviceId}.fields.${name}`));

    const fields = [...revisionFields, ...deviceFields];

    const validators = times(fields.length, () => createValidator([required]));

    return fromPairs(zip(fields, validators));
  }, [allInputs, revision]);

  const initialValues: RevisionFormData = useMemo(() => {
    let data: RevisionFormData = JSON.parse(revision.revisionData ?? 'null') || { fields: {}, devices: {} };

    allInputs
      .filter(({ context }) => context === 'revision')
      .filter(({ spec: { name } }) => !has(data.fields, name))
      .forEach(({ spec }) => {
        switch (spec.type) {
          case 'checkbox':
            data.fields[spec.name] = !!spec.defaultChecked;
            break;

          default:
            // No default value.
            break;
        }
      });

    return data;
  }, [revision]);

  const changes: RevisionChangeGroup[] = useMemo(() => {
    if (!FEATURE_TOGGLE_REVISION_CHANGES_MODAL) {
      return [];
    }

    const changes: RevisionChangeGroup[] = [];

    if (initialValues.fields._revisionName !== revision.revisionName) {
      changes.push({
        title: 'Názov revízie',
        changes: [{ before: initialValues.fields._revisionName, after: revision.revisionName }],
      });
    }

    if (initialValues.fields._organization !== revision.organization.fullName) {
      changes.push({
        title: 'Organizácia',
        changes: [{ before: initialValues.fields._organization, after: revision.organization.fullName }],
      });
    }

    if (initialValues.fields._department !== revision.department.fullName) {
      changes.push({
        title: 'Oddelenie',
        changes: [{ before: initialValues.fields._department, after: revision.department.fullName }],
      });
    }

    const deviceMap = fromPairs(revision.devices.map((device) => [String(device.deviceId), device]));

    const addedDevices = revision.devices.filter((device) => !has(initialValues.devices, String(device.deviceId)));
    const removedDevices = values(initialValues.devices).filter((device) => !has(deviceMap, String(device.deviceId)));
    const keptDevices = values(initialValues.devices).filter((device) => has(deviceMap, String(device.deviceId)));

    if (addedDevices.length > 0 || removedDevices.length > 0) {
      changes.push({
        title: 'Zariadenia',
        changes: [
          ...addedDevices.map((device) => ({ before: '', after: device.deviceName })),
          ...removedDevices.map((device) => ({ before: device.fields._deviceName, after: '' })),
        ],
      });
    }

    keptDevices.forEach((device) => {
      const revisionDevice = deviceMap[String(device.deviceId)];

      const deviceChanges: RevisionChange[] = [];

      if (device.fields._deviceName !== revisionDevice.deviceName) {
        deviceChanges.push({ before: device.fields._deviceName, after: revisionDevice.deviceName });
      }

      if (device.fields._deviceLocation !== revisionDevice.location) {
        deviceChanges.push({
          subTitle: 'Umiestnenie',
          before: device.fields._deviceLocation,
          after: revisionDevice.location,
        });
      }

      if (device.fields._deviceManufacturer !== revisionDevice.manufacturer) {
        deviceChanges.push({
          subTitle: 'Výrobca',
          before: device.fields._deviceManufacturer,
          after: revisionDevice.manufacturer,
        });
      }

      if (deviceChanges.length > 0) {
        changes.push({
          title: `Zariadenie ${device.fields._deviceName} (ID: ${device.deviceId})`,
          changes: deviceChanges,
        });
      }
    });

    return changes;
  }, [initialValues, revision]);

  const form = useForm<RevisionFormData>({
    initialValues,
    validate,
  });

  const findInputByName = useCallback((name: string) => inputLookup.get(name), [inputLookup]);

  const tabHasVisibleInputs = useCallback(
    (tab: IFormTabSlug) =>
      sectionsByTab[tab].some(({ rows }) => rows.some(({ inputs }) => inputs.some(({ spec: { hidden } }) => !hidden))),
    [sectionsByTab]
  );

  // Fill in the form with the revision data.
  useEffect(() => {
    form.setFieldValue('fields._revisionName', revision.revisionName);
    form.setFieldValue('fields._organization', revision.organization.fullName);
    form.setFieldValue('fields._department', revision.department.fullName);
    form.setFieldValue('fields._technicianName', revision.technician.fullName);
    form.setFieldValue('fields._technicianOrganization', revision.technician.organization.fullName);
    form.setFieldValue('fields._technicianPhone', revision.technician.phone);
    form.setFieldValue('fields._technicianEmail', revision.technician.email);

    if (revision.technician.certificates.length > 0) {
      const [certificate] = revision.technician.certificates;
      form.setFieldValue('fields._technicianCertificateNumber', certificate.certificateNumber);
      form.setFieldValue('fields._technicianCertificateRenewalDate', certificate.validFrom);
      form.setFieldValue('fields._technicianCertificateStampFileId', certificate.stampFileId);
    }

    revision.devices.forEach((device) => {
      if (!form.values.devices[device.deviceId]) {
        form.setFieldValue(`devices.${device.deviceId}`, {
          deviceId: device.deviceId,
          fields: {},
          faults: [],
          measurements: {},
        });
      }

      const prefix = `devices.${device.deviceId}.fields`;

      form.setFieldValue(`${prefix}._deviceName`, device.deviceName);
      form.setFieldValue(`${prefix}._deviceLocation`, device.location);
      form.setFieldValue(`${prefix}._deviceManufacturer`, device.manufacturer);
      form.setFieldValue(`${prefix}._deviceManufactured`, device.manufactured);
      form.setFieldValue(`${prefix}._deviceSerialNumber`, device.serialNumber);
      form.setFieldValue(`${prefix}._deviceSubTypeName`, device.deviceSubtype?.deviceTypeName);
      form.setFieldValue(`${prefix}._deviceBuilding`, device.building);
      form.setFieldValue(`${prefix}._deviceFloor`, device.floor);
      form.setFieldValue(`${prefix}._deviceRoom`, device.room);
      form.setFieldValue(`${prefix}._deviceLatitude`, device.latitude);
      form.setFieldValue(`${prefix}._deviceLongitude`, device.longitude);
      form.setFieldValue(`${prefix}._deviceInternalPossessionsNumber`, device.internalPossessionsNumber);
      form.setFieldValue(`${prefix}._deviceInternalNote`, device.internalNote);
    });

    Object.keys(form.values.devices).forEach((deviceId) => {
      const revisionHasDevice = revision.devices.find((device) => device.deviceId === Number(deviceId));

      if (!revisionHasDevice) {
        form.setFieldValue(`devices.${deviceId}.removed`, true);
      }
    });

    allInputs.forEach(({ spec }) => {
      if (spec.type === 'deviceProperty') {
        revision.devices.forEach((device) => {
          const field = `devices.${device.deviceId}.fields.${spec.name}`;

          switch (spec.property) {
            case 'deviceSubTypeName':
              form.setFieldValue(field, device.deviceSubtype?.deviceTypeName);
              break;

            default:
              form.setFieldValue(`devices.${device.deviceId}.fields.${spec.name}`, device[spec.property]);
          }
        });
      }
    });
  }, [revision]);

  // Fetch the technician certificate stamp.
  useEffect(() => {
    const fileId = form.values.fields._technicianCertificateStampFileId;

    if (fileId) {
      readFile({ fileId })
        .then(toBase64)
        .then((parsed) => form.setFieldValue('fields._technicianCertificateStamp', parsed))
        .catch(panic);
    }
  }, [form.values.fields._technicianCertificateStampFileId]);

  // Interval checks.
  useTotalFaultsIntervalCheck(form);

  const value = useMemo(
    () => ({ revision, sectionsByTab, form, changes, findInputByName, tabHasVisibleInputs }),
    [revision, sectionsByTab, form, changes, findInputByName, tabHasVisibleInputs]
  );

  return (
    <FillOutRevisionDataProviderContext.Provider value={value}>{children}</FillOutRevisionDataProviderContext.Provider>
  );
}

/**
 * Hook to get the revision and form spec.
 */
export function useFillOutRevisionDataProvider() {
  return useContext(FillOutRevisionDataProviderContext);
}
