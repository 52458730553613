import { Button, Group, Modal } from '@mantine/core';
import { DepartmentListResponse } from 'api/actions/department-list/department-list-response';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { useEffect, useMemo, useState } from 'react';
import MultiCheckbox, { MultiCheckboxItem } from 'components/inputs/multi-checkbox/MultiCheckbox';
import { isEmpty, noop } from 'lodash';
import BadgeDeleted from 'components/Badge';

/**
 * A single department.
 */
export type PickDepartmentItem = DepartmentListResponse[number];

/**
 * Parameters of the PickDepartments component.
 */
export interface PickDepartmentsParams {
  opened?: boolean;
  title?: string;
  initialPicked?: number[];
  excludeIds?: number[];
  showDiscarded?: boolean;
  permissionSlug?: string;
  onClose?: () => void;
  onPick?: (departments: PickDepartmentItem[]) => void;
}

/**
 * Modal for selecting departments.
 */
export default function PickDepartments({
  opened = false,
  title = 'Pridať stredisko',
  initialPicked = [],
  excludeIds = [],
  showDiscarded = false,
  permissionSlug = undefined,
  onClose = noop,
  onPick = noop,
}: PickDepartmentsParams = {}) {
  const { getAction } = useApi();
  const [departments, setDepartments] = useState<PickDepartmentItem[]>([]);
  const [picked, setPicked] = useState<number[]>(initialPicked);
  const [loading, setLoading] = useState(true);

  const options: MultiCheckboxItem[] = useMemo(
    () =>
      departments.map(({ departmentId, departmentName, status, organization }) => ({
        value: departmentId,
        label: departmentName,
        iconLeft: status ? null : <BadgeDeleted size="sm" text="Vyradené" />,
        group: organization.organizationName,
      })),
    [departments]
  );

  // Fetch departments from the API.
  useEffect(() => {
    setLoading(true);

    const departmentGetListAction = getAction('DepartmentList');

    departmentGetListAction({
      query: { filters: { 'showDiscarded.eq': showDiscarded ? 1 : 0, 'permissionSlug.eq': permissionSlug } },
    })
      .then(setDepartments)
      .catch(panic)
      .finally(() => setLoading(false));
  }, []);

  /**
   * Is called when the user clicks on the "Pridať" button.
   */
  const onPickImpl = () => {
    onPick(departments.filter(({ departmentId }) => picked.includes(departmentId)));
    setPicked([]);
    onClose();
  };

  return (
    <Modal title={title} opened={opened} onClose={onClose}>
      <MultiCheckbox
        withSearch
        data={options}
        value={picked}
        loading={loading}
        onChange={setPicked}
        excludeIds={excludeIds}
        group={true}
        searchPlaceholder="Hľadať strediská"
        noResultPlaceholder="Neboli nájdené žiadne strediská."
      />
      <Group position="apart" pt={24}>
        <Button size="md" variant="subtle-gray" onClick={onClose}>
          Zrušiť
        </Button>
        <Button size="md" variant="primary" onClick={onPickImpl} disabled={isEmpty(picked)}>
          Pridať
        </Button>
      </Group>
    </Modal>
  );
}
