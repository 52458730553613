import { Select, SelectItem } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'restriction' as const;

export interface RestrictionSelectFilterBaseProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Parameters of the RestrictionSelectFilter component.
 */
interface RestrictionSelectFilterProps extends RestrictionSelectFilterBaseProps {
  data: SelectItem[];
}

/**
 * Filter components for the restriction column.
 *
 * The filter assumes:
 *  - column field is `restriction`
 */
export default function RestrictionSelectBaseFilter({ api, onChange, data }: RestrictionSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });

  return (
    <Select
      data={data}
      value={value}
      onChange={setValue}
      searchable
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky typy obmedzení"
    />
  );
}
