import Excerpt from 'components/Excerpt';
import { DeviceSubtypeRow } from 'components/tables/data/device-subtypes/types';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for the description.
 */
export default function DescriptionColumn({ data: { description } }: { data: DeviceSubtypeRow }) {
  return (
    <P2Regular color="gray.8">
      <Excerpt text={description ?? '-'} length={150} />
    </P2Regular>
  );
}
