import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import { useFileManager } from 'api/file-manager/file-manager-context';
import panic from 'errors/panic';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import toBase64 from 'utils/to-base64';
import { RevisionActionProps } from 'components/tables/data/revision/types';
import { isString, noop } from 'lodash';
import slugify from 'slugify';
import useDocumentGeneratorV2 from 'api/document-generator-v2/use-document-generator-v2';

/**
 * Implements the confirm action for revision.
 */
export default function useConfirmAction() {
  const { getAction } = useApi();
  const { uploadFile } = useFileManager();
  const { generatePdf } = useDocumentGeneratorV2();

  /**
   * Makes a call to the API to confirm the revision.
   */
  async function confirmRevision({ revision: { revisionId }, onStatusChange = noop }: RevisionActionProps) {
    try {
      const revisionGetAction = getAction('RevisionGet');
      const revisionConfirmAction = getAction('RevisionCustomerConfirm');

      const revision = await revisionGetAction({ parameters: { revisionId: String(revisionId) } });
      const { revisionData, revisionName, revisionTemplate, revisionVisual } = revision;

      if (!revisionData) {
        throw new Error('Revízna správa nemá dáta');
      }

      if (!revisionTemplate) {
        throw new Error('Revízna správa nemá šablónu');
      }

      const data = JSON.parse(revisionData);
      let fileName: string = `${Date.now()}-${slugify(revisionName)}.pdf`;

      if (isString(data.info?.endDate)) {
        fileName = `${data.info.endDate}-${slugify(revisionName)}.pdf`;
      }

      // generate the PDF
      let contents: Blob;

      if (revisionTemplate.templateScheme && revisionTemplate.visualData) {
        contents = await generatePdf(revision);
      } else {
        const documentsRenderResponse = await fetch('/api/documents/render/', {
          method: 'POST',
          body: JSON.stringify({
            data,
            template: revisionTemplate.slug,
            theme: revisionVisual?.slug ?? 'default',
            title: revisionName,
          }),
          headers: { 'Content-Type': 'application/json' },
        });

        const { pdfUrl } = await documentsRenderResponse.json();

        // retrieve the contents of the PDF
        const response = await fetch(`/api/documents${pdfUrl}`, {
          method: 'GET',
        });

        contents = await response.blob();
      }

      // upload the file to File Manager
      const base64Contents = await toBase64(contents);
      const remoteId = await uploadFile({ fileName, contents: base64Contents });

      // save it to DB
      const confirmActionResponse = await revisionConfirmAction({
        parameters: { revisionId: String(revisionId) },
        payload: { fileId: remoteId, fileName },
      });

      onStatusChange({ revisionId, status: confirmActionResponse.status, fileId: remoteId });

      showNotification({
        title: 'Revízna správa bola prebratá',
        message: `Revíznu správu ${revisionName} ste úspešne prebrali.`,
        color: SUCCESS_NOTIFICATION_COLOR,
      });
    } catch (e: any) {
      panic(e);
    }
  }

  return {
    confirmRevision,
  };
}
