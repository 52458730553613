import { Text } from '@mantine/core';
import { Portal } from '@mantine/core';
import { ActionIcon, Button, Group, Menu } from '@mantine/core';
import { IconBackspace, IconCopy, IconDotsVertical, IconPencil, IconSettings, IconTrashX } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import {
  COPY_REVISION_TEMPLATE_PAGE_PATH,
  EDIT_REVISION_TEMPLATE_PAGE_PATH,
  TEMPLATE_EDITOR_PAGE_PATH,
} from 'routes/paths';
import { RevisionTemplateRow } from 'components/tables/data/revision-template/types';
import { useCallback, useMemo } from 'react';
import { useApi } from 'api/api-context';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useConfirm } from 'components/modals/message/MessageProvider';
import { noop } from 'lodash';
import { CANNOT_SET_ENTITY_STATUS, ERROR_NOTIFICATION_COLOR } from 'utils/constants';
import { notifications } from '@mantine/notifications';
import panic from 'errors/panic';
import { FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR } from 'env';

/**
 * Parameters for the RevisionTemplateActions component.
 */
type RevisionTemplateActionsProps = RevisionTemplateRow & { onStatusChange: (status: boolean) => void };

/**
 * The actions which can be performed on a single row.
 */
export default function RevisionTemplateActions({
  revisionTemplateId,
  name,
  status,
  onStatusChange = noop,
}: RevisionTemplateActionsProps) {
  const { getAction, roleId } = useApi();
  const { confirm } = useConfirm();

  const editPath = useMemo(() => EDIT_REVISION_TEMPLATE_PAGE_PATH.insert({ revisionTemplateId }), [revisionTemplateId]);
  const copyPath = useMemo(() => COPY_REVISION_TEMPLATE_PAGE_PATH.insert({ revisionTemplateId }), [revisionTemplateId]);

  /**
   * Makes a call to the API to set the status of the revision template.
   */
  const setStatusRemote = useCallback(
    (status: boolean) => {
      const revisionTemplateSetStatus = getAction('RevisionRevisionTemplateSetStatus');

      revisionTemplateSetStatus({ parameters: { revisionTemplateId: String(revisionTemplateId) }, payload: { status } })
        .then(() => onStatusChange(status))
        .catch((error) => {
          if (error.response.data.error.code === CANNOT_SET_ENTITY_STATUS) {
            notifications.show({
              title: 'Chyba!',
              message: 'Túto šablónu nemôžete deaktivovať.',
              color: ERROR_NOTIFICATION_COLOR,
            });
          } else {
            panic(error);
          }
        });
    },
    [getAction, revisionTemplateId]
  );

  /**
   * Confirms the status change.
   */
  const confirmSetStatus = useCallback(
    (status: boolean) => {
      const title = status ? 'Zaradiť šablónu' : 'Vyradiť šablónu';
      const message = status
        ? 'Naozaj chcete zaradiť šablónu?'
        : 'Naozaj chcete vyradiť šablónu? Vyradenie šablóny neovplyvní existujúce revízne správy, ktoré majú priradenú túto šablónu.';

      confirm({
        title,
        content: message,
        onConfirm: () => setStatusRemote(status),
      });
    },
    [confirm, setStatusRemote]
  );

  return (
    <Group spacing={12} noWrap>
      <Button
        size="md"
        variant="secondary"
        component={Link}
        to={TEMPLATE_EDITOR_PAGE_PATH.insert({ revisionTemplateId })}
        leftIcon={<IconSettings />}
        disabled={!status}
      >
        Editor šablóny
      </Button>
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Portal>
          <Menu.Dropdown>
            <Menu.Label>
              <Text maw={160} truncate>
                {name}
              </Text>
            </Menu.Label>
            {status && (
              <Menu.Item component={Link} to={editPath} icon={<IconPencil stroke={1.5} size={20} />}>
                Upraviť parametre
              </Menu.Item>
            )}
            {FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR && (
              <Menu.Item component={Link} to={copyPath} icon={<IconCopy stroke={1.5} size={20} />}>
                Kopírovať
              </Menu.Item>
            )}
            {roleId === ROLE_ADMIN_ID && (
              <Menu.Item
                color={status ? 'red.8' : 'blue.8'}
                onClick={() => confirmSetStatus(!status)}
                icon={status ? <IconTrashX stroke={1.5} size={20} /> : <IconBackspace stroke={1.5} size={20} />}
              >
                {status ? 'Vyradiť' : 'Zaradiť'}
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Group>
  );
}
