import panic from 'errors/panic';
import { noop } from 'lodash';
import { RevisionActionProps } from 'components/tables/data/revision/types';
import { useApi } from 'api/api-context';
import { useNavigate } from 'react-router-dom';
import { REVISION_PAGE_PATH } from 'routes/paths';
import { BRAIN_IT_WINDOW_OPEN_TARGET } from 'env';

type RevisionActionPropsExtended = RevisionActionProps & { deadline: string };

/**
 * Implements the sign action for revision.
 */
export default function useSignAction() {
  const { getAction } = useApi();
  const navigate = useNavigate();

  /**
   * Makes a call to the API to initiate the signing of the revision.
   */
  async function signRevision({
    revision: { revisionId },
    deadline,
    onStatusChange = noop,
  }: RevisionActionPropsExtended) {
    const revisionSignAction = getAction('RevisionSignDoc');

    try {
      const { redirectUrl, status } = await revisionSignAction({
        parameters: { revisionId: String(revisionId) },
        payload: { documentValidUntil: deadline },
      });

      onStatusChange({ status, revisionId });

      navigate(REVISION_PAGE_PATH.insert({ revisionId }));
      window.open(redirectUrl, BRAIN_IT_WINDOW_OPEN_TARGET);
    } catch (e: any) {
      panic(e);
    }
  }

  return {
    signRevision,
  };
}
