import { ADD_ORGANIZATION_PAGE_PATH } from 'routes/paths';
import { useApi } from 'api/api-context';
import DataTable from 'components/tables/DataTable';
import QualificationsSelectFilter from 'components/tables/filters/QualificationsSelectFilter';
import { useCallback, useMemo } from 'react';
import { getDeviceType } from 'model/DeviceType';
import SelfAdministrationSelectFilter from 'components/tables/filters/SelfAdministrationSelectFilter';
import { Organization, OrganizationRow } from 'components/tables/data/organization/types';
import OrganizationIdColumn from 'components/tables/data/organization/columns/OrganizationIdColumn';
import OrganizationNameColumn from 'components/tables/data/organization/columns/OrganizationNameColumn';
import AddressColumn from 'components/tables/data/organization/columns/AddressColumn';
import QualificationsColumn from 'components/tables/data/organization/columns/QualificationsColumn';
import DepartmentsColumn from 'components/tables/data/organization/columns/DepartmentsColumn';
import ActionsColumn from 'components/tables/data/organization/columns/ActionsColumn';
import { hiddenSearchableColumn as hiddenSearchable } from 'components/tables/utils/hidden-searchable-column';
import { ColDef } from 'ag-grid-community';

/**
 * Computes the cell style.
 */
const cellStyle = ({ data: { status } }: { data: OrganizationRow }) => ({ opacity: status ? 1 : 0.6 });

/**
 * The columns of the table.
 */
const columns: ColDef[] = [
  // ANCHOR Visible Columns
  {
    field: 'organizationId',
    headerName: 'ID',
    sortable: true,
    unSortIcon: true,
    width: 72,
    minWidth: 72,
    maxWidth: 72,
    cellStyle,
    cellRenderer: OrganizationIdColumn,
  },
  {
    field: 'organizationName',
    headerName: 'Organizácia',
    minWidth: 350,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: SelfAdministrationSelectFilter,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: OrganizationNameColumn,
  },
  {
    field: 'address',
    headerName: 'Adresa',
    minWidth: 300,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: AddressColumn,
  },
  {
    field: 'qualifications',
    headerName: 'Oprávnenia',
    minWidth: 250,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: QualificationsSelectFilter,
    resizable: true,
    cellStyle,
    cellRenderer: QualificationsColumn,
  },
  {
    field: 'departments',
    headerName: 'Strediská',
    minWidth: 400,
    resizable: true,
    wrapText: true,
    cellStyle,
    cellRenderer: DepartmentsColumn,
  },

  // ANCHOR Hidden Columns
  { field: 'streetAddress', headerName: 'Ulica', ...hiddenSearchable },
  { field: 'city', headerName: 'Mesto', ...hiddenSearchable },
  { field: 'organizationSelfAdministration', headerName: 'Organizácia sa spravuje sama', ...hiddenSearchable },
  { field: 'organizationIncludedInStatistics', headerName: 'Rátať do štatistík', ...hiddenSearchable },
  { field: 'organizationInKepHashArchive', headerName: 'KEP hash archív', ...hiddenSearchable },
  { field: 'qualificationNames', headerName: '', ...hiddenSearchable },
  { field: 'qualificationSlugs', headerName: '', ...hiddenSearchable },

  // ANCHOR Status
  {
    field: 'status',
    headerName: 'Aktívna',
    valueGetter: ({ data: { status } }: { data: OrganizationRow }) => Number(status),
    hide: true,
    filter: true,
  },

  // ANCHOR Actions
  {
    field: '_actions',
    headerName: '',
    pinned: 'right',
    width: 194,
    minWidth: 194,
    maxWidth: 194,
    lockPosition: 'right',
    cellRenderer: ActionsColumn,
  },
];

/**
 * Table which displays organizations.
 */
export default function OrganizationTable() {
  const { getAction } = useApi();

  const createRow = useCallback(
    (organization: Organization): OrganizationRow => ({
      ...organization,
      qualificationNames: organization.qualifications
        .map(({ deviceType, deviceTypeId }) => deviceType?.deviceTypeName || getDeviceType(deviceTypeId).name)
        .join(','),
      qualificationSlugs: organization.qualifications
        .map(({ deviceType, deviceTypeId }) => deviceType?.slug || getDeviceType(deviceTypeId).slug)
        .join(','),
      organizationSelfAdministration: organization.selfAdministration ? 'Áno' : 'Nie',
      organizationIncludedInStatistics: organization.includedInStatistics ? 'Áno' : 'Nie',
      organizationInKepHashArchive: organization.inKepHashArchive ? 'Áno' : 'Nie',
    }),
    []
  );

  const action = useCallback(async () => {
    const getOrganizations = getAction('OrganizationList');
    const organizations = await getOrganizations();
    return organizations.map(createRow);
  }, [getAction]);

  const dataExport = useMemo(
    () => ({
      modalTitle: 'Exportovať organizácie',
      fileName: 'organizacie.xlsx',
      columnKeys: [
        'organizationId',
        'organizationName',
        'streetAddress',
        'city',
        'organizationSelfAdministration',
        'organizationIncludedInStatistics',
        'organizationInKepHashArchive',
        'status',
      ],
    }),
    []
  );

  return (
    <DataTable
      tableId="organizations"
      title="Organizácie"
      addButtonText="Pridať novú"
      toggleDiscardedLabel="Zobraziť vyradené"
      columns={columns}
      action={action}
      addButtonTarget={ADD_ORGANIZATION_PAGE_PATH.original}
      dataExport={dataExport}
    />
  );
}
