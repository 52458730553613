import { MantineThemeOverride, rem } from '@mantine/core';
import { P1Regular, P2Regular, P3Regular } from 'theme/typography';

export type SelectTheme = NonNullable<MantineThemeOverride['components']>['Select'];

export const Select: SelectTheme = {
  defaultProps: (theme) => ({
    size: 'md',
    withinPortal: true,
    maxDropdownHeight: 400,
    searchable: true,
  }),
  styles: (theme) => ({
    input: {
      borderColor: theme.colors.gray[4],
      background: theme.white,
      boxShadow: 'none !important',
      '&:hover:not(:disabled):not([readonly])': {
        borderColor: theme.colors.blue[8],
      },
      '&:focus': {
        borderColor: theme.colors.blue[8],
        color: theme.colors.gray[8],
      },
      '&:disabled': {
        color: theme.colors.gray[4],
        backgroundColor: theme.colors.gray[1],
      },
      '&[readonly]': {
        color: theme.colors.gray[6],
        backgroundColor: theme.colors.gray[1],
        cursor: 'not-allowed',
      },
      '&:filled': {
        color: theme.colors.gray[8],
      },
    },
    dropdown: {
      '&:hover': {
        Color: theme.colors.blue[8],
      },
    },
    label: {
      color: theme.colors.gray[6],
      '&:disabled': {
        color: theme.colors.gray[2],
      },
    },
    item: {
      whiteSpace: 'pre-wrap',
    },
    rightSection: {
      '& button': {
        paddingLeft: '0',
        paddingRight: '0',
        opacity: 0.7,
        marginRight: rem(-12),
        transition: 'opacity 100ms ease',
        '&:hover': {
          opacity: 1,
        },
        '& svg': {
          width: `${rem(16)} !important`,
          height: `${rem(16)} !important`,
        },
      },
    },
  }),
  sizes: {
    lg: (theme) => ({
      input: {
        paddingLeft: rem(16),
        paddingRight: rem(16),
        paddingTop: rem(12),
        paddingBottom: rem(12),
        ...P1Regular,
      },
      dropdown: {
        ...P1Regular,
      },
      item: {
        ...P1Regular,
      },
      label: {
        ...P2Regular,
      },
    }),
    md: (theme) => ({
      input: {
        paddingLeft: rem(12),
        paddingRight: rem(12),
        paddingTop: rem(8),
        paddingBottom: rem(8),
        ...P1Regular,
      },
      dropdown: {
        ...P1Regular,
      },
      item: {
        ...P1Regular,
      },
      label: {
        ...P2Regular,
      },
    }),
    sm: (theme) => ({
      input: {
        paddingLeft: rem(8),
        paddingRight: rem(8),
        paddingTop: rem(4),
        paddingBottom: rem(4),
        ...P2Regular,
      },
      dropdown: {
        ...P2Regular,
      },
      item: {
        ...P2Regular,
      },
      label: {
        ...P3Regular,
      },
    }),
  },
};
