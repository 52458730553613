import DateDisplay from 'components/DateDisplay';
import { RevisionRow } from 'components/tables/data/revision/types';

/**
 * Column for the deadline.
 */
export default function DeadlineColumn({ data: { deadline, revisionStatus } }: { data: RevisionRow }) {
  return (
    <DateDisplay
      date={new Date(deadline)}
      withRelative={
        revisionStatus.slug !== 'confirmed' &&
        revisionStatus.slug !== 'deleted' &&
        revisionStatus.slug !== 'being-signed' &&
        revisionStatus.slug !== 'signed-with-kep'
      }
    />
  );
}
