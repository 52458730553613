import { Button, Menu } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { RevisionSingleActionProps } from 'components/tables/data/revision/types';
import { Link } from 'react-router-dom';
import { EDIT_REVISION_PAGE_PATH } from 'routes/paths';

/**
 * Action performed by the users who have the permission to edit the revision.
 */
export default function EditAction({ revision, kind }: RevisionSingleActionProps) {
  if (kind === 'button') {
    return (
      <Button
        size="md"
        component={Link}
        to={EDIT_REVISION_PAGE_PATH.insert({ revisionId: revision.revisionId })}
        leftIcon={<IconPencil />}
        variant="secondary"
        w={180}
      >
        Editovať
      </Button>
    );
  }

  return (
    <Menu.Item
      component={Link}
      to={EDIT_REVISION_PAGE_PATH.insert({ revisionId: revision.revisionId })}
      icon={<IconPencil stroke="1.5" size={24} />}
      color="gray.8"
    >
      Editovať
    </Menu.Item>
  );
}
