import { GridApi, IFloatingFilterParams } from 'ag-grid-community';
import RevisionStatusMultiSelect from 'components/inputs/revision-status-multi-select/RevisionStatusMultiSelect';
import { noop } from 'lodash';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

interface IRevisionStatusFloatingFilterProps extends IFloatingFilterParams {
  onChange?: FilterChangeCallback;
}

export const FILTER_KEY = 'revisionStatus.revisionStatusId' as const;

export default forwardRef(({ parentFilterInstance = noop, onChange, api }: IRevisionStatusFloatingFilterProps, ref) => {
  const [value, setValue] = useState<number[]>(() => api?.getFilterModel()?.[FILTER_KEY]?.filter ?? []);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => ({ onParentModelChanged: noop }));

  const onChangeImpl = useCallback(
    (value: number[]) => {
      setValue(value);

      if (onChange) {
        onChange({ [FILTER_KEY]: { filter: value } });
      } else {
        parentFilterInstance((instance: any) => {
          instance.onFloatingFilterChanged(value);
        });
      }
    },
    [onChange, parentFilterInstance]
  );

  // React to model changes.
  useEffect(() => {
    /** Handles the filter changed event. */
    const handleFilterChanged = ({ api }: { api: GridApi }) => setValue(api.getFilterModel()[FILTER_KEY]?.filter ?? []);

    api.addEventListener('modelUpdated', handleFilterChanged);
    return () => api.removeEventListener('modelUpdated', handleFilterChanged);
  }, []);

  return <RevisionStatusMultiSelect value={value} onChange={onChangeImpl} />;
});
