import { DeviceSubtypeRow } from 'components/tables/data/device-subtypes/types';
import P2Regular from 'components/typography/P2Regular';
import ProvenanceLabel from 'components/ProvenanceLabel';
import { Box, Group, Stack, Tooltip } from '@mantine/core';
import { Fragment, useMemo } from 'react';
import P2Medium from 'components/typography/P2Medium';
import OrganizationList from 'components/OrganizationList';

/**
 * List of departments.
 */
function DepartmentList({
  departments,
  limit = 3,
}: {
  departments: { departmentId: number; departmentName: string }[];
  limit?: number;
}) {
  const some = useMemo(() => departments.slice(0, limit), [departments, limit]);
  const more = useMemo(() => departments.slice(limit), [departments, limit]);

  const hasMore = more.length > 0;

  if (departments.length === 0) {
    return <P2Regular color="gray.6">-</P2Regular>;
  }

  return (
    <Group spacing={12}>
      {some.map(({ departmentId, departmentName }) => (
        <Fragment key={departmentId}>
          <P2Regular color="gray.8">{departmentName}</P2Regular>|
        </Fragment>
      ))}
      {hasMore && (
        <Tooltip
          bg="gray.2"
          withArrow
          label={
            <Stack spacing={4}>
              {more.map(({ departmentId, departmentName }) => (
                <P2Regular key={departmentId} color="gray.8">
                  {departmentName}
                </P2Regular>
              ))}
            </Stack>
          }
        >
          <Box>
            <P2Medium color="gray.6">ďalších (+{more.length})</P2Medium>
          </Box>
        </Tooltip>
      )}
    </Group>
  );
}

/**
 * Column for the restrictions.
 */
export default function RestrictionsColumn({
  data: { isDepartment, isGlobal, isOrganization, departments, organizations },
}: {
  data: DeviceSubtypeRow;
}) {
  return (
    <P2Regular color="gray.8">
      {isGlobal && <ProvenanceLabel provenance="global" />}
      {isOrganization && (
        <Stack spacing={8}>
          <ProvenanceLabel provenance="organization" />
          <OrganizationList organizations={organizations!} />
        </Stack>
      )}
      {isDepartment && (
        <Stack spacing={8}>
          <ProvenanceLabel provenance="department" />
          <DepartmentList departments={departments!} />
        </Stack>
      )}
    </P2Regular>
  );
}
