import { Stack } from '@mantine/core';
import OrganizationList from 'components/OrganizationList';
import ProvenanceLabel from 'components/ProvenanceLabel';
import { RevisionTemplateRow } from 'components/tables/data/revision-template/types';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for the restrictions.
 */
export default function RestrictionsColumn({
  data: { isGlobal, isOrganization, organizations },
}: {
  data: RevisionTemplateRow;
}) {
  return (
    <P2Regular color="gray.8">
      {isGlobal && <ProvenanceLabel provenance="global" />}
      {isOrganization && (
        <Stack spacing={8}>
          <ProvenanceLabel provenance="organization" />
          <OrganizationList organizations={organizations!} />
        </Stack>
      )}
    </P2Regular>
  );
}
