import { Paper, Tooltip } from '@mantine/core';
import P2Regular from 'components/typography/P2Regular';
import { Link } from 'react-router-dom';
import { DEVICE_FAULTS_PAGE_PATH } from 'routes/paths';
import { DeviceRow } from 'components/tables/data/device/types';

/**
 * Column for faults.
 */
export default function FaultsColumn({ data: { hasUnfixedFaults, deviceId } }: { data: DeviceRow }) {
  return hasUnfixedFaults ? (
    <Tooltip label="Zobraziť závady zariadenia">
      <Paper radius={4} bg="red.1" w="fit-content" px={8}>
        <Link to={DEVICE_FAULTS_PAGE_PATH.insert({ deviceId })} style={{ textDecoration: 'none' }}>
          <P2Regular color="red.9">Závada</P2Regular>
        </Link>
      </Paper>
    </Tooltip>
  ) : (
    <Paper radius={4} bg="green.1" w="fit-content" px={8}>
      <P2Regular color="green.9">Bez závady</P2Regular>
    </Paper>
  );
}
