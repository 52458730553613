import { RevisionGetResponse as Revision } from 'api/actions/revision-get/revision-get-response';
import { RevisionFormData } from 'components/forms/revision/fill-out/types';
import { useCallback, useMemo } from 'react';

/**
 * Integrates the document generator API into the application.
 */
export default function useDocumentGeneratorV2() {
  const preProcessTemplate = useCallback((template: string) => {
    const eTemplate = document.createElement('div');
    eTemplate.innerHTML = template;

    const deviceLoops = Array.from(eTemplate.getElementsByClassName('device-loop'));
    deviceLoops.forEach((deviceLoop) => {
      const start = deviceLoop.children.item(0)!;
      const body = deviceLoop.children.item(1)!;
      const end = deviceLoop.children.item(2)!;

      deviceLoop.replaceWith(start, ...body.children, end);
    });

    return eTemplate.innerHTML;
  }, []);

  const preProcessData = useCallback(
    (data: RevisionFormData) => ({
      ...data,
      devices: Object.values(data.devices).filter(({ removed }) => !removed),
    }),
    []
  );

  const callApi = useCallback(
    async (endpoint: 'preview' | 'generate', revision: Revision, overrideData?: any) => {
      const { template } = JSON.parse(revision.revisionTemplate!.templateScheme!);
      const visual = JSON.parse(revision.revisionTemplate!.visualData!);
      const revisionData = overrideData ?? JSON.parse(revision.revisionData!);

      const response = await fetch(`/api/documents/v2/${endpoint}/`, {
        method: 'POST',
        body: JSON.stringify({
          data: preProcessData(revisionData),
          template: preProcessTemplate(template),
          visual,
        }),
        headers: { 'Content-Type': 'application/json' },
      });

      return response;
    },
    [preProcessData, preProcessTemplate]
  );

  const generatePreview = useCallback(
    async (revision: Revision, overrideData?: any) => {
      const response = await callApi('preview', revision, overrideData);
      return await response.text();
    },
    [callApi]
  );

  const generatePdf = useCallback(
    async (revision: Revision, overrideData?: any) => {
      const response = await callApi('generate', revision, overrideData);
      return await response.blob();
    },
    [callApi]
  );

  const value = useMemo(() => ({ generatePreview, generatePdf }), [generatePreview, generatePdf]);

  return value;
}
