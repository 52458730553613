import { Button, Divider, Group, Modal, ScrollArea } from '@mantine/core';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { useCallback } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useFillOutRevisionSaveDraftProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionSaveDraftProvider';
import ChangeGroup from 'components/forms/revision/fill-out/modals/revision-changes-modal/ChangeGroup';

/**
 * Modal which displays the changes made to the revision.
 */
export default function RevisionChangesModal() {
  const { changes } = useFillOutRevisionDataProvider();
  const { saveDraft, loading } = useFillOutRevisionSaveDraftProvider();
  const [opened, { close }] = useDisclosure(changes.length > 0);

  const handleAcknowledge = useCallback(async () => {
    await saveDraft({ notify: false });
    close();
  }, [saveDraft, close]);

  return (
    <Modal
      title="V reviznej správe nastali zmeny !"
      opened={opened}
      onClose={close}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <Divider color="gray.2" />
      <ScrollArea.Autosize mah={500}>
        {changes.map((changeGroup, index) => (
          <ChangeGroup key={index} {...changeGroup} />
        ))}
      </ScrollArea.Autosize>
      <Group position="right" pt={16}>
        <Button variant="primary" onClick={handleAcknowledge} loading={loading}>
          V poriadku, beriem na vedomie
        </Button>
      </Group>
    </Modal>
  );
}
