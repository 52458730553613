import P2Medium from 'components/typography/P2Medium';
import { RevisionTemplateRow } from 'components/tables/data/revision-template/types';
import { Flex } from '@mantine/core';
import BadgeDeleted from 'components/Badge';

/**
 * Column for the revision template name.
 */
export default function RevisionTemplateNameColumn({ data: { name, status } }: { data: RevisionTemplateRow }) {
  const opacity = status ? 1 : 0.6;

  return (
    <Flex gap={10} align="center">
      {!status && <BadgeDeleted text="Vyradené" />}
      <P2Medium color="gray.8" style={{ opacity }}>
        {name}
      </P2Medium>
    </Flex>
  );
}
