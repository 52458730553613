import { ColDef } from 'ag-grid-community';
import { useApi } from 'api/api-context';
import { useCallback, useEffect, useState } from 'react';
import DataTable from 'components/tables/DataTable';
import { ADD_DEVICE_SUBTYPE_PAGE_PATH } from 'routes/paths';
import ActionsColumn from 'components/tables/data/device-subtypes/columns/ActionsColumn';
import DeviceSubtypeIdColumn from 'components/tables/data/device-subtypes/columns/DeviceSubtypeIdColumn';
import DeviceSubtypeNameColumn from 'components/tables/data/device-subtypes/columns/DeviceSubtypeNameColumn';
import DescriptionColumn from 'components/tables/data/device-subtypes/columns/DescriptionColumn';
import RestrictionsColumn from 'components/tables/data/device-subtypes/columns/RestrictionsColumn';
import { DeviceListTypesResponse } from 'api/actions/device-list-types/device-list-types-response';
import ThreeRestrictionsSelectFilter from 'components/tables/filters/restriction/ThreeRestrictionsSelectFilter';
import { useSearchParams } from 'react-router-dom';
import { DeviceSubtypeRow } from 'components/tables/data/device-subtypes/types';

const columns: ColDef[] = [
  {
    field: 'deviceTypeId',
    headerName: 'ID',
    sortable: true,
    filter: true,
    unSortIcon: true,
    suppressMovable: true,
    width: 72,
    minWidth: 72,
    maxWidth: 72,
    cellRenderer: DeviceSubtypeIdColumn,
  },
  {
    field: 'deviceTypeName',
    headerName: 'Názov',
    minWidth: 350,
    sortable: true,
    resizable: true,
    filter: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceSubtypeNameColumn,
  },
  {
    field: 'description',
    headerName: 'Popis',
    minWidth: 350,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DescriptionColumn,
  },
  {
    field: 'restriction',
    headerName: 'Vymedzenia',
    minWidth: 550,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ThreeRestrictionsSelectFilter,
    cellRenderer: RestrictionsColumn,
  },
  {
    valueGetter: ({ data: { status } }: { data: DeviceSubtypeRow }) => (status ? '1' : '0'),
    field: 'status',
    headerName: 'Aktívny',
    filter: true,
    minWidth: 0,
    maxWidth: 0,
    width: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: '_actions',
    headerName: '',
    pinned: 'right',
    width: 194,
    minWidth: 194,
    maxWidth: 194,
    cellRenderer: ActionsColumn,
  },
];

/**
 * Returns the restriction for the device subtype.
 */
function getDeviceSubtypeRestriction(subtype: DeviceListTypesResponse[number]): string {
  if (subtype.isGlobal) {
    return 'global';
  }

  if (subtype.isOrganization) {
    return 'organization';
  }

  if (subtype.isDepartment) {
    return 'department';
  }

  return 'unknown';
}

/**
 * Table which lists device subtypes based on the parent device type.
 */
export default function DeviceSubtypesTable({ deviceTypeId }: { deviceTypeId: number }) {
  const { getAction } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialFilters] = useState(() => {
    const filters: Record<string, any> = {};

    if (searchParams.has('deviceTypeId')) {
      filters.deviceTypeId = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('deviceTypeId'),
        deviceTypeId,
      };
    }

    return filters;
  });

  const action = useCallback(async () => {
    const action = getAction('DeviceListTypes');
    const subtypes = await action({
      query: {
        filters: {
          'parentDeviceTypeId.eq': deviceTypeId,
          'listSubtypes.eq': 1,
        },
      },
    });

    return subtypes.map((subtype) => ({
      ...subtype,
      restriction: getDeviceSubtypeRestriction(subtype),
    }));
  }, [getAction, deviceTypeId]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete('deviceTypeId');
        return params;
      },
      { replace: true }
    );
  });

  return (
    <DataTable
      tableId="device-subtypes"
      title="Typy zariadení"
      toggleDiscardedLabel="Zobraz vyradené"
      addButtonText="Vytvoriť nový typ zariadenia"
      columns={columns}
      action={action}
      addButtonTarget={ADD_DEVICE_SUBTYPE_PAGE_PATH.insert({ deviceTypeId })}
      initialFilters={initialFilters}
      dataExport={{
        modalTitle: 'Exportovať typy',
        fileName: 'typy-zariadeni.xlsx',
        columnKeys: ['deviceTypeId', 'deviceTypeName', 'description', 'restriction', 'status'],
      }}
    />
  );
}
