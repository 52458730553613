import BadgeDeleted from 'components/Badge';
import { DeviceRow } from 'components/tables/data/device/types';
import { Box, Flex, Skeleton } from '@mantine/core';
import P2Medium from 'components/typography/P2Medium';
import FileThumbnail from 'components/files/FileThumbnail';
import { useEffect, useState } from 'react';
import { useApi } from 'api/api-context';
import Slider from 'components/modals/slider/Slider';
import { FEATURE_TOGGLE_DEVICE_IMAGES_SLIDER } from 'env';

/**
 * Displays the main image of the device and opens the slider with more images.
 */
function DeviceImage({ deviceId, opacity }: { deviceId: number; opacity: number }) {
  const { getAction } = useApi();
  const [loading, setLoading] = useState(false);
  const [openedSlider, setOpenSlider] = useState(false);

  const [images, setImages] = useState<{ fileId: string; isMainImage?: boolean }[]>([]);
  const [mainImage, setMainImage] = useState<{ fileId: string; isMainImage?: boolean } | null>(null);

  useEffect(() => {
    setLoading(true);
    const deviceGetAction = getAction('DeviceGet');

    deviceGetAction({ parameters: { deviceId: String(deviceId) } })
      .then((device) => {
        const images =
          device.files
            ?.filter((file) => file.fileType === 'photos')
            .map((file) => ({ fileId: file.fileId, isMainImage: file.isMainImage })) || [];
        setImages(images);

        setMainImage(images.find((image) => image.isMainImage) || null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [deviceId]);

  if (loading) {
    return (
      <Box w={60} h={60} p={8}>
        <Skeleton w={44} h={44} radius={8} />
      </Box>
    );
  }

  return mainImage ? (
    <Box p={8} w={60} h={60} miw={60} mih={60} mah={60} maw={60} style={{ opacity }}>
      <FileThumbnail fileId={mainImage.fileId!} onOpenSlider={() => setOpenSlider(true)} />
      <Slider
        key={mainImage.fileId}
        opened={openedSlider}
        fileIds={images.map((image) => image.fileId)}
        onClose={() => setOpenSlider(false)}
        initialImageId={mainImage.fileId}
      />
    </Box>
  ) : (
    <Box w={60} h={60} miw={60} mih={60} mah={60} maw={60} />
  );
}

/**
 * Column for device name.
 */
export default function DeviceNameColumn({ data: { deviceName, deviceId, status } }: { data: DeviceRow }) {
  const opacity = status ? 1 : 0.6;

  return (
    <Flex gap={8} align="center">
      {FEATURE_TOGGLE_DEVICE_IMAGES_SLIDER && <DeviceImage deviceId={deviceId} opacity={opacity} />}
      {!status && <BadgeDeleted text="Vyradené" />}
      <P2Medium color="gray.8" style={{ opacity }}>
        {deviceName}
      </P2Medium>
    </Flex>
  );
}
