import { Button, Menu } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { RevisionSingleActionProps } from 'components/tables/data/revision/types';
import { noop } from 'lodash';
import useApproveAction from 'components/tables/data/revision/actions/hooks/use-approve-action';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Action performed by the user who has the permission to approve the revision.
 */
export default function ApproveAction({ revision, onStatusChange = noop, kind }: RevisionSingleActionProps) {
  const { approveRevision } = useApproveAction();
  const { confirm } = useConfirm();

  /**
   * Confirms the approval of the revision.
   */
  const confirmApproveRevision = () =>
    confirm({
      title: 'Schválenie revíznej správy',
      content: 'Naozaj si prajete schváliť túto revíznu správu?',
      onConfirm: () => approveRevision({ revision, onStatusChange }),
    });

  if (kind === 'button') {
    return (
      <Button
        size="md"
        onClick={confirmApproveRevision}
        leftIcon={<IconCheck stroke={1.5} size={24} />}
        variant="primary"
        w={180}
      >
        Schváliť
      </Button>
    );
  }

  return (
    <Menu.Item color="gray.8" onClick={confirmApproveRevision} icon={<IconCheck stroke={1.5} size={24} />}>
      Schváliť
    </Menu.Item>
  );
}
