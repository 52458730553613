import { Button, Checkbox, Divider, Group, LoadingOverlay, Modal, ScrollArea, Stack } from '@mantine/core';
import { ColDef, CsvExportParams } from 'ag-grid-community';
import P2Medium from 'components/typography/P2Medium';
import P2Regular from 'components/typography/P2Regular';
import { useCallback, useMemo, useState } from 'react';

/**
 * The parameters of data export.
 */
export interface DataTableExport {
  modalTitle: string;
  columnKeys: string[];
  initialSelectedColumns?: string[];
  fileName: string;
}

/**
 * Parameters of the ExportModal component.
 */
export interface ExportModalProps {
  columns: ColDef[];
  dataExport: DataTableExport;
  opened: boolean;
  onClose: () => void;
  onExport: (csvExportParams: CsvExportParams) => void | Promise<void>;
}

/**
 * Modal which allows the user to export the data.
 */
export default function ExportModal({ columns, dataExport, opened, onClose, onExport }: ExportModalProps) {
  const [loading, setLoading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    dataExport.initialSelectedColumns ?? dataExport.columnKeys
  );

  const selectedSet = useMemo(() => new Set(selectedColumns), [selectedColumns]);

  const columnMap = useMemo(
    () => columns.reduce((acc, column) => ({ ...acc, [column.field ?? '']: column }), {} as Record<string, ColDef>),
    [columns]
  );

  const toggleColumn = useCallback(
    (column: string) =>
      setSelectedColumns((selectedColumns) => {
        if (selectedColumns.includes(column)) {
          return selectedColumns.filter((key) => key !== column);
        } else {
          return [...selectedColumns, column];
        }
      }),
    []
  );

  const handleExport = useCallback(async () => {
    await onExport({
      fileName: dataExport.fileName,
      columnKeys: selectedColumns,
    });

    onClose();
  }, [onExport, onClose, selectedColumns, dataExport.fileName]);

  const handleExportWithLoading = useCallback(
    () =>
      setLoading((loading) => {
        if (!loading) {
          handleExport().then(() => setLoading(false));
        }

        return true;
      }),
    [handleExport]
  );

  const allSelected = selectedColumns.length === dataExport.columnKeys.length;
  const noneSelected = selectedColumns.length === 0;
  const subsetSelected = selectedColumns.length > 0 && !allSelected;

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={dataExport.modalTitle}
      closeOnClickOutside={!loading}
      closeOnEscape={!loading}
      withCloseButton={!loading}
    >
      <LoadingOverlay visible={loading} opacity={1} />
      <Group position="apart" pt={4} pb={12}>
        <Checkbox
          size="md"
          checked={allSelected}
          indeterminate={subsetSelected}
          onChange={() => setSelectedColumns(allSelected ? [] : dataExport.columnKeys)}
          label={<P2Medium>{allSelected ? 'Zrušiť výber' : 'Vybrať všetky stĺpce'}</P2Medium>}
          ml={8}
        />
        <P2Regular>
          Vybrané: {selectedColumns.length} / {dataExport.columnKeys.length}
        </P2Regular>
      </Group>
      <Divider color="gray.0" className="shadow-sm" />
      <ScrollArea h={500}>
        <Stack spacing={8} px={8} pt={12}>
          {dataExport.columnKeys.map((key) => (
            <Checkbox
              size="md"
              key={key}
              label={columnMap[key]?.headerName ?? key}
              checked={selectedSet.has(key)}
              onChange={() => toggleColumn(key)}
            />
          ))}
        </Stack>
      </ScrollArea>
      <Group position="right" spacing={16} pt={16}>
        <Button variant="link" onClick={onClose} px={8}>
          Zrušiť
        </Button>
        <Button variant="primary" disabled={noneSelected} onClick={handleExportWithLoading}>
          Exportovať
        </Button>
      </Group>
    </Modal>
  );
}
