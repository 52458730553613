import { Select } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'faultType' as const;

/**
 * The options for the select component used to filter.
 */
export const OPTIONS = [
  { value: 'no-faults', label: 'Bez závad' },
  { value: 'with-faults', label: 'So závadami' },
] as const;

/**
 * Parameters of the DeviceFaultsSelectFilter component.
 */
export interface DeviceFaultsSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the device faults column.
 *
 * This filter assumes:
 *   - column field is `faultType`
 */
export default function DeviceFaultsSelectFilter({ api, onChange }: DeviceFaultsSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });

  return (
    <Select
      clearable
      searchable
      w="100%"
      size="sm"
      placeholder="Všetky"
      data={OPTIONS}
      value={value}
      onChange={setValue}
    />
  );
}
