import P2Regular from 'components/typography/P2Regular';
import { PredefinedDataRow } from 'components/tables/data/predefined/types';
import Excerpt from 'components/Excerpt';

/**
 * Column for the key.
 */
export default function KeyColumn({ data: { key } }: { data: PredefinedDataRow }) {
  return (
    <P2Regular color="gray.8">
      <Excerpt text={key} length={130} />
    </P2Regular>
  );
}
