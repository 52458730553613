import { Box, Flex } from '@mantine/core';
import { UserRow } from 'components/tables/data/user/types';
import BadgeDeleted from 'components/Badge';
import UserContact from 'components/UserContact';

/**
 * Column for the full name.
 */
export default function FullNameColumn({ data: { firstName, lastName, email, status } }: { data: UserRow }) {
  const opacity = status ? 1 : 0.6;

  return (
    <Flex gap={10} align="center">
      {!status && <BadgeDeleted text="Vyradený" />}
      <Box style={{ opacity }}>
        <UserContact firstName={firstName} lastName={lastName} email={email} />
      </Box>
    </Flex>
  );
}
