import { isFunction, merge } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

/**
 * Reads an object from local storage.
 */
function readObjectFromLocalStorage<T>(key: string): T {
  try {
    const value = localStorage.getItem(key) || '{}';
    return JSON.parse(value) as T;
  } catch (e) {
    return {} as T;
  }
}

/**
 * Uses local storage to store the specified value. Uses a write-through cache.
 */
export default function useLocalStorage<T>(key: string, defaultValue: T | (() => T)) {
  const [value, setValue] = useState(() =>
    merge(isFunction(defaultValue) ? defaultValue() : defaultValue, readObjectFromLocalStorage<T>(key))
  );

  const updateValue = useCallback((update: Partial<T>) => setValue((prev) => ({ ...prev, ...update })), []);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue, updateValue] as const;
}
