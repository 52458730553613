import P2Regular from 'components/typography/P2Regular';
import { OrganizationRow } from 'components/tables/data/organization/types';

/**
 * Column for the address.
 */
export default function AddressColumn({ data: { streetAddress, city } }: { data: OrganizationRow }) {
  return (
    <P2Regular color="gray.8">
      {streetAddress}, {city}
    </P2Regular>
  );
}
