import { Select, rem } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'safetyPoint' as const;

/**
 * The options of the filter.
 */
export const OPTIONS = [
  { value: 'suitable', label: 'Vyhovuje' },
  { value: 'not-suitable', label: 'Nevyhovuje' },
  { value: 'with-reservations', label: 'Vyhovuje s výhradami' },
];

/**
 * Parameters of the RevisionSafetyPointSelectFilter component.
 */
interface RevisionSafetyPointSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the revision safetyPoint column.
 *
 * This filter assumes:
 *  - column field is `safetyPoint`
 */
export default function RevisionSafetyPointSelectFilter({ api, onChange }: RevisionSafetyPointSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });

  return (
    <Select
      clearable
      searchable
      w="100%"
      size="sm"
      placeholder="Všetky typy záveru"
      data={OPTIONS}
      value={value}
      onChange={setValue}
      styles={{ dropdown: { minWidth: rem(180) } }}
    />
  );
}
