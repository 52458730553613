import { Button, Menu } from '@mantine/core';
import { IconArrowBack } from '@tabler/icons-react';
import { useConfirmWithMessage } from 'components/modals/confirm-with-message/ConfirmWithMessageProvider';
import { RevisionSingleActionProps } from 'components/tables/data/revision/types';
import { noop } from 'lodash';
import useReopenAction from 'components/tables/data/revision/actions/hooks/use-reopen-action';

/**
 * Action performed by the user who has the permission to reopen the revision.
 */
export default function ReopenAction({ revision, onStatusChange = noop, kind }: RevisionSingleActionProps) {
  const { reopenRevision } = useReopenAction();
  const { confirmWithMessage } = useConfirmWithMessage();

  /**
   * Confirms the reopening of the revision.
   */
  const confirmReopenRevision = () =>
    confirmWithMessage({
      title: 'Vrátenie revíznej správy',
      message: 'Naozaj si prajete vrátiť túto revíznu správu?',
      onConfirmWithMessage: (reason?: string) => reopenRevision({ revision, reason, onStatusChange }),
    });

  if (kind === 'button') {
    return (
      <Button
        size="md"
        onClick={confirmReopenRevision}
        leftIcon={<IconArrowBack stroke={1.5} size={24} />}
        variant="secondary"
        w={180}
      >
        Vrátiť
      </Button>
    );
  }

  return (
    <Menu.Item color="gray.8" onClick={confirmReopenRevision} icon={<IconArrowBack stroke={1.5} size={24} />}>
      Vrátiť
    </Menu.Item>
  );
}
