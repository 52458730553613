import { ADD_USER_PAGE_PATH } from 'routes/paths';
import { ColDef } from 'ag-grid-community';
import DataTable from 'components/tables/DataTable';
import OrganizationSelectFilter from 'components/tables/filters/OrganizationSelectFilter';
import RoleSelectFilter from 'components/tables/filters/RoleSelectFilter';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi } from 'api/api-context';
import { UserRow } from 'components/tables/data/user/types';
import FullNameColumn from 'components/tables/data/user/columns/FullNameColumn';
import OrganizationNameColumn from 'components/tables/data/user/columns/OrganizationNameColumn';
import RoleNameColumn from 'components/tables/data/user/columns/RoleNameColumn';
import CertificatesColumn from 'components/tables/data/user/columns/CertificatesColumn';
import ActionsColumn from 'components/tables/data/user/columns/ActionsColumn';
import MigratedFromV1NoteColumn from 'components/tables/data/user/columns/MigratedFromV1NoteColumn';
import { ROLE_ADMIN_ID } from 'model/Role';
import CertificatesSelectFilter from 'components/tables/filters/CertificatesSelectFilter';
import { getDeviceType } from 'model/DeviceType';
import PermissionsColumn from 'components/tables/data/user/columns/PermissionsColumn';

/**
 * Table which displays users.
 */
export default function UserTable() {
  const { getAction, roleId } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * The columns of the table.
   */
  const columns = useMemo(() => {
    const columns: ColDef[] = [];

    columns.push({ field: 'userId', hide: true, headerName: 'ID používateľa' });

    columns.push({
      field: 'fullName',
      headerName: 'Meno',
      minWidth: 350,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      cellRenderer: FullNameColumn,
    });

    columns.push({
      field: 'preNominals',
      headerName: 'Titul pred menom',
      hide: true,
    });

    columns.push({
      field: 'postNominals',
      headerName: 'Titul za menom',
      hide: true,
    });

    columns.push({
      field: 'email',
      headerName: 'E-mail',
      filter: true,
      minWidth: 0,
      maxWidth: 0,
      width: 0,
      cellStyle: { opacity: 0 },
    });

    columns.push({
      field: 'phoneNumber',
      headerName: 'Telefónne číslo',
      filter: true,
      minWidth: 0,
      maxWidth: 0,
      width: 0,
      cellStyle: { opacity: 0 },
    });

    columns.push({
      valueGetter: ({ data: { status } }: { data: UserRow }) => (status ? '1' : '0'),
      field: 'status',
      headerName: 'Aktívny',
      filter: true,
      minWidth: 0,
      maxWidth: 0,
      width: 0,
      cellStyle: { opacity: 0 },
    });

    columns.push({
      field: 'organization.organizationId',
      headerName: 'ID organizácie',
      hide: true,
      filter: true,
    });

    columns.push({
      field: 'organization.organizationName',
      headerName: 'Organizácia',
      minWidth: 250,
      filter: true,
      floatingFilter: true,
      floatingFilterComponent: OrganizationSelectFilter.configure({ permissionSlug: 'manage-users' }),
      sortable: true,
      resizable: true,
      unSortIcon: true,
      wrapText: true,
      cellRenderer: OrganizationNameColumn,
    });

    if (roleId === ROLE_ADMIN_ID) {
      columns.push({
        field: 'role.roleId',
        headerName: 'ID roly',
        hide: true,
        filter: true,
      });

      columns.push({
        field: 'role.roleName',
        headerName: 'Rola',
        minWidth: 150,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: RoleSelectFilter,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellRenderer: RoleNameColumn,
      });
    }

    columns.push({
      field: 'certificateSlugs',
      hide: true,
      filter: true,
    });

    columns.push({
      field: 'certificates',
      headerName: 'Osvedčenia',
      resizable: true,
      filter: true,
      floatingFilter: true,
      floatingFilterComponent: CertificatesSelectFilter,
      minWidth: 240,
      cellRenderer: CertificatesColumn,
    });

    columns.push({
      field: 'permissions',
      headerName: 'Oprávenia',
      minWidth: 400,
      resizable: true,
      wrapText: true,
      cellRenderer: PermissionsColumn,
    });

    columns.push({
      field: 'migratedFromV1Note',
      headerName: 'Poznámka k migrácii',
      minWidth: 350,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: MigratedFromV1NoteColumn,
    });

    columns.push({
      field: '_actions',
      headerName: '',
      pinned: 'right',
      width: 194,
      minWidth: 194,
      maxWidth: 194,
      cellRenderer: ActionsColumn,
    });

    return columns;
  }, [roleId]);

  const [initialFilters] = useState(() => {
    const filters: Record<string, any> = {};

    if (searchParams.has('organizationId')) {
      filters['organization.organizationId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('organizationId'),
      };
    }

    return filters;
  });

  const action = useCallback(async () => {
    const action = getAction('UserList');

    const users = await action();

    return users.map((user) => ({
      ...user,
      certificateSlugs: user.certificates.map(
        ({ deviceType, deviceTypeId }) => deviceType?.slug || getDeviceType(deviceTypeId)?.slug
      ),
    }));
  }, [getAction]);

  const columnKeys = useMemo(() => {
    const columns: string[] = [];

    columns.push('userId');
    columns.push('fullName');
    columns.push('preNominals');
    columns.push('postNominals');
    columns.push('email');
    columns.push('phoneNumber');
    columns.push('status');
    columns.push('migratedFromV1Note');
    columns.push('organization.organizationId');
    columns.push('organization.organizationName');

    if (roleId === ROLE_ADMIN_ID) {
      columns.push('role.roleId');
      columns.push('role.roleName');
    }

    return columns;
  }, [roleId]);

  useEffect(() => {
    setSearchParams((params) => {
      params.delete('organizationId');
      return params;
    });
  });

  return (
    <DataTable
      tableId="users"
      title="Používatelia"
      toggleDiscardedLabel="Zobraziť vyradených"
      addButtonText="Pridať nového"
      columns={columns}
      action={action}
      addButtonTarget={ADD_USER_PAGE_PATH.original}
      initialFilters={initialFilters}
      dataExport={{
        modalTitle: 'Exportovať používateľov',
        fileName: 'pouzivatelia.xlsx',
        columnKeys,
      }}
    />
  );
}
