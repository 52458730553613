import { ActionIcon, Button, Group, Menu, Portal, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  IconBackspace,
  IconBell,
  IconDotsVertical,
  IconMapPinPlus,
  IconPencil,
  IconServer,
  IconTrashX,
  IconUser,
} from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import { useConfirm } from 'components/modals/message/MessageProvider';
import P1Regular from 'components/typography/P1Regular';
import P2Regular from 'components/typography/P2Regular';
import panic from 'errors/panic';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EDIT_USER_PAGE_PATH, NOTIFICATION_SETTINGS_PAGE_PATH, WELCOME_PAGE_PATH } from 'routes/paths';
import { CANNOT_SET_ENTITY_STATUS, ERROR_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Parameters of the UserActions component.
 */
export interface UserActionsProps {
  userId: number;
  fullName: string;
  status: boolean;
  managesDevices: { deviceId: number; deviceName: string }[];
  departmentsContact: { departmentId: number; departmentName: string }[];
  onStatusChange: (status: boolean) => void;
}

/**
 * Displays actions for a user in a table.
 */
export default function UserActions({
  userId,
  fullName,
  status,
  onStatusChange,
  managesDevices,
  departmentsContact,
}: UserActionsProps) {
  const navigate = useNavigate();
  const { getAction, setImpersonate, roleId, userId: contextUserId } = useApi();
  const { confirm } = useConfirm();

  const discardUserMessage = useMemo(
    () => (
      <Stack spacing={16}>
        <P1Regular c="gray.8">Naozaj chcete vyradiť používateľa?</P1Regular>
        <P1Regular c="gray.8">Používateľ bude odstránený zo všetkých organizácií a stredísk.</P1Regular>
        {managesDevices.length > 0 && (
          <Stack spacing={4}>
            <P1Regular c="gray.8">Používateľ bude odstránený ako zodpovedná osoba pri týchto zariadeniach:</P1Regular>
            {managesDevices.map(({ deviceId, deviceName }) => (
              <Group key={deviceId} spacing={8} pl={8}>
                <IconServer size={16} />
                <P2Regular>{deviceName}</P2Regular>
              </Group>
            ))}
          </Stack>
        )}
        {departmentsContact.length > 0 && (
          <Stack spacing={4}>
            <P1Regular c="gray.8">Používateľ bude odstránený ako kontaktná osoba na týchto strediskách:</P1Regular>
            {departmentsContact.map(({ departmentId, departmentName }) => (
              <Group key={departmentId} spacing={8} pl={8}>
                <IconMapPinPlus size={16} />
                <P2Regular>{departmentName}</P2Regular>
              </Group>
            ))}
          </Stack>
        )}
      </Stack>
    ),
    [managesDevices, departmentsContact]
  );

  /**
   * Makes a call to the API to set the status of the user.
   */
  const setStatusRemote = useCallback(
    (status: boolean) => {
      const userSetStatus = getAction('UserSetStatus');

      userSetStatus({ parameters: { userId: String(userId) }, payload: { status } })
        .then(() => onStatusChange(status))
        .catch((error) => {
          if (error.response.data.error.code === CANNOT_SET_ENTITY_STATUS) {
            notifications.show({
              title: 'Chyba!',
              message: 'Tohto používateľa nemôžete deaktivovať.',
              color: ERROR_NOTIFICATION_COLOR,
            });
          } else {
            panic(error);
          }
        });
    },
    [getAction, userId]
  );

  /**
   * Confirms the status change.
   */
  const confirmSetStatus = useCallback(
    (status: boolean) => {
      const title = status ? 'Zaradiť používateľa' : 'Vyradiť používateľa';
      const message = status ? 'Naozaj chcete zaradiť používateľa?' : discardUserMessage;

      confirm({
        title,
        content: message,
        onConfirm: () => setStatusRemote(status),
      });
    },
    [confirm, setStatusRemote, discardUserMessage]
  );

  /**
   * Confirms the impersonation.
   */
  const confirmImpersonate = useCallback(() => {
    if (roleId !== ROLE_ADMIN_ID) {
      return; // Only admins can impersonate.
    }

    confirm({
      title: `Vystupovať ako ${fullName}`,
      content: 'Naozaj chcete vystupovať ako tento používateľ?',
      onConfirm: () => {
        const userGetMeAction = getAction('AuthLoggedUserInfo');

        userGetMeAction({ headers: { 'X-Impersonate': userId } })
          .then((user) => {
            setImpersonate(user);
            navigate(WELCOME_PAGE_PATH.original);
          })
          .catch(panic);
      },
    });
  }, [navigate, confirm, getAction, setImpersonate, fullName, userId, roleId]);

  return (
    <Group spacing={12} noWrap>
      {contextUserId !== userId ? (
        <Button
          size="md"
          component={Link}
          to={EDIT_USER_PAGE_PATH.insert({ userId })}
          variant="secondary"
          leftIcon={<IconPencil />}
          disabled={!status}
        >
          Editovať
        </Button>
      ) : (
        // TODO maybe this does not have to be here but when there's no button, the menu is not aligned properly
        <Button size="md" variant="tertiary" w={115}>
          To ste vy
        </Button>
      )}
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Portal>
          <Menu.Dropdown>
            <Menu.Label>
              <Text maw={160} truncate>
                {fullName}
              </Text>
            </Menu.Label>
            {status && (
              <>
                {roleId === ROLE_ADMIN_ID && contextUserId !== userId && (
                  <Menu.Item onClick={confirmImpersonate} icon={<IconUser stroke="1.5" size={20} />}>
                    Vystupovať ako
                  </Menu.Item>
                )}
                <Menu.Item
                  component={Link}
                  to={NOTIFICATION_SETTINGS_PAGE_PATH.insert({ userId })}
                  icon={<IconBell stroke="1.5" size={20} />}
                >
                  Nastavenia upozornení
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            {contextUserId !== userId && (
              <Menu.Item
                color={status ? 'red.8' : 'blue.8'}
                onClick={() => confirmSetStatus(!status)}
                icon={status ? <IconTrashX stroke={1.5} size={20} /> : <IconBackspace stroke={1.5} size={20} />}
              >
                {status ? 'Vyradiť' : 'Zaradiť'}
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Group>
  );
}
