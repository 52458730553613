import { noop } from 'lodash';
import { RevisionActionProps } from 'components/tables/data/revision/types';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import panic from 'errors/panic';
import { useApi } from 'api/api-context';

/**
 * The parameters of the decline action.
 */
export interface DeclineActionParams extends RevisionActionProps {
  reason: string;
}

/**
 * Implements the decline action for revision.
 */
export default function useDeclineAction() {
  const { getAction } = useApi();

  /**
   * Makes a call to the API to decline the revision.
   */
  async function declineRevision({
    revision: { revisionId, revisionName },
    reason,
    onStatusChange = noop,
  }: DeclineActionParams) {
    const revisionDeclineAction = getAction('RevisionTechnicianDecline');

    try {
      const { status } = await revisionDeclineAction({
        parameters: { revisionId: String(revisionId) },
        payload: { reason },
      });

      onStatusChange({ revisionId, status, reason });

      showNotification({
        title: 'Revízna správa bola odmietnutá',
        message: `Revíznu správu ${revisionName} ste úspešne odmietnutá.`,
        color: SUCCESS_NOTIFICATION_COLOR,
      });
    } catch (error: any) {
      panic(error);
    }
  }

  return { declineRevision };
}
