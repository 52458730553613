import { Select } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'deadlineStatus' as const;

/**
 * The options of the filter.
 */
export const OPTIONS = [
  { value: 'after-deadline', label: 'Po termíne' },
  { value: 'before-deadline', label: 'Pred termínom' },
] as const;

/**
 * Parameters of the RevisionDeadlineSelectFilter component.
 */
interface RevisionDeadlineSelectFilterProps {
  api: GridApi;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the revision deadlineStatus column.
 *
 * This filter assumes:
 *  - column field is `deadlineStatus`
 */
export default function RevisionDeadlineSelectFilter({ api, onChange }: RevisionDeadlineSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });

  return (
    <Select
      clearable
      searchable
      w="100%"
      size="sm"
      placeholder="Všetky"
      data={OPTIONS}
      value={value}
      onChange={setValue}
    />
  );
}
