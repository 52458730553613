import CertificateSelect from 'components/inputs/certificate-select/CertificateSelect';
import { IFormInputSpecCertificate } from 'pages/revisions-module/template-editor/editors/form/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';

/**
 * Certificate select input for the revision form.
 */
export default function RevisionFieldCertificate({ spec, name }: RevisionFieldProps<IFormInputSpecCertificate>) {
  const { form, revision } = useFillOutRevisionDataProvider();

  return (
    <CertificateSelect
      required={spec.required}
      readOnly={spec.readOnly}
      size="lg"
      label={spec.label}
      searchable
      autoSelectSingleResult
      placeholder="Vyberte osvedčenie revízneho technika"
      loadingPlaceholder="Načítavanie ..."
      deviceTypeId={revision.deviceType.deviceTypeId}
      name={name}
      clearable={!spec.required}
      {...form.getInputProps(name)}
    />
  );
}
